export function useLinks() {
  const { public: configPublic } = useRuntimeConfig();
  if (!configPublic.baseUrl) throw new Error('baseUrl is not defined');
  let domen: string = configPublic.baseUrl as string;
  domen = GlobalUtils.Text.sliceLastSlash(domen);
  return {
    ABOUT: `${domen}/contacts`,
    AGREEMENT: `${domen}/userAgreement`,
    ARCANA: `${domen}/open/progress_spring23_level_10`,
    BALANCE_POPUP: `${domen}/offersshop/?shut_up_and_take_my_money=true`,
    BATTLES: '/battles',
    BONUS_PAGE: `${domen}/bonuspage`,
    CASE: `${domen}/open/`,
    CONTRACT: `${domen}/contract/`,
    DAILY_CASES_XP_POPUP: `${domen}/daily_cases?show_xp_modal=true`,
    DC_HOOK: `${domen}/open/progress_spring23_level_50`,
    EVENT: `${domen}/snow23`,
    FAQ: `${domen}/faq`,
    FREE_CASES: `${domen}/daily_cases`,
    INSTAGRAM: 'https://www.instagram.com/tastydrop_co/',
    INVENTORY_POPUP: `${domen}/offersshop?shut_up_and_sell=true`,
    MAIN: '/',
    OPEN_CASE: `${domen}/open/`,
    POLICY: `${domen}/cookies`,
    PROFILE: `${domen}/profile`,
    PROFILE_LINK: `${domen}/user/`,
    PROFILE_SECRET_LINK: `${domen}/SuperSecretProfilePage2/`,
    RULES: `${domen}/eventCondition`,
    SECRETSHOP: `${domen}/offersshop`,
    TELEGRAM: 'https://t.me/tastydropz',
    UPGRADES: `${domen}/upgrades/`,
    VK: 'https://vk.com/tastydrop',
    domen,
  };
}
