export default defineNuxtPlugin(() => {
  /* Client Jobs on Initialisation */
  if (process.client) {
    const config = useRuntimeConfig();

    // Предоставление возможности дебага на разных окружениях засчет логинга
    // Ключ в package.json определенного энва
    // @ts-ignore
    window.activeLoggers = createLoggersActivator(config.public.loggerActivationKey);

    tryConnectToWebsocket();
  }
});
