import type { IAppProjectTitle } from '~/types/interface';

type TProjectName<T> = Record<keyof T, keyof T>;
export const projectName: TProjectName<IAppProjectTitle> = {
  tastydrop: 'tastydrop',
  tastygo: 'tastygo',
};

export const PRESETS_KEY = 'presets';
export const WEBSOCKET_FIRST_CHANNEL = 'first_channel';
export const PROJECT_ROUTER = 'projectRouter';
