import { useBkUserStore } from '~/features/bk/store/bk.user';
import { useUserStore } from '~/store/user/user.store';

interface IAmplitudeEvent {
  event: string;
  eventProperties?: object;
  userProperties?: object;
}

const PROVIDERS = {
  BETTING: 'betting',
  BETTING_TEST: 'bettingTest',
  DEFAULT: 'default',
} as const;

export const useAmplitude = () => {
  const {
    _route: {
      meta: { isIntegratorModule },
    },
  } = useNuxtApp();

  const startTransaction = (apiProvider: keyof typeof PROVIDERS) => {
    const events: IAmplitudeEvent[] = [];
    return {
      add(payload: IAmplitudeEvent) {
        events.push(payload);
      },
      commit() {
        return sendEvents(PROVIDERS[apiProvider], events);
      },
    };
  };

  const sendEvent = (apiProvider: keyof typeof PROVIDERS, payload: IAmplitudeEvent) =>
    sendEvents(PROVIDERS[apiProvider], [payload]);

  const sendEvents = (apiProvider: (typeof PROVIDERS)[keyof typeof PROVIDERS], payload: IAmplitudeEvent[]) => {
    try {
      let userId: number | string | undefined;

      if (isIntegratorModule) {
        const bkUser = useBkUserStore();
        userId = bkUser.user?.userId;
      } else {
        const userStore = useUserStore();
        userId = userStore.userId;
      }

      const instance = useNuxtApp().$amplitude[apiProvider];

      if (userId) {
        instance.setUserId(userId.toString());
      }

      for (const item of payload) {
        instance.track({
          event_properties: {
            ...item.eventProperties,
            is_new_front: true,
          },
          event_type: item.event,
          user_properties: item.userProperties,
        });
      }

      return true;
    } catch {
      return false;
    }
  };

  return {
    sendEvent,
    startTransaction,
  };
};
