export enum AlertCodes {
  ALL_ITEMS_IN_SALE_QUEUE = 'all_items_in_sale_queue',
  DATA_UPDATED = 'data_updated',
  ENTER_SUCCESS = 'enter_success',
  ERROR = 'error',
  ERROR_BATTLE_AUTHORIZE = 'error_battle_authorize',
  ERROR_EVENT_SETTINGS = 'error_event_settings',
  ERROR_GENERATION = 'error_generation',
  ERROR_MAX_ROUNDS = 'error_max_rounds',
  ERROR_NOT_ENOUGH_FUNDS = 'error_not_enough_funds',
  ERROR_NOT_ENOUGH_FUNDS_FOR_BATTLE = 'error_not_enough_funds_for_battle',
  ERROR_OCCURRED = 'error_occurred',
  ERROR_SKIN_TRANSFER = 'error_skin_transfer',
  ERROR_TOO_MANY_ROUNDS = 'error_too_many_rounds',
  ERROR_WITHDRAWAL = 'error_item_withdrawal',
  ITEM_IN_SALE_QUEUE = 'item_in_sale_queue',
  ITEM_IN_WITHDRAWAL_QUEUE = 'item_in_withdrawal_queue',
  ITEM_IN_WITHDRAWAL_STEAM_QUEUE = 'item_in_withdrawal_steam_queue',
  ITEM_RECEIVED = 'item_received',
  ITEM_TRANSFER_CANCELED = 'item_transfer_canceled',
  JOINING_BATTLE = 'joining_battle',
  LEVEL_GENERATION = 'level_generation',
  PREPARING_TO_DISPATCH = 'preparing_to_dispatch',
  REGISTRATION_SUCCESS = 'registration_success',
  STATUS_NOT_FOUND = 'status_not_found',
}
