import { BattlesRoutesApi } from './BattlesRoutes.api';
import ApiQueryHttp from '~/api/global/http/ApiQueryHttp';
import type { TPossibleNull } from '~/types/Shared.types';
import type { TBattleQueries } from '~/repository/modules/battles/BattlesQueries.types';
import type { IBattlesDefaultError } from '~/repository/modules/battles/BattlesErrors.types';

type TGetBattlesKeys = keyof typeof BattlesRoutesApi.GET;
type TPostBattlesKeys = keyof typeof BattlesRoutesApi.POST;

export default class BattlesHttp extends ApiQueryHttp {
  protected readonly battlesApi: string;

  constructor() {
    super();
    this.battlesApi = '/battles';
  }

  /* GET */

  public async getClientData<TResponse>(
    route: TGetBattlesKeys,
    queries?: Partial<TBattleQueries>,
    encodeQueries = true,
  ): Promise<TPossibleNull<TResponse>> {
    const queryString = this.query.serialise(queries || {}, encodeQueries);
    const result = await this.$oldApi.get<TResponse>(this.route(BattlesRoutesApi.GET[route]) + queryString);

    return result || null;
  }

  public async getSSRData<TResponse>(
    route: TGetBattlesKeys,
    queries?: Partial<TBattleQueries>,
    encodeQueries = true,
  ): Promise<TPossibleNull<TResponse>> {
    const queryString = this.query.serialise(queries || {}, encodeQueries);
    const { data } = await useAsyncData<TResponse>(BattlesRoutesApi.GET[route], () =>
      this.$oldApi.get(this.route(BattlesRoutesApi.GET[route]) + queryString),
    );
    return (data.value as TResponse) || null;
  }

  /* POST */

  public async makePostRequest<TRequest = object, TResponse = object>(
    route: TPostBattlesKeys,
    body: TRequest,
  ): Promise<TPossibleNull<TResponse>> {
    return await this.$oldApi.post(this.route(BattlesRoutesApi.POST[route]), {
      body: body || {},
      isMapped: true,
      // @ts-ignore
      manualErrorHandler: (error: { data: IBattlesDefaultError }) => {
        const errorData = error.data || null;
        errorData && errorData.xdebugMessage && delete errorData.xdebugMessage;
        return errorData;
      },
      mapBeforeSend: true,
    });
  }

  private route = (route: string) => this.battlesApi + route;
}
