import { Colors } from '../constants/colors.constants';

/**
 * Возвращает объект с rgba значениями или строку
 * из переданной hex строки
 * @param {string} hex - HEX строка
 * @param {Object | string} returnType - Тип возвращаемого значения функции
 * @param {number} alpha - Добавочное значение альфа коэффициента от 0 до 1
 * @returns {Object | string} - Возвращает или объект или строку с rgba значением
 */
function hexToRgb(
  hex: string,
  returnType: 'object',
  alpha?: number,
): {
  alpha: number;
  b: number;
  g: number;
  r: number;
};
function hexToRgb(hex: string, returnType: 'string', alpha?: number): string;
function hexToRgb(hex: string, returnType: 'object' | 'string', alpha = 1): unknown {
  if (alpha && alpha < 0 && alpha > 1) throw new Error(`Invalid alpha value ${alpha}`);

  const [R, G, B] = [parseInt(hex.slice(1, 3), 16), parseInt(hex.slice(3, 5), 16), parseInt(hex.slice(5, 7), 16)];

  if (returnType === 'string') return `rgb${alpha ? 'a' : ''}(${R},${G},${B}${alpha ? ',' + alpha : ''})`;
  return {
    alpha,
    b: B,
    g: G,
    r: R,
  };
}
const hex2Rgb = hexToRgb;

/**
 * Возвращает набором цветов в зависимости от переданного уровня пользователя
 * @param lvl - Значение уровня пользователя
 * @returns - Набор цветов в зависимости от переданного уровня
 */
const parseLvlColors = (lvl: 'free' | number | null) => {
  const level = toValue(lvl);

  if (level === 'free') {
    return Colors.LVL.FREE_CASES;
  } else if (!level) {
    return Colors.LVL.LVL_1_4;
  } else if (level >= 200) {
    return Colors.LVL.LVL_200;
  }

  const splitLvl = (lvlKey: string) => lvlKey.split('_');

  const currentLvlKey = ref(
    Object.keys(Colors.LVL).find((key) => {
      const [, min, max] = splitLvl(key);
      return min && max && level >= +min && level <= +max;
    }),
  );

  if (!currentLvlKey.value) return Colors.LVL.LVL_1_4;

  return Colors.LVL[currentLvlKey.value as keyof typeof Colors.LVL];
};

type TRarityKey = keyof typeof Colors.RARITY;
const getColorsRarity = (color: Lowercase<TRarityKey> | string): (typeof Colors.RARITY)[TRarityKey] => {
  if (typeof color !== 'string') return '#ffffff';
  return Colors.RARITY[color.toUpperCase() as TRarityKey];
};

type TGradientRarityKey = keyof typeof Colors.GRADIENTS.RARITY;
const getGradientColorsRarity = (
  color: Lowercase<TRarityKey> | string,
): (typeof Colors.GRADIENTS.RARITY)[TGradientRarityKey] => {
  if (typeof color !== 'string') return '';
  return Colors.GRADIENTS.RARITY[color.toUpperCase() as TGradientRarityKey];
};

/**
 * Возвращает валидный hex цвет с "#" символом в начале
 * @param {string} color - Hex цвет с/без символом "#"
 * @returns {string} - Hex цвет с символом "#"
 */
const passColorThroughHash = (color: string) => {
  const HASH_SYMBOL = '#';
  if (color[0] !== HASH_SYMBOL) return HASH_SYMBOL + color;
  return color;
};

export default {
  Colors: {
    getColorsRarity,
    getGradientColorsRarity,
    hex2Rgb,
    parseLvlColors,
    passColorThroughHash,
  },
};
