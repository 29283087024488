<template>
  <component
    :is="componentType"
    :style="style"
    :disabled="state.disabled"
    class="sharedButton"
    v-bind="bindAttributes"
    @click="props?.onClick"
  >
    <slot name="prepend"></slot>
    <SharedText as="span" :weight="FontWeights.BOLD" :size="textProps.size" v-bind="textProps || {}">
      <slot></slot>
    </SharedText>
    <slot name="append"></slot>
  </component>
</template>

<script setup lang="ts">
import { toRefs } from 'vue';
import type { Property } from 'csstype';
import type {
  ISharedButtonNewProps,
  TSharedButtonNewComponentType,
  TSharedButtonNewLinkType,
} from './SharedButtonNew.types';
import { FontWeights } from '~/types/SharedFont.types';
import {
  defaultButtonColorPreset,
  defaultButtonStatePreset,
  defaultMediumButtonTextPreset,
  defaultMiddleButtonFormPreset,
} from '~/components/SharedButtonNew/SharedButtonNew.data';
import type { TBackgroundColor } from '~/types/Shared.types';
import type {
  ImediaBreakpoints,
  ISharedButtonMediaValue,
  ISharedButtonNewFormPreset,
} from '~/types/SharedButtonNew.types';
import { useViewport } from '#imports';
import { NuxtLink } from '#components';

const { proceedCssValue } = GlobalUtils.CSS;

const props = withDefaults(defineProps<ISharedButtonNewProps>(), {
  color: () => defaultButtonColorPreset,
  form: () => defaultMiddleButtonFormPreset,
  href: '',
  state: () => defaultButtonStatePreset,
  target: '',
  textProps: () => defaultMediumButtonTextPreset,
  to: '',
});
const { color, form, state, textProps, linkProps } = toRefs(props);

const componentType = computed<TSharedButtonNewComponentType>(() => {
  switch (true) {
    case !!linkProps.value?.href:
      return 'a';
    case !!linkProps.value?.to:
      return NuxtLink;
    default:
      return 'button';
  }
});

const bindAttributes = computed<TSharedButtonNewLinkType>(() => {
  switch (true) {
    case !!linkProps.value?.to:
      return {
        target: linkProps.value?.target,
        to: linkProps.value?.to,
      };
    case !!linkProps.value?.href:
      return {
        href: linkProps.value?.href,
        target: linkProps.value?.target,
      };
    default:
      return {};
  }
});

const modifiedStyles = computed(() => {
  const properties: {
    borderRadius: Property.BorderRadius | number;
    color: TBackgroundColor;
    colorHovered: TBackgroundColor;
  } = {
    borderRadius: '',
    color: '',
    colorHovered: '',
  };
  properties.color = color.value?.colorInactive && !state.value?.active ? color.value.colorInactive : color.value.color;

  properties.colorHovered =
    color.value.colorHovered && state.value?.active && color.value?.colorInactive
      ? color.value.color
      : color.value.colorHovered;

  properties.borderRadius = proceedCssValue(form.value.radius);

  return properties;
});

const viewport = useViewport();

// @ts-ignore
const mediaQuery = (
  breakpoint: keyof ImediaBreakpoints,
  mediadata: Record<keyof ISharedButtonMediaValue, ISharedButtonMediaValue>,
  property: keyof ISharedButtonMediaValue | keyof ISharedButtonNewFormPreset,
) => {
  // задаем массив возможных брейкпоинтов
  const localBreakpoints = ['sm', 'md', 'xl', 'xxl'];
  // смотрим индекс предыдушего брейкпоинта, если он undefined указываем индекс как -1
  const prevBreakpointIndex = localBreakpoints.findIndex((bp) => bp === breakpoint) || -1;
  // находим значение предыдушего брейкпоинта по индексу, или ставим 'sm', если он последний
  const prevBreakpointOrSM =
    prevBreakpointIndex < 0
      ? (localBreakpoints[prevBreakpointIndex - 1] as keyof ImediaBreakpoints)
      : ('sm' as keyof ImediaBreakpoints);

  // 1. если в media указано свойство для нужно брейпоинта возвращаем его
  // @ts-ignore
  if (mediadata?.[breakpoint]?.[property]) {
    // @ts-ignore
    return mediadata?.[breakpoint]?.[property];
  } else {
    // 3. проверяем выход из рекурсии, на случай, если media не указан вовсе
    if (prevBreakpointIndex < 0) {
      return form.value?.[property];
    }
    // 2. если свойства в медиа под нужный брейкпоинт нет,
    // рекурсивно запускаем эту же функцию для предыдущего брейкпоинта
    return mediaQuery(prevBreakpointOrSM, mediadata, property);
  }
};

// берем свойства формы при изменения размеров экрана из ключа media
const media = computed(() => {
  const breakpoint = viewport.breakpoint.value.toLowerCase() as keyof ImediaBreakpoints;
  const mediaData = form.value?.media as Record<keyof ISharedButtonMediaValue, ISharedButtonMediaValue>;

  return {
    flexDir: mediaQuery(breakpoint, mediaData, 'flexDir'),
    height: mediaQuery(breakpoint, mediaData, 'height'),
    margin: mediaQuery(breakpoint, mediaData, 'margin'),
    minWidth: mediaQuery(breakpoint, mediaData, 'minWidth'),
    padding: mediaQuery(breakpoint, mediaData, 'padding'),
    radius: mediaQuery(breakpoint, mediaData, 'radius'),
    width: mediaQuery(breakpoint, mediaData, 'width'),
  };
});

const style = computed(() => {
  // иначе текст в кнопке скачет при загрузке
  return {
    '--justify-content': form?.value?.justifyContent || 'center',
  };
});
</script>

<style scoped lang="scss">
@use 'assets/styles/media';

.sharedButton {
  --width: v-bind('media.width');
  --min-width: v-bind('media.minWidth');
  --padding: v-bind('media.padding');
  --radius: v-bind('media.radius');
  --height: v-bind('media.height');
  --margin: v-bind('media.margin');
  --flex-dir: v-bind('media.flexDir');

  display: flex;
  flex-direction: var(--flex-dir);
  grid-template-rows: 1fr;
  align-items: center;
  justify-content: var(--justify-content);
  width: var(--width);
  min-width: var(--min-width);
  height: var(--height);
  padding: var(--padding);
  margin: var(--margin);
  background-color: v-bind('modifiedStyles.color');
  border-radius: var(--radius);
  transition: var(--default-duration) all;

  &:hover:not(:disabled) {
    cursor: pointer;
    background-color: v-bind('modifiedStyles.colorHovered');
  }
}
</style>
