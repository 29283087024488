import { default as MainPageEE7YkPn6NHMeta } from "/app/layers/dota/routes/eventCondition/MainPage/MainPage.vue?macro=true";
import { default as MainPagemM32X6wTvIMeta } from "/app/layers/dota/routes/privacy-policy/MainPage/MainPage.vue?macro=true";
import { default as MainPage4xcI1LOwCiMeta } from "/app/layers/dota/routes/cookies/MainPage/MainPage.vue?macro=true";
import { default as MainPagePJxAKR9ZxRMeta } from "/app/layers/dota/routes/userAgreement/MainPage/MainPage.vue?macro=true";
import { default as MainPagelWSqxp92D4Meta } from "/app/layers/dota/routes/contacts/MainPage/MainPage.vue?macro=true";
import { default as IFrameControllerd7RJ4XI8cIMeta } from "/app/features/bk/controllers/IFrameController/IFrameController.vue?macro=true";
import { default as IFrameTestController2jZinoTdeQMeta } from "/app/features/bk/controllers/IFrameTestController/IFrameTestController.vue?macro=true";
import { default as DropPage5wwAajUKztMeta } from "/app/layers/dota/routes/cases/DropPage/DropPage.vue?macro=true";
import { default as OpenPagevR5vXml3LAMeta } from "/app/layers/dota/routes/cases/OpenPageDev/OpenPage.vue?macro=true";
import { default as TestSimpleCasea3yDs2UPNoMeta } from "/app/layers/dota/routes/cases/TestSimpleCase/TestSimpleCase.vue?macro=true";
import { default as MainPageJODkIn2wd3Meta } from "/app/layers/dota/routes/cardForm/MainPage/MainPage.vue?macro=true";
import { default as MainPageYmytrE2UerMeta } from "/app/layers/dota/routes/payment/MainPage/MainPage.vue?macro=true";
import { default as ResultPageBASZ0VjGTlMeta } from "/app/layers/dota/routes/payment/ResultPage/ResultPage.vue?macro=true";
import { default as UnauthPageaeuzXJ3vs6Meta } from "/app/layers/dota/routes/payment/UnauthPage/UnauthPage.vue?macro=true";
import { default as CreateBattlePageEUAqGqDp4bMeta } from "/app/layers/dota/routes/battles/CreateBattlePage/CreateBattlePage.vue?macro=true";
import { default as MainPageEKwWOytIzIMeta } from "/app/layers/dota/routes/battles/MainPage/MainPage.vue?macro=true";
import { default as HistoryPageyPwmaAKXe2Meta } from "/app/layers/dota/routes/battles/HistoryPage/HistoryPage.vue?macro=true";
import { default as PersonalStatisticsPagerDmFyaYgEjMeta } from "/app/layers/dota/routes/battles/PersonalStatisticsPage/PersonalStatisticsPage.vue?macro=true";
import { default as RunningBattlePage28oK4TAgCqMeta } from "/app/layers/dota/routes/battles/RunningBattlePage/RunningBattlePage.vue?macro=true";
import { default as NotWorkingBattlePagewnDPQQsZ9tMeta } from "/app/layers/dota/routes/battles/NotWorkingBattlePage/NotWorkingBattlePage.vue?macro=true";
import { default as MainPageMejfE9SXNwMeta } from "/app/layers/dota/routes/mainPage/MainPage/MainPage.vue?macro=true";
import { default as EventCasesPageBVQEDhJhIpMeta } from "/app/layers/dota/routes/mainPage/EventCasesPage/EventCasesPage.vue?macro=true";
import { default as TopLaddersPagecARmRO4To7Meta } from "/app/layers/dota/routes/mainPage/TopLaddersPage/TopLaddersPage.vue?macro=true";
import { default as MainPageilrUVbfL3gMeta } from "/app/layers/dota/routes/techies/MainPage/MainPage.vue?macro=true";
import { default as UserPageSecretNpbLlJzwMNMeta } from "~/layers/dota/routes/profile/UserPageSecret/UserPageSecret.vue?macro=true";
import { default as MainPagefFC20c5EcCMeta } from "/app/layers/dota/routes/quiz/MainPage/MainPage.vue?macro=true";
import { default as MainPageuEGbVpMMPyMeta } from "/app/layers/dota/routes/faq/MainPage/MainPage.vue?macro=true";
import { default as LandingKrCpxeqvBgMeta } from "/app/layers/dota/routes/hamster/Landing/Landing.vue?macro=true";
export default [
  {
    name: MainPageEE7YkPn6NHMeta?.name ?? "eventCondition___en",
    path: MainPageEE7YkPn6NHMeta?.path ?? "/en/eventCondition",
    meta: MainPageEE7YkPn6NHMeta || {},
    alias: MainPageEE7YkPn6NHMeta?.alias || [],
    redirect: MainPageEE7YkPn6NHMeta?.redirect,
    component: () => import("/app/layers/dota/routes/eventCondition/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageEE7YkPn6NHMeta?.name ?? "eventCondition___ru",
    path: MainPageEE7YkPn6NHMeta?.path ?? "/eventCondition",
    meta: MainPageEE7YkPn6NHMeta || {},
    alias: MainPageEE7YkPn6NHMeta?.alias || [],
    redirect: MainPageEE7YkPn6NHMeta?.redirect,
    component: () => import("/app/layers/dota/routes/eventCondition/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageEE7YkPn6NHMeta?.name ?? "eventCondition___kk",
    path: MainPageEE7YkPn6NHMeta?.path ?? "/kk/eventCondition",
    meta: MainPageEE7YkPn6NHMeta || {},
    alias: MainPageEE7YkPn6NHMeta?.alias || [],
    redirect: MainPageEE7YkPn6NHMeta?.redirect,
    component: () => import("/app/layers/dota/routes/eventCondition/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageEE7YkPn6NHMeta?.name ?? "eventCondition___az",
    path: MainPageEE7YkPn6NHMeta?.path ?? "/az/eventCondition",
    meta: MainPageEE7YkPn6NHMeta || {},
    alias: MainPageEE7YkPn6NHMeta?.alias || [],
    redirect: MainPageEE7YkPn6NHMeta?.redirect,
    component: () => import("/app/layers/dota/routes/eventCondition/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageEE7YkPn6NHMeta?.name ?? "eventCondition___uz",
    path: MainPageEE7YkPn6NHMeta?.path ?? "/uz/eventCondition",
    meta: MainPageEE7YkPn6NHMeta || {},
    alias: MainPageEE7YkPn6NHMeta?.alias || [],
    redirect: MainPageEE7YkPn6NHMeta?.redirect,
    component: () => import("/app/layers/dota/routes/eventCondition/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageEE7YkPn6NHMeta?.name ?? "eventCondition___es",
    path: MainPageEE7YkPn6NHMeta?.path ?? "/es/eventCondition",
    meta: MainPageEE7YkPn6NHMeta || {},
    alias: MainPageEE7YkPn6NHMeta?.alias || [],
    redirect: MainPageEE7YkPn6NHMeta?.redirect,
    component: () => import("/app/layers/dota/routes/eventCondition/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageEE7YkPn6NHMeta?.name ?? "eventCondition___hi",
    path: MainPageEE7YkPn6NHMeta?.path ?? "/hi/eventCondition",
    meta: MainPageEE7YkPn6NHMeta || {},
    alias: MainPageEE7YkPn6NHMeta?.alias || [],
    redirect: MainPageEE7YkPn6NHMeta?.redirect,
    component: () => import("/app/layers/dota/routes/eventCondition/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageEE7YkPn6NHMeta?.name ?? "eventCondition___ph",
    path: MainPageEE7YkPn6NHMeta?.path ?? "/ph/eventCondition",
    meta: MainPageEE7YkPn6NHMeta || {},
    alias: MainPageEE7YkPn6NHMeta?.alias || [],
    redirect: MainPageEE7YkPn6NHMeta?.redirect,
    component: () => import("/app/layers/dota/routes/eventCondition/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageEE7YkPn6NHMeta?.name ?? "eventCondition___id",
    path: MainPageEE7YkPn6NHMeta?.path ?? "/id/eventCondition",
    meta: MainPageEE7YkPn6NHMeta || {},
    alias: MainPageEE7YkPn6NHMeta?.alias || [],
    redirect: MainPageEE7YkPn6NHMeta?.redirect,
    component: () => import("/app/layers/dota/routes/eventCondition/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagemM32X6wTvIMeta?.name ?? "privacy-policy___en",
    path: MainPagemM32X6wTvIMeta?.path ?? "/en/privacy-policy",
    meta: MainPagemM32X6wTvIMeta || {},
    alias: MainPagemM32X6wTvIMeta?.alias || [],
    redirect: MainPagemM32X6wTvIMeta?.redirect,
    component: () => import("/app/layers/dota/routes/privacy-policy/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagemM32X6wTvIMeta?.name ?? "privacy-policy___ru",
    path: MainPagemM32X6wTvIMeta?.path ?? "/privacy-policy",
    meta: MainPagemM32X6wTvIMeta || {},
    alias: MainPagemM32X6wTvIMeta?.alias || [],
    redirect: MainPagemM32X6wTvIMeta?.redirect,
    component: () => import("/app/layers/dota/routes/privacy-policy/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagemM32X6wTvIMeta?.name ?? "privacy-policy___kk",
    path: MainPagemM32X6wTvIMeta?.path ?? "/kk/privacy-policy",
    meta: MainPagemM32X6wTvIMeta || {},
    alias: MainPagemM32X6wTvIMeta?.alias || [],
    redirect: MainPagemM32X6wTvIMeta?.redirect,
    component: () => import("/app/layers/dota/routes/privacy-policy/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagemM32X6wTvIMeta?.name ?? "privacy-policy___az",
    path: MainPagemM32X6wTvIMeta?.path ?? "/az/privacy-policy",
    meta: MainPagemM32X6wTvIMeta || {},
    alias: MainPagemM32X6wTvIMeta?.alias || [],
    redirect: MainPagemM32X6wTvIMeta?.redirect,
    component: () => import("/app/layers/dota/routes/privacy-policy/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagemM32X6wTvIMeta?.name ?? "privacy-policy___uz",
    path: MainPagemM32X6wTvIMeta?.path ?? "/uz/privacy-policy",
    meta: MainPagemM32X6wTvIMeta || {},
    alias: MainPagemM32X6wTvIMeta?.alias || [],
    redirect: MainPagemM32X6wTvIMeta?.redirect,
    component: () => import("/app/layers/dota/routes/privacy-policy/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagemM32X6wTvIMeta?.name ?? "privacy-policy___es",
    path: MainPagemM32X6wTvIMeta?.path ?? "/es/privacy-policy",
    meta: MainPagemM32X6wTvIMeta || {},
    alias: MainPagemM32X6wTvIMeta?.alias || [],
    redirect: MainPagemM32X6wTvIMeta?.redirect,
    component: () => import("/app/layers/dota/routes/privacy-policy/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagemM32X6wTvIMeta?.name ?? "privacy-policy___hi",
    path: MainPagemM32X6wTvIMeta?.path ?? "/hi/privacy-policy",
    meta: MainPagemM32X6wTvIMeta || {},
    alias: MainPagemM32X6wTvIMeta?.alias || [],
    redirect: MainPagemM32X6wTvIMeta?.redirect,
    component: () => import("/app/layers/dota/routes/privacy-policy/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagemM32X6wTvIMeta?.name ?? "privacy-policy___ph",
    path: MainPagemM32X6wTvIMeta?.path ?? "/ph/privacy-policy",
    meta: MainPagemM32X6wTvIMeta || {},
    alias: MainPagemM32X6wTvIMeta?.alias || [],
    redirect: MainPagemM32X6wTvIMeta?.redirect,
    component: () => import("/app/layers/dota/routes/privacy-policy/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagemM32X6wTvIMeta?.name ?? "privacy-policy___id",
    path: MainPagemM32X6wTvIMeta?.path ?? "/id/privacy-policy",
    meta: MainPagemM32X6wTvIMeta || {},
    alias: MainPagemM32X6wTvIMeta?.alias || [],
    redirect: MainPagemM32X6wTvIMeta?.redirect,
    component: () => import("/app/layers/dota/routes/privacy-policy/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPage4xcI1LOwCiMeta?.name ?? "cookies___en",
    path: MainPage4xcI1LOwCiMeta?.path ?? "/en/cookies",
    meta: MainPage4xcI1LOwCiMeta || {},
    alias: MainPage4xcI1LOwCiMeta?.alias || [],
    redirect: MainPage4xcI1LOwCiMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cookies/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPage4xcI1LOwCiMeta?.name ?? "cookies___ru",
    path: MainPage4xcI1LOwCiMeta?.path ?? "/cookies",
    meta: MainPage4xcI1LOwCiMeta || {},
    alias: MainPage4xcI1LOwCiMeta?.alias || [],
    redirect: MainPage4xcI1LOwCiMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cookies/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPage4xcI1LOwCiMeta?.name ?? "cookies___kk",
    path: MainPage4xcI1LOwCiMeta?.path ?? "/kk/cookies",
    meta: MainPage4xcI1LOwCiMeta || {},
    alias: MainPage4xcI1LOwCiMeta?.alias || [],
    redirect: MainPage4xcI1LOwCiMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cookies/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPage4xcI1LOwCiMeta?.name ?? "cookies___az",
    path: MainPage4xcI1LOwCiMeta?.path ?? "/az/cookies",
    meta: MainPage4xcI1LOwCiMeta || {},
    alias: MainPage4xcI1LOwCiMeta?.alias || [],
    redirect: MainPage4xcI1LOwCiMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cookies/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPage4xcI1LOwCiMeta?.name ?? "cookies___uz",
    path: MainPage4xcI1LOwCiMeta?.path ?? "/uz/cookies",
    meta: MainPage4xcI1LOwCiMeta || {},
    alias: MainPage4xcI1LOwCiMeta?.alias || [],
    redirect: MainPage4xcI1LOwCiMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cookies/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPage4xcI1LOwCiMeta?.name ?? "cookies___es",
    path: MainPage4xcI1LOwCiMeta?.path ?? "/es/cookies",
    meta: MainPage4xcI1LOwCiMeta || {},
    alias: MainPage4xcI1LOwCiMeta?.alias || [],
    redirect: MainPage4xcI1LOwCiMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cookies/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPage4xcI1LOwCiMeta?.name ?? "cookies___hi",
    path: MainPage4xcI1LOwCiMeta?.path ?? "/hi/cookies",
    meta: MainPage4xcI1LOwCiMeta || {},
    alias: MainPage4xcI1LOwCiMeta?.alias || [],
    redirect: MainPage4xcI1LOwCiMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cookies/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPage4xcI1LOwCiMeta?.name ?? "cookies___ph",
    path: MainPage4xcI1LOwCiMeta?.path ?? "/ph/cookies",
    meta: MainPage4xcI1LOwCiMeta || {},
    alias: MainPage4xcI1LOwCiMeta?.alias || [],
    redirect: MainPage4xcI1LOwCiMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cookies/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPage4xcI1LOwCiMeta?.name ?? "cookies___id",
    path: MainPage4xcI1LOwCiMeta?.path ?? "/id/cookies",
    meta: MainPage4xcI1LOwCiMeta || {},
    alias: MainPage4xcI1LOwCiMeta?.alias || [],
    redirect: MainPage4xcI1LOwCiMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cookies/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagePJxAKR9ZxRMeta?.name ?? "userAgreement___en",
    path: MainPagePJxAKR9ZxRMeta?.path ?? "/en/userAgreement",
    meta: MainPagePJxAKR9ZxRMeta || {},
    alias: MainPagePJxAKR9ZxRMeta?.alias || [],
    redirect: MainPagePJxAKR9ZxRMeta?.redirect,
    component: () => import("/app/layers/dota/routes/userAgreement/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagePJxAKR9ZxRMeta?.name ?? "userAgreement___ru",
    path: MainPagePJxAKR9ZxRMeta?.path ?? "/userAgreement",
    meta: MainPagePJxAKR9ZxRMeta || {},
    alias: MainPagePJxAKR9ZxRMeta?.alias || [],
    redirect: MainPagePJxAKR9ZxRMeta?.redirect,
    component: () => import("/app/layers/dota/routes/userAgreement/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagePJxAKR9ZxRMeta?.name ?? "userAgreement___kk",
    path: MainPagePJxAKR9ZxRMeta?.path ?? "/kk/userAgreement",
    meta: MainPagePJxAKR9ZxRMeta || {},
    alias: MainPagePJxAKR9ZxRMeta?.alias || [],
    redirect: MainPagePJxAKR9ZxRMeta?.redirect,
    component: () => import("/app/layers/dota/routes/userAgreement/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagePJxAKR9ZxRMeta?.name ?? "userAgreement___az",
    path: MainPagePJxAKR9ZxRMeta?.path ?? "/az/userAgreement",
    meta: MainPagePJxAKR9ZxRMeta || {},
    alias: MainPagePJxAKR9ZxRMeta?.alias || [],
    redirect: MainPagePJxAKR9ZxRMeta?.redirect,
    component: () => import("/app/layers/dota/routes/userAgreement/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagePJxAKR9ZxRMeta?.name ?? "userAgreement___uz",
    path: MainPagePJxAKR9ZxRMeta?.path ?? "/uz/userAgreement",
    meta: MainPagePJxAKR9ZxRMeta || {},
    alias: MainPagePJxAKR9ZxRMeta?.alias || [],
    redirect: MainPagePJxAKR9ZxRMeta?.redirect,
    component: () => import("/app/layers/dota/routes/userAgreement/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagePJxAKR9ZxRMeta?.name ?? "userAgreement___es",
    path: MainPagePJxAKR9ZxRMeta?.path ?? "/es/userAgreement",
    meta: MainPagePJxAKR9ZxRMeta || {},
    alias: MainPagePJxAKR9ZxRMeta?.alias || [],
    redirect: MainPagePJxAKR9ZxRMeta?.redirect,
    component: () => import("/app/layers/dota/routes/userAgreement/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagePJxAKR9ZxRMeta?.name ?? "userAgreement___hi",
    path: MainPagePJxAKR9ZxRMeta?.path ?? "/hi/userAgreement",
    meta: MainPagePJxAKR9ZxRMeta || {},
    alias: MainPagePJxAKR9ZxRMeta?.alias || [],
    redirect: MainPagePJxAKR9ZxRMeta?.redirect,
    component: () => import("/app/layers/dota/routes/userAgreement/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagePJxAKR9ZxRMeta?.name ?? "userAgreement___ph",
    path: MainPagePJxAKR9ZxRMeta?.path ?? "/ph/userAgreement",
    meta: MainPagePJxAKR9ZxRMeta || {},
    alias: MainPagePJxAKR9ZxRMeta?.alias || [],
    redirect: MainPagePJxAKR9ZxRMeta?.redirect,
    component: () => import("/app/layers/dota/routes/userAgreement/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagePJxAKR9ZxRMeta?.name ?? "userAgreement___id",
    path: MainPagePJxAKR9ZxRMeta?.path ?? "/id/userAgreement",
    meta: MainPagePJxAKR9ZxRMeta || {},
    alias: MainPagePJxAKR9ZxRMeta?.alias || [],
    redirect: MainPagePJxAKR9ZxRMeta?.redirect,
    component: () => import("/app/layers/dota/routes/userAgreement/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagelWSqxp92D4Meta?.name ?? "contacts___en",
    path: MainPagelWSqxp92D4Meta?.path ?? "/en/contacts",
    meta: MainPagelWSqxp92D4Meta || {},
    alias: MainPagelWSqxp92D4Meta?.alias || [],
    redirect: MainPagelWSqxp92D4Meta?.redirect,
    component: () => import("/app/layers/dota/routes/contacts/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagelWSqxp92D4Meta?.name ?? "contacts___ru",
    path: MainPagelWSqxp92D4Meta?.path ?? "/contacts",
    meta: MainPagelWSqxp92D4Meta || {},
    alias: MainPagelWSqxp92D4Meta?.alias || [],
    redirect: MainPagelWSqxp92D4Meta?.redirect,
    component: () => import("/app/layers/dota/routes/contacts/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagelWSqxp92D4Meta?.name ?? "contacts___kk",
    path: MainPagelWSqxp92D4Meta?.path ?? "/kk/contacts",
    meta: MainPagelWSqxp92D4Meta || {},
    alias: MainPagelWSqxp92D4Meta?.alias || [],
    redirect: MainPagelWSqxp92D4Meta?.redirect,
    component: () => import("/app/layers/dota/routes/contacts/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagelWSqxp92D4Meta?.name ?? "contacts___az",
    path: MainPagelWSqxp92D4Meta?.path ?? "/az/contacts",
    meta: MainPagelWSqxp92D4Meta || {},
    alias: MainPagelWSqxp92D4Meta?.alias || [],
    redirect: MainPagelWSqxp92D4Meta?.redirect,
    component: () => import("/app/layers/dota/routes/contacts/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagelWSqxp92D4Meta?.name ?? "contacts___uz",
    path: MainPagelWSqxp92D4Meta?.path ?? "/uz/contacts",
    meta: MainPagelWSqxp92D4Meta || {},
    alias: MainPagelWSqxp92D4Meta?.alias || [],
    redirect: MainPagelWSqxp92D4Meta?.redirect,
    component: () => import("/app/layers/dota/routes/contacts/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagelWSqxp92D4Meta?.name ?? "contacts___es",
    path: MainPagelWSqxp92D4Meta?.path ?? "/es/contacts",
    meta: MainPagelWSqxp92D4Meta || {},
    alias: MainPagelWSqxp92D4Meta?.alias || [],
    redirect: MainPagelWSqxp92D4Meta?.redirect,
    component: () => import("/app/layers/dota/routes/contacts/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagelWSqxp92D4Meta?.name ?? "contacts___hi",
    path: MainPagelWSqxp92D4Meta?.path ?? "/hi/contacts",
    meta: MainPagelWSqxp92D4Meta || {},
    alias: MainPagelWSqxp92D4Meta?.alias || [],
    redirect: MainPagelWSqxp92D4Meta?.redirect,
    component: () => import("/app/layers/dota/routes/contacts/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagelWSqxp92D4Meta?.name ?? "contacts___ph",
    path: MainPagelWSqxp92D4Meta?.path ?? "/ph/contacts",
    meta: MainPagelWSqxp92D4Meta || {},
    alias: MainPagelWSqxp92D4Meta?.alias || [],
    redirect: MainPagelWSqxp92D4Meta?.redirect,
    component: () => import("/app/layers/dota/routes/contacts/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagelWSqxp92D4Meta?.name ?? "contacts___id",
    path: MainPagelWSqxp92D4Meta?.path ?? "/id/contacts",
    meta: MainPagelWSqxp92D4Meta || {},
    alias: MainPagelWSqxp92D4Meta?.alias || [],
    redirect: MainPagelWSqxp92D4Meta?.redirect,
    component: () => import("/app/layers/dota/routes/contacts/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: IFrameControllerd7RJ4XI8cIMeta?.name ?? "iframe-data___en",
    path: IFrameControllerd7RJ4XI8cIMeta?.path ?? "/en/integrator/:integrator_uuid/iframe/case/:case_name",
    meta: { ...(IFrameControllerd7RJ4XI8cIMeta || {}), ...{"isIntegratorModule":true} },
    alias: IFrameControllerd7RJ4XI8cIMeta?.alias || [],
    redirect: IFrameControllerd7RJ4XI8cIMeta?.redirect,
    component: () => import("/app/features/bk/controllers/IFrameController/IFrameController.vue").then(m => m.default || m)
  },
  {
    name: IFrameControllerd7RJ4XI8cIMeta?.name ?? "iframe-data___ru",
    path: IFrameControllerd7RJ4XI8cIMeta?.path ?? "/integrator/:integrator_uuid/iframe/case/:case_name",
    meta: { ...(IFrameControllerd7RJ4XI8cIMeta || {}), ...{"isIntegratorModule":true} },
    alias: IFrameControllerd7RJ4XI8cIMeta?.alias || [],
    redirect: IFrameControllerd7RJ4XI8cIMeta?.redirect,
    component: () => import("/app/features/bk/controllers/IFrameController/IFrameController.vue").then(m => m.default || m)
  },
  {
    name: IFrameControllerd7RJ4XI8cIMeta?.name ?? "iframe-data___kk",
    path: IFrameControllerd7RJ4XI8cIMeta?.path ?? "/kk/integrator/:integrator_uuid/iframe/case/:case_name",
    meta: { ...(IFrameControllerd7RJ4XI8cIMeta || {}), ...{"isIntegratorModule":true} },
    alias: IFrameControllerd7RJ4XI8cIMeta?.alias || [],
    redirect: IFrameControllerd7RJ4XI8cIMeta?.redirect,
    component: () => import("/app/features/bk/controllers/IFrameController/IFrameController.vue").then(m => m.default || m)
  },
  {
    name: IFrameControllerd7RJ4XI8cIMeta?.name ?? "iframe-data___az",
    path: IFrameControllerd7RJ4XI8cIMeta?.path ?? "/az/integrator/:integrator_uuid/iframe/case/:case_name",
    meta: { ...(IFrameControllerd7RJ4XI8cIMeta || {}), ...{"isIntegratorModule":true} },
    alias: IFrameControllerd7RJ4XI8cIMeta?.alias || [],
    redirect: IFrameControllerd7RJ4XI8cIMeta?.redirect,
    component: () => import("/app/features/bk/controllers/IFrameController/IFrameController.vue").then(m => m.default || m)
  },
  {
    name: IFrameControllerd7RJ4XI8cIMeta?.name ?? "iframe-data___uz",
    path: IFrameControllerd7RJ4XI8cIMeta?.path ?? "/uz/integrator/:integrator_uuid/iframe/case/:case_name",
    meta: { ...(IFrameControllerd7RJ4XI8cIMeta || {}), ...{"isIntegratorModule":true} },
    alias: IFrameControllerd7RJ4XI8cIMeta?.alias || [],
    redirect: IFrameControllerd7RJ4XI8cIMeta?.redirect,
    component: () => import("/app/features/bk/controllers/IFrameController/IFrameController.vue").then(m => m.default || m)
  },
  {
    name: IFrameControllerd7RJ4XI8cIMeta?.name ?? "iframe-data___es",
    path: IFrameControllerd7RJ4XI8cIMeta?.path ?? "/es/integrator/:integrator_uuid/iframe/case/:case_name",
    meta: { ...(IFrameControllerd7RJ4XI8cIMeta || {}), ...{"isIntegratorModule":true} },
    alias: IFrameControllerd7RJ4XI8cIMeta?.alias || [],
    redirect: IFrameControllerd7RJ4XI8cIMeta?.redirect,
    component: () => import("/app/features/bk/controllers/IFrameController/IFrameController.vue").then(m => m.default || m)
  },
  {
    name: IFrameControllerd7RJ4XI8cIMeta?.name ?? "iframe-data___hi",
    path: IFrameControllerd7RJ4XI8cIMeta?.path ?? "/hi/integrator/:integrator_uuid/iframe/case/:case_name",
    meta: { ...(IFrameControllerd7RJ4XI8cIMeta || {}), ...{"isIntegratorModule":true} },
    alias: IFrameControllerd7RJ4XI8cIMeta?.alias || [],
    redirect: IFrameControllerd7RJ4XI8cIMeta?.redirect,
    component: () => import("/app/features/bk/controllers/IFrameController/IFrameController.vue").then(m => m.default || m)
  },
  {
    name: IFrameControllerd7RJ4XI8cIMeta?.name ?? "iframe-data___ph",
    path: IFrameControllerd7RJ4XI8cIMeta?.path ?? "/ph/integrator/:integrator_uuid/iframe/case/:case_name",
    meta: { ...(IFrameControllerd7RJ4XI8cIMeta || {}), ...{"isIntegratorModule":true} },
    alias: IFrameControllerd7RJ4XI8cIMeta?.alias || [],
    redirect: IFrameControllerd7RJ4XI8cIMeta?.redirect,
    component: () => import("/app/features/bk/controllers/IFrameController/IFrameController.vue").then(m => m.default || m)
  },
  {
    name: IFrameControllerd7RJ4XI8cIMeta?.name ?? "iframe-data___id",
    path: IFrameControllerd7RJ4XI8cIMeta?.path ?? "/id/integrator/:integrator_uuid/iframe/case/:case_name",
    meta: { ...(IFrameControllerd7RJ4XI8cIMeta || {}), ...{"isIntegratorModule":true} },
    alias: IFrameControllerd7RJ4XI8cIMeta?.alias || [],
    redirect: IFrameControllerd7RJ4XI8cIMeta?.redirect,
    component: () => import("/app/features/bk/controllers/IFrameController/IFrameController.vue").then(m => m.default || m)
  },
  {
    name: IFrameTestController2jZinoTdeQMeta?.name ?? "iframe-testing___en",
    path: IFrameTestController2jZinoTdeQMeta?.path ?? "/en/integrator/:integrator_uuid/iframe/test",
    meta: { ...(IFrameTestController2jZinoTdeQMeta || {}), ...{"isIntegratorModule":true} },
    alias: IFrameTestController2jZinoTdeQMeta?.alias || [],
    redirect: IFrameTestController2jZinoTdeQMeta?.redirect,
    component: () => import("/app/features/bk/controllers/IFrameTestController/IFrameTestController.vue").then(m => m.default || m)
  },
  {
    name: IFrameTestController2jZinoTdeQMeta?.name ?? "iframe-testing___ru",
    path: IFrameTestController2jZinoTdeQMeta?.path ?? "/integrator/:integrator_uuid/iframe/test",
    meta: { ...(IFrameTestController2jZinoTdeQMeta || {}), ...{"isIntegratorModule":true} },
    alias: IFrameTestController2jZinoTdeQMeta?.alias || [],
    redirect: IFrameTestController2jZinoTdeQMeta?.redirect,
    component: () => import("/app/features/bk/controllers/IFrameTestController/IFrameTestController.vue").then(m => m.default || m)
  },
  {
    name: IFrameTestController2jZinoTdeQMeta?.name ?? "iframe-testing___kk",
    path: IFrameTestController2jZinoTdeQMeta?.path ?? "/kk/integrator/:integrator_uuid/iframe/test",
    meta: { ...(IFrameTestController2jZinoTdeQMeta || {}), ...{"isIntegratorModule":true} },
    alias: IFrameTestController2jZinoTdeQMeta?.alias || [],
    redirect: IFrameTestController2jZinoTdeQMeta?.redirect,
    component: () => import("/app/features/bk/controllers/IFrameTestController/IFrameTestController.vue").then(m => m.default || m)
  },
  {
    name: IFrameTestController2jZinoTdeQMeta?.name ?? "iframe-testing___az",
    path: IFrameTestController2jZinoTdeQMeta?.path ?? "/az/integrator/:integrator_uuid/iframe/test",
    meta: { ...(IFrameTestController2jZinoTdeQMeta || {}), ...{"isIntegratorModule":true} },
    alias: IFrameTestController2jZinoTdeQMeta?.alias || [],
    redirect: IFrameTestController2jZinoTdeQMeta?.redirect,
    component: () => import("/app/features/bk/controllers/IFrameTestController/IFrameTestController.vue").then(m => m.default || m)
  },
  {
    name: IFrameTestController2jZinoTdeQMeta?.name ?? "iframe-testing___uz",
    path: IFrameTestController2jZinoTdeQMeta?.path ?? "/uz/integrator/:integrator_uuid/iframe/test",
    meta: { ...(IFrameTestController2jZinoTdeQMeta || {}), ...{"isIntegratorModule":true} },
    alias: IFrameTestController2jZinoTdeQMeta?.alias || [],
    redirect: IFrameTestController2jZinoTdeQMeta?.redirect,
    component: () => import("/app/features/bk/controllers/IFrameTestController/IFrameTestController.vue").then(m => m.default || m)
  },
  {
    name: IFrameTestController2jZinoTdeQMeta?.name ?? "iframe-testing___es",
    path: IFrameTestController2jZinoTdeQMeta?.path ?? "/es/integrator/:integrator_uuid/iframe/test",
    meta: { ...(IFrameTestController2jZinoTdeQMeta || {}), ...{"isIntegratorModule":true} },
    alias: IFrameTestController2jZinoTdeQMeta?.alias || [],
    redirect: IFrameTestController2jZinoTdeQMeta?.redirect,
    component: () => import("/app/features/bk/controllers/IFrameTestController/IFrameTestController.vue").then(m => m.default || m)
  },
  {
    name: IFrameTestController2jZinoTdeQMeta?.name ?? "iframe-testing___hi",
    path: IFrameTestController2jZinoTdeQMeta?.path ?? "/hi/integrator/:integrator_uuid/iframe/test",
    meta: { ...(IFrameTestController2jZinoTdeQMeta || {}), ...{"isIntegratorModule":true} },
    alias: IFrameTestController2jZinoTdeQMeta?.alias || [],
    redirect: IFrameTestController2jZinoTdeQMeta?.redirect,
    component: () => import("/app/features/bk/controllers/IFrameTestController/IFrameTestController.vue").then(m => m.default || m)
  },
  {
    name: IFrameTestController2jZinoTdeQMeta?.name ?? "iframe-testing___ph",
    path: IFrameTestController2jZinoTdeQMeta?.path ?? "/ph/integrator/:integrator_uuid/iframe/test",
    meta: { ...(IFrameTestController2jZinoTdeQMeta || {}), ...{"isIntegratorModule":true} },
    alias: IFrameTestController2jZinoTdeQMeta?.alias || [],
    redirect: IFrameTestController2jZinoTdeQMeta?.redirect,
    component: () => import("/app/features/bk/controllers/IFrameTestController/IFrameTestController.vue").then(m => m.default || m)
  },
  {
    name: IFrameTestController2jZinoTdeQMeta?.name ?? "iframe-testing___id",
    path: IFrameTestController2jZinoTdeQMeta?.path ?? "/id/integrator/:integrator_uuid/iframe/test",
    meta: { ...(IFrameTestController2jZinoTdeQMeta || {}), ...{"isIntegratorModule":true} },
    alias: IFrameTestController2jZinoTdeQMeta?.alias || [],
    redirect: IFrameTestController2jZinoTdeQMeta?.redirect,
    component: () => import("/app/features/bk/controllers/IFrameTestController/IFrameTestController.vue").then(m => m.default || m)
  },
  {
    name: DropPage5wwAajUKztMeta?.name ?? "drop-page___en",
    path: DropPage5wwAajUKztMeta?.path ?? "/en/open/:caseName/:gameId",
    meta: DropPage5wwAajUKztMeta || {},
    alias: DropPage5wwAajUKztMeta?.alias || [],
    redirect: DropPage5wwAajUKztMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cases/DropPage/DropPage.vue").then(m => m.default || m)
  },
  {
    name: DropPage5wwAajUKztMeta?.name ?? "drop-page___ru",
    path: DropPage5wwAajUKztMeta?.path ?? "/open/:caseName/:gameId",
    meta: DropPage5wwAajUKztMeta || {},
    alias: DropPage5wwAajUKztMeta?.alias || [],
    redirect: DropPage5wwAajUKztMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cases/DropPage/DropPage.vue").then(m => m.default || m)
  },
  {
    name: DropPage5wwAajUKztMeta?.name ?? "drop-page___kk",
    path: DropPage5wwAajUKztMeta?.path ?? "/kk/open/:caseName/:gameId",
    meta: DropPage5wwAajUKztMeta || {},
    alias: DropPage5wwAajUKztMeta?.alias || [],
    redirect: DropPage5wwAajUKztMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cases/DropPage/DropPage.vue").then(m => m.default || m)
  },
  {
    name: DropPage5wwAajUKztMeta?.name ?? "drop-page___az",
    path: DropPage5wwAajUKztMeta?.path ?? "/az/open/:caseName/:gameId",
    meta: DropPage5wwAajUKztMeta || {},
    alias: DropPage5wwAajUKztMeta?.alias || [],
    redirect: DropPage5wwAajUKztMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cases/DropPage/DropPage.vue").then(m => m.default || m)
  },
  {
    name: DropPage5wwAajUKztMeta?.name ?? "drop-page___uz",
    path: DropPage5wwAajUKztMeta?.path ?? "/uz/open/:caseName/:gameId",
    meta: DropPage5wwAajUKztMeta || {},
    alias: DropPage5wwAajUKztMeta?.alias || [],
    redirect: DropPage5wwAajUKztMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cases/DropPage/DropPage.vue").then(m => m.default || m)
  },
  {
    name: DropPage5wwAajUKztMeta?.name ?? "drop-page___es",
    path: DropPage5wwAajUKztMeta?.path ?? "/es/open/:caseName/:gameId",
    meta: DropPage5wwAajUKztMeta || {},
    alias: DropPage5wwAajUKztMeta?.alias || [],
    redirect: DropPage5wwAajUKztMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cases/DropPage/DropPage.vue").then(m => m.default || m)
  },
  {
    name: DropPage5wwAajUKztMeta?.name ?? "drop-page___hi",
    path: DropPage5wwAajUKztMeta?.path ?? "/hi/open/:caseName/:gameId",
    meta: DropPage5wwAajUKztMeta || {},
    alias: DropPage5wwAajUKztMeta?.alias || [],
    redirect: DropPage5wwAajUKztMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cases/DropPage/DropPage.vue").then(m => m.default || m)
  },
  {
    name: DropPage5wwAajUKztMeta?.name ?? "drop-page___ph",
    path: DropPage5wwAajUKztMeta?.path ?? "/ph/open/:caseName/:gameId",
    meta: DropPage5wwAajUKztMeta || {},
    alias: DropPage5wwAajUKztMeta?.alias || [],
    redirect: DropPage5wwAajUKztMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cases/DropPage/DropPage.vue").then(m => m.default || m)
  },
  {
    name: DropPage5wwAajUKztMeta?.name ?? "drop-page___id",
    path: DropPage5wwAajUKztMeta?.path ?? "/id/open/:caseName/:gameId",
    meta: DropPage5wwAajUKztMeta || {},
    alias: DropPage5wwAajUKztMeta?.alias || [],
    redirect: DropPage5wwAajUKztMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cases/DropPage/DropPage.vue").then(m => m.default || m)
  },
  {
    name: OpenPagevR5vXml3LAMeta?.name ?? "open-page___en",
    path: OpenPagevR5vXml3LAMeta?.path ?? "/en/open/:caseName",
    meta: OpenPagevR5vXml3LAMeta || {},
    alias: OpenPagevR5vXml3LAMeta?.alias || [],
    redirect: OpenPagevR5vXml3LAMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cases/OpenPageDev/OpenPage.vue").then(m => m.default || m)
  },
  {
    name: OpenPagevR5vXml3LAMeta?.name ?? "open-page___ru",
    path: OpenPagevR5vXml3LAMeta?.path ?? "/open/:caseName",
    meta: OpenPagevR5vXml3LAMeta || {},
    alias: OpenPagevR5vXml3LAMeta?.alias || [],
    redirect: OpenPagevR5vXml3LAMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cases/OpenPageDev/OpenPage.vue").then(m => m.default || m)
  },
  {
    name: OpenPagevR5vXml3LAMeta?.name ?? "open-page___kk",
    path: OpenPagevR5vXml3LAMeta?.path ?? "/kk/open/:caseName",
    meta: OpenPagevR5vXml3LAMeta || {},
    alias: OpenPagevR5vXml3LAMeta?.alias || [],
    redirect: OpenPagevR5vXml3LAMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cases/OpenPageDev/OpenPage.vue").then(m => m.default || m)
  },
  {
    name: OpenPagevR5vXml3LAMeta?.name ?? "open-page___az",
    path: OpenPagevR5vXml3LAMeta?.path ?? "/az/open/:caseName",
    meta: OpenPagevR5vXml3LAMeta || {},
    alias: OpenPagevR5vXml3LAMeta?.alias || [],
    redirect: OpenPagevR5vXml3LAMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cases/OpenPageDev/OpenPage.vue").then(m => m.default || m)
  },
  {
    name: OpenPagevR5vXml3LAMeta?.name ?? "open-page___uz",
    path: OpenPagevR5vXml3LAMeta?.path ?? "/uz/open/:caseName",
    meta: OpenPagevR5vXml3LAMeta || {},
    alias: OpenPagevR5vXml3LAMeta?.alias || [],
    redirect: OpenPagevR5vXml3LAMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cases/OpenPageDev/OpenPage.vue").then(m => m.default || m)
  },
  {
    name: OpenPagevR5vXml3LAMeta?.name ?? "open-page___es",
    path: OpenPagevR5vXml3LAMeta?.path ?? "/es/open/:caseName",
    meta: OpenPagevR5vXml3LAMeta || {},
    alias: OpenPagevR5vXml3LAMeta?.alias || [],
    redirect: OpenPagevR5vXml3LAMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cases/OpenPageDev/OpenPage.vue").then(m => m.default || m)
  },
  {
    name: OpenPagevR5vXml3LAMeta?.name ?? "open-page___hi",
    path: OpenPagevR5vXml3LAMeta?.path ?? "/hi/open/:caseName",
    meta: OpenPagevR5vXml3LAMeta || {},
    alias: OpenPagevR5vXml3LAMeta?.alias || [],
    redirect: OpenPagevR5vXml3LAMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cases/OpenPageDev/OpenPage.vue").then(m => m.default || m)
  },
  {
    name: OpenPagevR5vXml3LAMeta?.name ?? "open-page___ph",
    path: OpenPagevR5vXml3LAMeta?.path ?? "/ph/open/:caseName",
    meta: OpenPagevR5vXml3LAMeta || {},
    alias: OpenPagevR5vXml3LAMeta?.alias || [],
    redirect: OpenPagevR5vXml3LAMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cases/OpenPageDev/OpenPage.vue").then(m => m.default || m)
  },
  {
    name: OpenPagevR5vXml3LAMeta?.name ?? "open-page___id",
    path: OpenPagevR5vXml3LAMeta?.path ?? "/id/open/:caseName",
    meta: OpenPagevR5vXml3LAMeta || {},
    alias: OpenPagevR5vXml3LAMeta?.alias || [],
    redirect: OpenPagevR5vXml3LAMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cases/OpenPageDev/OpenPage.vue").then(m => m.default || m)
  },
  {
    name: TestSimpleCasea3yDs2UPNoMeta?.name ?? "test-open-page___en",
    path: TestSimpleCasea3yDs2UPNoMeta?.path ?? "/en/test-open/:caseName",
    meta: TestSimpleCasea3yDs2UPNoMeta || {},
    alias: TestSimpleCasea3yDs2UPNoMeta?.alias || [],
    redirect: TestSimpleCasea3yDs2UPNoMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cases/TestSimpleCase/TestSimpleCase.vue").then(m => m.default || m)
  },
  {
    name: TestSimpleCasea3yDs2UPNoMeta?.name ?? "test-open-page___ru",
    path: TestSimpleCasea3yDs2UPNoMeta?.path ?? "/test-open/:caseName",
    meta: TestSimpleCasea3yDs2UPNoMeta || {},
    alias: TestSimpleCasea3yDs2UPNoMeta?.alias || [],
    redirect: TestSimpleCasea3yDs2UPNoMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cases/TestSimpleCase/TestSimpleCase.vue").then(m => m.default || m)
  },
  {
    name: TestSimpleCasea3yDs2UPNoMeta?.name ?? "test-open-page___kk",
    path: TestSimpleCasea3yDs2UPNoMeta?.path ?? "/kk/test-open/:caseName",
    meta: TestSimpleCasea3yDs2UPNoMeta || {},
    alias: TestSimpleCasea3yDs2UPNoMeta?.alias || [],
    redirect: TestSimpleCasea3yDs2UPNoMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cases/TestSimpleCase/TestSimpleCase.vue").then(m => m.default || m)
  },
  {
    name: TestSimpleCasea3yDs2UPNoMeta?.name ?? "test-open-page___az",
    path: TestSimpleCasea3yDs2UPNoMeta?.path ?? "/az/test-open/:caseName",
    meta: TestSimpleCasea3yDs2UPNoMeta || {},
    alias: TestSimpleCasea3yDs2UPNoMeta?.alias || [],
    redirect: TestSimpleCasea3yDs2UPNoMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cases/TestSimpleCase/TestSimpleCase.vue").then(m => m.default || m)
  },
  {
    name: TestSimpleCasea3yDs2UPNoMeta?.name ?? "test-open-page___uz",
    path: TestSimpleCasea3yDs2UPNoMeta?.path ?? "/uz/test-open/:caseName",
    meta: TestSimpleCasea3yDs2UPNoMeta || {},
    alias: TestSimpleCasea3yDs2UPNoMeta?.alias || [],
    redirect: TestSimpleCasea3yDs2UPNoMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cases/TestSimpleCase/TestSimpleCase.vue").then(m => m.default || m)
  },
  {
    name: TestSimpleCasea3yDs2UPNoMeta?.name ?? "test-open-page___es",
    path: TestSimpleCasea3yDs2UPNoMeta?.path ?? "/es/test-open/:caseName",
    meta: TestSimpleCasea3yDs2UPNoMeta || {},
    alias: TestSimpleCasea3yDs2UPNoMeta?.alias || [],
    redirect: TestSimpleCasea3yDs2UPNoMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cases/TestSimpleCase/TestSimpleCase.vue").then(m => m.default || m)
  },
  {
    name: TestSimpleCasea3yDs2UPNoMeta?.name ?? "test-open-page___hi",
    path: TestSimpleCasea3yDs2UPNoMeta?.path ?? "/hi/test-open/:caseName",
    meta: TestSimpleCasea3yDs2UPNoMeta || {},
    alias: TestSimpleCasea3yDs2UPNoMeta?.alias || [],
    redirect: TestSimpleCasea3yDs2UPNoMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cases/TestSimpleCase/TestSimpleCase.vue").then(m => m.default || m)
  },
  {
    name: TestSimpleCasea3yDs2UPNoMeta?.name ?? "test-open-page___ph",
    path: TestSimpleCasea3yDs2UPNoMeta?.path ?? "/ph/test-open/:caseName",
    meta: TestSimpleCasea3yDs2UPNoMeta || {},
    alias: TestSimpleCasea3yDs2UPNoMeta?.alias || [],
    redirect: TestSimpleCasea3yDs2UPNoMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cases/TestSimpleCase/TestSimpleCase.vue").then(m => m.default || m)
  },
  {
    name: TestSimpleCasea3yDs2UPNoMeta?.name ?? "test-open-page___id",
    path: TestSimpleCasea3yDs2UPNoMeta?.path ?? "/id/test-open/:caseName",
    meta: TestSimpleCasea3yDs2UPNoMeta || {},
    alias: TestSimpleCasea3yDs2UPNoMeta?.alias || [],
    redirect: TestSimpleCasea3yDs2UPNoMeta?.redirect,
    component: () => import("/app/layers/dota/routes/cases/TestSimpleCase/TestSimpleCase.vue").then(m => m.default || m)
  },
  {
    name: MainPageJODkIn2wd3Meta?.name ?? "cardform___en",
    path: MainPageJODkIn2wd3Meta?.path ?? "/en/cardform",
    meta: MainPageJODkIn2wd3Meta || {},
    alias: MainPageJODkIn2wd3Meta?.alias || [],
    redirect: MainPageJODkIn2wd3Meta?.redirect,
    component: () => import("/app/layers/dota/routes/cardForm/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageJODkIn2wd3Meta?.name ?? "cardform___ru",
    path: MainPageJODkIn2wd3Meta?.path ?? "/cardform",
    meta: MainPageJODkIn2wd3Meta || {},
    alias: MainPageJODkIn2wd3Meta?.alias || [],
    redirect: MainPageJODkIn2wd3Meta?.redirect,
    component: () => import("/app/layers/dota/routes/cardForm/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageJODkIn2wd3Meta?.name ?? "cardform___kk",
    path: MainPageJODkIn2wd3Meta?.path ?? "/kk/cardform",
    meta: MainPageJODkIn2wd3Meta || {},
    alias: MainPageJODkIn2wd3Meta?.alias || [],
    redirect: MainPageJODkIn2wd3Meta?.redirect,
    component: () => import("/app/layers/dota/routes/cardForm/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageJODkIn2wd3Meta?.name ?? "cardform___az",
    path: MainPageJODkIn2wd3Meta?.path ?? "/az/cardform",
    meta: MainPageJODkIn2wd3Meta || {},
    alias: MainPageJODkIn2wd3Meta?.alias || [],
    redirect: MainPageJODkIn2wd3Meta?.redirect,
    component: () => import("/app/layers/dota/routes/cardForm/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageJODkIn2wd3Meta?.name ?? "cardform___uz",
    path: MainPageJODkIn2wd3Meta?.path ?? "/uz/cardform",
    meta: MainPageJODkIn2wd3Meta || {},
    alias: MainPageJODkIn2wd3Meta?.alias || [],
    redirect: MainPageJODkIn2wd3Meta?.redirect,
    component: () => import("/app/layers/dota/routes/cardForm/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageJODkIn2wd3Meta?.name ?? "cardform___es",
    path: MainPageJODkIn2wd3Meta?.path ?? "/es/cardform",
    meta: MainPageJODkIn2wd3Meta || {},
    alias: MainPageJODkIn2wd3Meta?.alias || [],
    redirect: MainPageJODkIn2wd3Meta?.redirect,
    component: () => import("/app/layers/dota/routes/cardForm/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageJODkIn2wd3Meta?.name ?? "cardform___hi",
    path: MainPageJODkIn2wd3Meta?.path ?? "/hi/cardform",
    meta: MainPageJODkIn2wd3Meta || {},
    alias: MainPageJODkIn2wd3Meta?.alias || [],
    redirect: MainPageJODkIn2wd3Meta?.redirect,
    component: () => import("/app/layers/dota/routes/cardForm/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageJODkIn2wd3Meta?.name ?? "cardform___ph",
    path: MainPageJODkIn2wd3Meta?.path ?? "/ph/cardform",
    meta: MainPageJODkIn2wd3Meta || {},
    alias: MainPageJODkIn2wd3Meta?.alias || [],
    redirect: MainPageJODkIn2wd3Meta?.redirect,
    component: () => import("/app/layers/dota/routes/cardForm/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageJODkIn2wd3Meta?.name ?? "cardform___id",
    path: MainPageJODkIn2wd3Meta?.path ?? "/id/cardform",
    meta: MainPageJODkIn2wd3Meta || {},
    alias: MainPageJODkIn2wd3Meta?.alias || [],
    redirect: MainPageJODkIn2wd3Meta?.redirect,
    component: () => import("/app/layers/dota/routes/cardForm/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageYmytrE2UerMeta?.name ?? "payment___en",
    path: MainPageYmytrE2UerMeta?.path ?? "/en/refill",
    meta: MainPageYmytrE2UerMeta || {},
    alias: MainPageYmytrE2UerMeta?.alias || [],
    redirect: MainPageYmytrE2UerMeta?.redirect,
    component: () => import("/app/layers/dota/routes/payment/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageYmytrE2UerMeta?.name ?? "payment___ru",
    path: MainPageYmytrE2UerMeta?.path ?? "/refill",
    meta: MainPageYmytrE2UerMeta || {},
    alias: MainPageYmytrE2UerMeta?.alias || [],
    redirect: MainPageYmytrE2UerMeta?.redirect,
    component: () => import("/app/layers/dota/routes/payment/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageYmytrE2UerMeta?.name ?? "payment___kk",
    path: MainPageYmytrE2UerMeta?.path ?? "/kk/refill",
    meta: MainPageYmytrE2UerMeta || {},
    alias: MainPageYmytrE2UerMeta?.alias || [],
    redirect: MainPageYmytrE2UerMeta?.redirect,
    component: () => import("/app/layers/dota/routes/payment/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageYmytrE2UerMeta?.name ?? "payment___az",
    path: MainPageYmytrE2UerMeta?.path ?? "/az/refill",
    meta: MainPageYmytrE2UerMeta || {},
    alias: MainPageYmytrE2UerMeta?.alias || [],
    redirect: MainPageYmytrE2UerMeta?.redirect,
    component: () => import("/app/layers/dota/routes/payment/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageYmytrE2UerMeta?.name ?? "payment___uz",
    path: MainPageYmytrE2UerMeta?.path ?? "/uz/refill",
    meta: MainPageYmytrE2UerMeta || {},
    alias: MainPageYmytrE2UerMeta?.alias || [],
    redirect: MainPageYmytrE2UerMeta?.redirect,
    component: () => import("/app/layers/dota/routes/payment/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageYmytrE2UerMeta?.name ?? "payment___es",
    path: MainPageYmytrE2UerMeta?.path ?? "/es/refill",
    meta: MainPageYmytrE2UerMeta || {},
    alias: MainPageYmytrE2UerMeta?.alias || [],
    redirect: MainPageYmytrE2UerMeta?.redirect,
    component: () => import("/app/layers/dota/routes/payment/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageYmytrE2UerMeta?.name ?? "payment___hi",
    path: MainPageYmytrE2UerMeta?.path ?? "/hi/refill",
    meta: MainPageYmytrE2UerMeta || {},
    alias: MainPageYmytrE2UerMeta?.alias || [],
    redirect: MainPageYmytrE2UerMeta?.redirect,
    component: () => import("/app/layers/dota/routes/payment/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageYmytrE2UerMeta?.name ?? "payment___ph",
    path: MainPageYmytrE2UerMeta?.path ?? "/ph/refill",
    meta: MainPageYmytrE2UerMeta || {},
    alias: MainPageYmytrE2UerMeta?.alias || [],
    redirect: MainPageYmytrE2UerMeta?.redirect,
    component: () => import("/app/layers/dota/routes/payment/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageYmytrE2UerMeta?.name ?? "payment___id",
    path: MainPageYmytrE2UerMeta?.path ?? "/id/refill",
    meta: MainPageYmytrE2UerMeta || {},
    alias: MainPageYmytrE2UerMeta?.alias || [],
    redirect: MainPageYmytrE2UerMeta?.redirect,
    component: () => import("/app/layers/dota/routes/payment/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: ResultPageBASZ0VjGTlMeta?.name ?? "payment-result___en",
    path: ResultPageBASZ0VjGTlMeta?.path ?? "/en/refill/result",
    meta: ResultPageBASZ0VjGTlMeta || {},
    alias: ResultPageBASZ0VjGTlMeta?.alias || [],
    redirect: ResultPageBASZ0VjGTlMeta?.redirect,
    component: () => import("/app/layers/dota/routes/payment/ResultPage/ResultPage.vue").then(m => m.default || m)
  },
  {
    name: ResultPageBASZ0VjGTlMeta?.name ?? "payment-result___ru",
    path: ResultPageBASZ0VjGTlMeta?.path ?? "/refill/result",
    meta: ResultPageBASZ0VjGTlMeta || {},
    alias: ResultPageBASZ0VjGTlMeta?.alias || [],
    redirect: ResultPageBASZ0VjGTlMeta?.redirect,
    component: () => import("/app/layers/dota/routes/payment/ResultPage/ResultPage.vue").then(m => m.default || m)
  },
  {
    name: ResultPageBASZ0VjGTlMeta?.name ?? "payment-result___kk",
    path: ResultPageBASZ0VjGTlMeta?.path ?? "/kk/refill/result",
    meta: ResultPageBASZ0VjGTlMeta || {},
    alias: ResultPageBASZ0VjGTlMeta?.alias || [],
    redirect: ResultPageBASZ0VjGTlMeta?.redirect,
    component: () => import("/app/layers/dota/routes/payment/ResultPage/ResultPage.vue").then(m => m.default || m)
  },
  {
    name: ResultPageBASZ0VjGTlMeta?.name ?? "payment-result___az",
    path: ResultPageBASZ0VjGTlMeta?.path ?? "/az/refill/result",
    meta: ResultPageBASZ0VjGTlMeta || {},
    alias: ResultPageBASZ0VjGTlMeta?.alias || [],
    redirect: ResultPageBASZ0VjGTlMeta?.redirect,
    component: () => import("/app/layers/dota/routes/payment/ResultPage/ResultPage.vue").then(m => m.default || m)
  },
  {
    name: ResultPageBASZ0VjGTlMeta?.name ?? "payment-result___uz",
    path: ResultPageBASZ0VjGTlMeta?.path ?? "/uz/refill/result",
    meta: ResultPageBASZ0VjGTlMeta || {},
    alias: ResultPageBASZ0VjGTlMeta?.alias || [],
    redirect: ResultPageBASZ0VjGTlMeta?.redirect,
    component: () => import("/app/layers/dota/routes/payment/ResultPage/ResultPage.vue").then(m => m.default || m)
  },
  {
    name: ResultPageBASZ0VjGTlMeta?.name ?? "payment-result___es",
    path: ResultPageBASZ0VjGTlMeta?.path ?? "/es/refill/result",
    meta: ResultPageBASZ0VjGTlMeta || {},
    alias: ResultPageBASZ0VjGTlMeta?.alias || [],
    redirect: ResultPageBASZ0VjGTlMeta?.redirect,
    component: () => import("/app/layers/dota/routes/payment/ResultPage/ResultPage.vue").then(m => m.default || m)
  },
  {
    name: ResultPageBASZ0VjGTlMeta?.name ?? "payment-result___hi",
    path: ResultPageBASZ0VjGTlMeta?.path ?? "/hi/refill/result",
    meta: ResultPageBASZ0VjGTlMeta || {},
    alias: ResultPageBASZ0VjGTlMeta?.alias || [],
    redirect: ResultPageBASZ0VjGTlMeta?.redirect,
    component: () => import("/app/layers/dota/routes/payment/ResultPage/ResultPage.vue").then(m => m.default || m)
  },
  {
    name: ResultPageBASZ0VjGTlMeta?.name ?? "payment-result___ph",
    path: ResultPageBASZ0VjGTlMeta?.path ?? "/ph/refill/result",
    meta: ResultPageBASZ0VjGTlMeta || {},
    alias: ResultPageBASZ0VjGTlMeta?.alias || [],
    redirect: ResultPageBASZ0VjGTlMeta?.redirect,
    component: () => import("/app/layers/dota/routes/payment/ResultPage/ResultPage.vue").then(m => m.default || m)
  },
  {
    name: ResultPageBASZ0VjGTlMeta?.name ?? "payment-result___id",
    path: ResultPageBASZ0VjGTlMeta?.path ?? "/id/refill/result",
    meta: ResultPageBASZ0VjGTlMeta || {},
    alias: ResultPageBASZ0VjGTlMeta?.alias || [],
    redirect: ResultPageBASZ0VjGTlMeta?.redirect,
    component: () => import("/app/layers/dota/routes/payment/ResultPage/ResultPage.vue").then(m => m.default || m)
  },
  {
    name: UnauthPageaeuzXJ3vs6Meta?.name ?? "payment-unauth___en",
    path: UnauthPageaeuzXJ3vs6Meta?.path ?? "/en/refill/unauth",
    meta: UnauthPageaeuzXJ3vs6Meta || {},
    alias: UnauthPageaeuzXJ3vs6Meta?.alias || [],
    redirect: UnauthPageaeuzXJ3vs6Meta?.redirect,
    component: () => import("/app/layers/dota/routes/payment/UnauthPage/UnauthPage.vue").then(m => m.default || m)
  },
  {
    name: UnauthPageaeuzXJ3vs6Meta?.name ?? "payment-unauth___ru",
    path: UnauthPageaeuzXJ3vs6Meta?.path ?? "/refill/unauth",
    meta: UnauthPageaeuzXJ3vs6Meta || {},
    alias: UnauthPageaeuzXJ3vs6Meta?.alias || [],
    redirect: UnauthPageaeuzXJ3vs6Meta?.redirect,
    component: () => import("/app/layers/dota/routes/payment/UnauthPage/UnauthPage.vue").then(m => m.default || m)
  },
  {
    name: UnauthPageaeuzXJ3vs6Meta?.name ?? "payment-unauth___kk",
    path: UnauthPageaeuzXJ3vs6Meta?.path ?? "/kk/refill/unauth",
    meta: UnauthPageaeuzXJ3vs6Meta || {},
    alias: UnauthPageaeuzXJ3vs6Meta?.alias || [],
    redirect: UnauthPageaeuzXJ3vs6Meta?.redirect,
    component: () => import("/app/layers/dota/routes/payment/UnauthPage/UnauthPage.vue").then(m => m.default || m)
  },
  {
    name: UnauthPageaeuzXJ3vs6Meta?.name ?? "payment-unauth___az",
    path: UnauthPageaeuzXJ3vs6Meta?.path ?? "/az/refill/unauth",
    meta: UnauthPageaeuzXJ3vs6Meta || {},
    alias: UnauthPageaeuzXJ3vs6Meta?.alias || [],
    redirect: UnauthPageaeuzXJ3vs6Meta?.redirect,
    component: () => import("/app/layers/dota/routes/payment/UnauthPage/UnauthPage.vue").then(m => m.default || m)
  },
  {
    name: UnauthPageaeuzXJ3vs6Meta?.name ?? "payment-unauth___uz",
    path: UnauthPageaeuzXJ3vs6Meta?.path ?? "/uz/refill/unauth",
    meta: UnauthPageaeuzXJ3vs6Meta || {},
    alias: UnauthPageaeuzXJ3vs6Meta?.alias || [],
    redirect: UnauthPageaeuzXJ3vs6Meta?.redirect,
    component: () => import("/app/layers/dota/routes/payment/UnauthPage/UnauthPage.vue").then(m => m.default || m)
  },
  {
    name: UnauthPageaeuzXJ3vs6Meta?.name ?? "payment-unauth___es",
    path: UnauthPageaeuzXJ3vs6Meta?.path ?? "/es/refill/unauth",
    meta: UnauthPageaeuzXJ3vs6Meta || {},
    alias: UnauthPageaeuzXJ3vs6Meta?.alias || [],
    redirect: UnauthPageaeuzXJ3vs6Meta?.redirect,
    component: () => import("/app/layers/dota/routes/payment/UnauthPage/UnauthPage.vue").then(m => m.default || m)
  },
  {
    name: UnauthPageaeuzXJ3vs6Meta?.name ?? "payment-unauth___hi",
    path: UnauthPageaeuzXJ3vs6Meta?.path ?? "/hi/refill/unauth",
    meta: UnauthPageaeuzXJ3vs6Meta || {},
    alias: UnauthPageaeuzXJ3vs6Meta?.alias || [],
    redirect: UnauthPageaeuzXJ3vs6Meta?.redirect,
    component: () => import("/app/layers/dota/routes/payment/UnauthPage/UnauthPage.vue").then(m => m.default || m)
  },
  {
    name: UnauthPageaeuzXJ3vs6Meta?.name ?? "payment-unauth___ph",
    path: UnauthPageaeuzXJ3vs6Meta?.path ?? "/ph/refill/unauth",
    meta: UnauthPageaeuzXJ3vs6Meta || {},
    alias: UnauthPageaeuzXJ3vs6Meta?.alias || [],
    redirect: UnauthPageaeuzXJ3vs6Meta?.redirect,
    component: () => import("/app/layers/dota/routes/payment/UnauthPage/UnauthPage.vue").then(m => m.default || m)
  },
  {
    name: UnauthPageaeuzXJ3vs6Meta?.name ?? "payment-unauth___id",
    path: UnauthPageaeuzXJ3vs6Meta?.path ?? "/id/refill/unauth",
    meta: UnauthPageaeuzXJ3vs6Meta || {},
    alias: UnauthPageaeuzXJ3vs6Meta?.alias || [],
    redirect: UnauthPageaeuzXJ3vs6Meta?.redirect,
    component: () => import("/app/layers/dota/routes/payment/UnauthPage/UnauthPage.vue").then(m => m.default || m)
  },
  {
    name: CreateBattlePageEUAqGqDp4bMeta?.name ?? "create-battle-page___en",
    path: CreateBattlePageEUAqGqDp4bMeta?.path ?? "/en/battles/create",
    meta: { ...(CreateBattlePageEUAqGqDp4bMeta || {}), ...{"footer":false} },
    alias: CreateBattlePageEUAqGqDp4bMeta?.alias || [],
    redirect: CreateBattlePageEUAqGqDp4bMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/CreateBattlePage/CreateBattlePage.vue").then(m => m.default || m)
  },
  {
    name: CreateBattlePageEUAqGqDp4bMeta?.name ?? "create-battle-page___ru",
    path: CreateBattlePageEUAqGqDp4bMeta?.path ?? "/battles/create",
    meta: { ...(CreateBattlePageEUAqGqDp4bMeta || {}), ...{"footer":false} },
    alias: CreateBattlePageEUAqGqDp4bMeta?.alias || [],
    redirect: CreateBattlePageEUAqGqDp4bMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/CreateBattlePage/CreateBattlePage.vue").then(m => m.default || m)
  },
  {
    name: CreateBattlePageEUAqGqDp4bMeta?.name ?? "create-battle-page___kk",
    path: CreateBattlePageEUAqGqDp4bMeta?.path ?? "/kk/battles/create",
    meta: { ...(CreateBattlePageEUAqGqDp4bMeta || {}), ...{"footer":false} },
    alias: CreateBattlePageEUAqGqDp4bMeta?.alias || [],
    redirect: CreateBattlePageEUAqGqDp4bMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/CreateBattlePage/CreateBattlePage.vue").then(m => m.default || m)
  },
  {
    name: CreateBattlePageEUAqGqDp4bMeta?.name ?? "create-battle-page___az",
    path: CreateBattlePageEUAqGqDp4bMeta?.path ?? "/az/battles/create",
    meta: { ...(CreateBattlePageEUAqGqDp4bMeta || {}), ...{"footer":false} },
    alias: CreateBattlePageEUAqGqDp4bMeta?.alias || [],
    redirect: CreateBattlePageEUAqGqDp4bMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/CreateBattlePage/CreateBattlePage.vue").then(m => m.default || m)
  },
  {
    name: CreateBattlePageEUAqGqDp4bMeta?.name ?? "create-battle-page___uz",
    path: CreateBattlePageEUAqGqDp4bMeta?.path ?? "/uz/battles/create",
    meta: { ...(CreateBattlePageEUAqGqDp4bMeta || {}), ...{"footer":false} },
    alias: CreateBattlePageEUAqGqDp4bMeta?.alias || [],
    redirect: CreateBattlePageEUAqGqDp4bMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/CreateBattlePage/CreateBattlePage.vue").then(m => m.default || m)
  },
  {
    name: CreateBattlePageEUAqGqDp4bMeta?.name ?? "create-battle-page___es",
    path: CreateBattlePageEUAqGqDp4bMeta?.path ?? "/es/battles/create",
    meta: { ...(CreateBattlePageEUAqGqDp4bMeta || {}), ...{"footer":false} },
    alias: CreateBattlePageEUAqGqDp4bMeta?.alias || [],
    redirect: CreateBattlePageEUAqGqDp4bMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/CreateBattlePage/CreateBattlePage.vue").then(m => m.default || m)
  },
  {
    name: CreateBattlePageEUAqGqDp4bMeta?.name ?? "create-battle-page___hi",
    path: CreateBattlePageEUAqGqDp4bMeta?.path ?? "/hi/battles/create",
    meta: { ...(CreateBattlePageEUAqGqDp4bMeta || {}), ...{"footer":false} },
    alias: CreateBattlePageEUAqGqDp4bMeta?.alias || [],
    redirect: CreateBattlePageEUAqGqDp4bMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/CreateBattlePage/CreateBattlePage.vue").then(m => m.default || m)
  },
  {
    name: CreateBattlePageEUAqGqDp4bMeta?.name ?? "create-battle-page___ph",
    path: CreateBattlePageEUAqGqDp4bMeta?.path ?? "/ph/battles/create",
    meta: { ...(CreateBattlePageEUAqGqDp4bMeta || {}), ...{"footer":false} },
    alias: CreateBattlePageEUAqGqDp4bMeta?.alias || [],
    redirect: CreateBattlePageEUAqGqDp4bMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/CreateBattlePage/CreateBattlePage.vue").then(m => m.default || m)
  },
  {
    name: CreateBattlePageEUAqGqDp4bMeta?.name ?? "create-battle-page___id",
    path: CreateBattlePageEUAqGqDp4bMeta?.path ?? "/id/battles/create",
    meta: { ...(CreateBattlePageEUAqGqDp4bMeta || {}), ...{"footer":false} },
    alias: CreateBattlePageEUAqGqDp4bMeta?.alias || [],
    redirect: CreateBattlePageEUAqGqDp4bMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/CreateBattlePage/CreateBattlePage.vue").then(m => m.default || m)
  },
  {
    name: MainPageEKwWOytIzIMeta?.name ?? "main-battles-page___en",
    path: MainPageEKwWOytIzIMeta?.path ?? "/en/battles",
    meta: MainPageEKwWOytIzIMeta || {},
    alias: MainPageEKwWOytIzIMeta?.alias || [],
    redirect: MainPageEKwWOytIzIMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageEKwWOytIzIMeta?.name ?? "main-battles-page___ru",
    path: MainPageEKwWOytIzIMeta?.path ?? "/battles",
    meta: MainPageEKwWOytIzIMeta || {},
    alias: MainPageEKwWOytIzIMeta?.alias || [],
    redirect: MainPageEKwWOytIzIMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageEKwWOytIzIMeta?.name ?? "main-battles-page___kk",
    path: MainPageEKwWOytIzIMeta?.path ?? "/kk/battles",
    meta: MainPageEKwWOytIzIMeta || {},
    alias: MainPageEKwWOytIzIMeta?.alias || [],
    redirect: MainPageEKwWOytIzIMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageEKwWOytIzIMeta?.name ?? "main-battles-page___az",
    path: MainPageEKwWOytIzIMeta?.path ?? "/az/battles",
    meta: MainPageEKwWOytIzIMeta || {},
    alias: MainPageEKwWOytIzIMeta?.alias || [],
    redirect: MainPageEKwWOytIzIMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageEKwWOytIzIMeta?.name ?? "main-battles-page___uz",
    path: MainPageEKwWOytIzIMeta?.path ?? "/uz/battles",
    meta: MainPageEKwWOytIzIMeta || {},
    alias: MainPageEKwWOytIzIMeta?.alias || [],
    redirect: MainPageEKwWOytIzIMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageEKwWOytIzIMeta?.name ?? "main-battles-page___es",
    path: MainPageEKwWOytIzIMeta?.path ?? "/es/battles",
    meta: MainPageEKwWOytIzIMeta || {},
    alias: MainPageEKwWOytIzIMeta?.alias || [],
    redirect: MainPageEKwWOytIzIMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageEKwWOytIzIMeta?.name ?? "main-battles-page___hi",
    path: MainPageEKwWOytIzIMeta?.path ?? "/hi/battles",
    meta: MainPageEKwWOytIzIMeta || {},
    alias: MainPageEKwWOytIzIMeta?.alias || [],
    redirect: MainPageEKwWOytIzIMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageEKwWOytIzIMeta?.name ?? "main-battles-page___ph",
    path: MainPageEKwWOytIzIMeta?.path ?? "/ph/battles",
    meta: MainPageEKwWOytIzIMeta || {},
    alias: MainPageEKwWOytIzIMeta?.alias || [],
    redirect: MainPageEKwWOytIzIMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageEKwWOytIzIMeta?.name ?? "main-battles-page___id",
    path: MainPageEKwWOytIzIMeta?.path ?? "/id/battles",
    meta: MainPageEKwWOytIzIMeta || {},
    alias: MainPageEKwWOytIzIMeta?.alias || [],
    redirect: MainPageEKwWOytIzIMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: HistoryPageyPwmaAKXe2Meta?.name ?? "history-battles-page___en",
    path: HistoryPageyPwmaAKXe2Meta?.path ?? "/en/battles/history",
    meta: HistoryPageyPwmaAKXe2Meta || {},
    alias: HistoryPageyPwmaAKXe2Meta?.alias || [],
    redirect: HistoryPageyPwmaAKXe2Meta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/HistoryPage/HistoryPage.vue").then(m => m.default || m)
  },
  {
    name: HistoryPageyPwmaAKXe2Meta?.name ?? "history-battles-page___ru",
    path: HistoryPageyPwmaAKXe2Meta?.path ?? "/battles/history",
    meta: HistoryPageyPwmaAKXe2Meta || {},
    alias: HistoryPageyPwmaAKXe2Meta?.alias || [],
    redirect: HistoryPageyPwmaAKXe2Meta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/HistoryPage/HistoryPage.vue").then(m => m.default || m)
  },
  {
    name: HistoryPageyPwmaAKXe2Meta?.name ?? "history-battles-page___kk",
    path: HistoryPageyPwmaAKXe2Meta?.path ?? "/kk/battles/history",
    meta: HistoryPageyPwmaAKXe2Meta || {},
    alias: HistoryPageyPwmaAKXe2Meta?.alias || [],
    redirect: HistoryPageyPwmaAKXe2Meta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/HistoryPage/HistoryPage.vue").then(m => m.default || m)
  },
  {
    name: HistoryPageyPwmaAKXe2Meta?.name ?? "history-battles-page___az",
    path: HistoryPageyPwmaAKXe2Meta?.path ?? "/az/battles/history",
    meta: HistoryPageyPwmaAKXe2Meta || {},
    alias: HistoryPageyPwmaAKXe2Meta?.alias || [],
    redirect: HistoryPageyPwmaAKXe2Meta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/HistoryPage/HistoryPage.vue").then(m => m.default || m)
  },
  {
    name: HistoryPageyPwmaAKXe2Meta?.name ?? "history-battles-page___uz",
    path: HistoryPageyPwmaAKXe2Meta?.path ?? "/uz/battles/history",
    meta: HistoryPageyPwmaAKXe2Meta || {},
    alias: HistoryPageyPwmaAKXe2Meta?.alias || [],
    redirect: HistoryPageyPwmaAKXe2Meta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/HistoryPage/HistoryPage.vue").then(m => m.default || m)
  },
  {
    name: HistoryPageyPwmaAKXe2Meta?.name ?? "history-battles-page___es",
    path: HistoryPageyPwmaAKXe2Meta?.path ?? "/es/battles/history",
    meta: HistoryPageyPwmaAKXe2Meta || {},
    alias: HistoryPageyPwmaAKXe2Meta?.alias || [],
    redirect: HistoryPageyPwmaAKXe2Meta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/HistoryPage/HistoryPage.vue").then(m => m.default || m)
  },
  {
    name: HistoryPageyPwmaAKXe2Meta?.name ?? "history-battles-page___hi",
    path: HistoryPageyPwmaAKXe2Meta?.path ?? "/hi/battles/history",
    meta: HistoryPageyPwmaAKXe2Meta || {},
    alias: HistoryPageyPwmaAKXe2Meta?.alias || [],
    redirect: HistoryPageyPwmaAKXe2Meta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/HistoryPage/HistoryPage.vue").then(m => m.default || m)
  },
  {
    name: HistoryPageyPwmaAKXe2Meta?.name ?? "history-battles-page___ph",
    path: HistoryPageyPwmaAKXe2Meta?.path ?? "/ph/battles/history",
    meta: HistoryPageyPwmaAKXe2Meta || {},
    alias: HistoryPageyPwmaAKXe2Meta?.alias || [],
    redirect: HistoryPageyPwmaAKXe2Meta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/HistoryPage/HistoryPage.vue").then(m => m.default || m)
  },
  {
    name: HistoryPageyPwmaAKXe2Meta?.name ?? "history-battles-page___id",
    path: HistoryPageyPwmaAKXe2Meta?.path ?? "/id/battles/history",
    meta: HistoryPageyPwmaAKXe2Meta || {},
    alias: HistoryPageyPwmaAKXe2Meta?.alias || [],
    redirect: HistoryPageyPwmaAKXe2Meta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/HistoryPage/HistoryPage.vue").then(m => m.default || m)
  },
  {
    name: PersonalStatisticsPagerDmFyaYgEjMeta?.name ?? "statistics-battles-page___en",
    path: PersonalStatisticsPagerDmFyaYgEjMeta?.path ?? "/en/battles/statistics",
    meta: PersonalStatisticsPagerDmFyaYgEjMeta || {},
    alias: PersonalStatisticsPagerDmFyaYgEjMeta?.alias || [],
    redirect: PersonalStatisticsPagerDmFyaYgEjMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/PersonalStatisticsPage/PersonalStatisticsPage.vue").then(m => m.default || m)
  },
  {
    name: PersonalStatisticsPagerDmFyaYgEjMeta?.name ?? "statistics-battles-page___ru",
    path: PersonalStatisticsPagerDmFyaYgEjMeta?.path ?? "/battles/statistics",
    meta: PersonalStatisticsPagerDmFyaYgEjMeta || {},
    alias: PersonalStatisticsPagerDmFyaYgEjMeta?.alias || [],
    redirect: PersonalStatisticsPagerDmFyaYgEjMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/PersonalStatisticsPage/PersonalStatisticsPage.vue").then(m => m.default || m)
  },
  {
    name: PersonalStatisticsPagerDmFyaYgEjMeta?.name ?? "statistics-battles-page___kk",
    path: PersonalStatisticsPagerDmFyaYgEjMeta?.path ?? "/kk/battles/statistics",
    meta: PersonalStatisticsPagerDmFyaYgEjMeta || {},
    alias: PersonalStatisticsPagerDmFyaYgEjMeta?.alias || [],
    redirect: PersonalStatisticsPagerDmFyaYgEjMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/PersonalStatisticsPage/PersonalStatisticsPage.vue").then(m => m.default || m)
  },
  {
    name: PersonalStatisticsPagerDmFyaYgEjMeta?.name ?? "statistics-battles-page___az",
    path: PersonalStatisticsPagerDmFyaYgEjMeta?.path ?? "/az/battles/statistics",
    meta: PersonalStatisticsPagerDmFyaYgEjMeta || {},
    alias: PersonalStatisticsPagerDmFyaYgEjMeta?.alias || [],
    redirect: PersonalStatisticsPagerDmFyaYgEjMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/PersonalStatisticsPage/PersonalStatisticsPage.vue").then(m => m.default || m)
  },
  {
    name: PersonalStatisticsPagerDmFyaYgEjMeta?.name ?? "statistics-battles-page___uz",
    path: PersonalStatisticsPagerDmFyaYgEjMeta?.path ?? "/uz/battles/statistics",
    meta: PersonalStatisticsPagerDmFyaYgEjMeta || {},
    alias: PersonalStatisticsPagerDmFyaYgEjMeta?.alias || [],
    redirect: PersonalStatisticsPagerDmFyaYgEjMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/PersonalStatisticsPage/PersonalStatisticsPage.vue").then(m => m.default || m)
  },
  {
    name: PersonalStatisticsPagerDmFyaYgEjMeta?.name ?? "statistics-battles-page___es",
    path: PersonalStatisticsPagerDmFyaYgEjMeta?.path ?? "/es/battles/statistics",
    meta: PersonalStatisticsPagerDmFyaYgEjMeta || {},
    alias: PersonalStatisticsPagerDmFyaYgEjMeta?.alias || [],
    redirect: PersonalStatisticsPagerDmFyaYgEjMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/PersonalStatisticsPage/PersonalStatisticsPage.vue").then(m => m.default || m)
  },
  {
    name: PersonalStatisticsPagerDmFyaYgEjMeta?.name ?? "statistics-battles-page___hi",
    path: PersonalStatisticsPagerDmFyaYgEjMeta?.path ?? "/hi/battles/statistics",
    meta: PersonalStatisticsPagerDmFyaYgEjMeta || {},
    alias: PersonalStatisticsPagerDmFyaYgEjMeta?.alias || [],
    redirect: PersonalStatisticsPagerDmFyaYgEjMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/PersonalStatisticsPage/PersonalStatisticsPage.vue").then(m => m.default || m)
  },
  {
    name: PersonalStatisticsPagerDmFyaYgEjMeta?.name ?? "statistics-battles-page___ph",
    path: PersonalStatisticsPagerDmFyaYgEjMeta?.path ?? "/ph/battles/statistics",
    meta: PersonalStatisticsPagerDmFyaYgEjMeta || {},
    alias: PersonalStatisticsPagerDmFyaYgEjMeta?.alias || [],
    redirect: PersonalStatisticsPagerDmFyaYgEjMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/PersonalStatisticsPage/PersonalStatisticsPage.vue").then(m => m.default || m)
  },
  {
    name: PersonalStatisticsPagerDmFyaYgEjMeta?.name ?? "statistics-battles-page___id",
    path: PersonalStatisticsPagerDmFyaYgEjMeta?.path ?? "/id/battles/statistics",
    meta: PersonalStatisticsPagerDmFyaYgEjMeta || {},
    alias: PersonalStatisticsPagerDmFyaYgEjMeta?.alias || [],
    redirect: PersonalStatisticsPagerDmFyaYgEjMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/PersonalStatisticsPage/PersonalStatisticsPage.vue").then(m => m.default || m)
  },
  {
    name: RunningBattlePage28oK4TAgCqMeta?.name ?? "single-battle-page___en",
    path: RunningBattlePage28oK4TAgCqMeta?.path ?? "/en/battles/:battleId",
    meta: { ...(RunningBattlePage28oK4TAgCqMeta || {}), ...{"footer":false} },
    alias: RunningBattlePage28oK4TAgCqMeta?.alias || [],
    redirect: RunningBattlePage28oK4TAgCqMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/RunningBattlePage/RunningBattlePage.vue").then(m => m.default || m)
  },
  {
    name: RunningBattlePage28oK4TAgCqMeta?.name ?? "single-battle-page___ru",
    path: RunningBattlePage28oK4TAgCqMeta?.path ?? "/battles/:battleId",
    meta: { ...(RunningBattlePage28oK4TAgCqMeta || {}), ...{"footer":false} },
    alias: RunningBattlePage28oK4TAgCqMeta?.alias || [],
    redirect: RunningBattlePage28oK4TAgCqMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/RunningBattlePage/RunningBattlePage.vue").then(m => m.default || m)
  },
  {
    name: RunningBattlePage28oK4TAgCqMeta?.name ?? "single-battle-page___kk",
    path: RunningBattlePage28oK4TAgCqMeta?.path ?? "/kk/battles/:battleId",
    meta: { ...(RunningBattlePage28oK4TAgCqMeta || {}), ...{"footer":false} },
    alias: RunningBattlePage28oK4TAgCqMeta?.alias || [],
    redirect: RunningBattlePage28oK4TAgCqMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/RunningBattlePage/RunningBattlePage.vue").then(m => m.default || m)
  },
  {
    name: RunningBattlePage28oK4TAgCqMeta?.name ?? "single-battle-page___az",
    path: RunningBattlePage28oK4TAgCqMeta?.path ?? "/az/battles/:battleId",
    meta: { ...(RunningBattlePage28oK4TAgCqMeta || {}), ...{"footer":false} },
    alias: RunningBattlePage28oK4TAgCqMeta?.alias || [],
    redirect: RunningBattlePage28oK4TAgCqMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/RunningBattlePage/RunningBattlePage.vue").then(m => m.default || m)
  },
  {
    name: RunningBattlePage28oK4TAgCqMeta?.name ?? "single-battle-page___uz",
    path: RunningBattlePage28oK4TAgCqMeta?.path ?? "/uz/battles/:battleId",
    meta: { ...(RunningBattlePage28oK4TAgCqMeta || {}), ...{"footer":false} },
    alias: RunningBattlePage28oK4TAgCqMeta?.alias || [],
    redirect: RunningBattlePage28oK4TAgCqMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/RunningBattlePage/RunningBattlePage.vue").then(m => m.default || m)
  },
  {
    name: RunningBattlePage28oK4TAgCqMeta?.name ?? "single-battle-page___es",
    path: RunningBattlePage28oK4TAgCqMeta?.path ?? "/es/battles/:battleId",
    meta: { ...(RunningBattlePage28oK4TAgCqMeta || {}), ...{"footer":false} },
    alias: RunningBattlePage28oK4TAgCqMeta?.alias || [],
    redirect: RunningBattlePage28oK4TAgCqMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/RunningBattlePage/RunningBattlePage.vue").then(m => m.default || m)
  },
  {
    name: RunningBattlePage28oK4TAgCqMeta?.name ?? "single-battle-page___hi",
    path: RunningBattlePage28oK4TAgCqMeta?.path ?? "/hi/battles/:battleId",
    meta: { ...(RunningBattlePage28oK4TAgCqMeta || {}), ...{"footer":false} },
    alias: RunningBattlePage28oK4TAgCqMeta?.alias || [],
    redirect: RunningBattlePage28oK4TAgCqMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/RunningBattlePage/RunningBattlePage.vue").then(m => m.default || m)
  },
  {
    name: RunningBattlePage28oK4TAgCqMeta?.name ?? "single-battle-page___ph",
    path: RunningBattlePage28oK4TAgCqMeta?.path ?? "/ph/battles/:battleId",
    meta: { ...(RunningBattlePage28oK4TAgCqMeta || {}), ...{"footer":false} },
    alias: RunningBattlePage28oK4TAgCqMeta?.alias || [],
    redirect: RunningBattlePage28oK4TAgCqMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/RunningBattlePage/RunningBattlePage.vue").then(m => m.default || m)
  },
  {
    name: RunningBattlePage28oK4TAgCqMeta?.name ?? "single-battle-page___id",
    path: RunningBattlePage28oK4TAgCqMeta?.path ?? "/id/battles/:battleId",
    meta: { ...(RunningBattlePage28oK4TAgCqMeta || {}), ...{"footer":false} },
    alias: RunningBattlePage28oK4TAgCqMeta?.alias || [],
    redirect: RunningBattlePage28oK4TAgCqMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/RunningBattlePage/RunningBattlePage.vue").then(m => m.default || m)
  },
  {
    name: NotWorkingBattlePagewnDPQQsZ9tMeta?.name ?? "not-working-battle-page___en",
    path: NotWorkingBattlePagewnDPQQsZ9tMeta?.path ?? "/en/battles/temp",
    meta: { ...(NotWorkingBattlePagewnDPQQsZ9tMeta || {}), ...{"footer":false} },
    alias: NotWorkingBattlePagewnDPQQsZ9tMeta?.alias || [],
    redirect: NotWorkingBattlePagewnDPQQsZ9tMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/NotWorkingBattlePage/NotWorkingBattlePage.vue").then(m => m.default || m)
  },
  {
    name: NotWorkingBattlePagewnDPQQsZ9tMeta?.name ?? "not-working-battle-page___ru",
    path: NotWorkingBattlePagewnDPQQsZ9tMeta?.path ?? "/battles/temp",
    meta: { ...(NotWorkingBattlePagewnDPQQsZ9tMeta || {}), ...{"footer":false} },
    alias: NotWorkingBattlePagewnDPQQsZ9tMeta?.alias || [],
    redirect: NotWorkingBattlePagewnDPQQsZ9tMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/NotWorkingBattlePage/NotWorkingBattlePage.vue").then(m => m.default || m)
  },
  {
    name: NotWorkingBattlePagewnDPQQsZ9tMeta?.name ?? "not-working-battle-page___kk",
    path: NotWorkingBattlePagewnDPQQsZ9tMeta?.path ?? "/kk/battles/temp",
    meta: { ...(NotWorkingBattlePagewnDPQQsZ9tMeta || {}), ...{"footer":false} },
    alias: NotWorkingBattlePagewnDPQQsZ9tMeta?.alias || [],
    redirect: NotWorkingBattlePagewnDPQQsZ9tMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/NotWorkingBattlePage/NotWorkingBattlePage.vue").then(m => m.default || m)
  },
  {
    name: NotWorkingBattlePagewnDPQQsZ9tMeta?.name ?? "not-working-battle-page___az",
    path: NotWorkingBattlePagewnDPQQsZ9tMeta?.path ?? "/az/battles/temp",
    meta: { ...(NotWorkingBattlePagewnDPQQsZ9tMeta || {}), ...{"footer":false} },
    alias: NotWorkingBattlePagewnDPQQsZ9tMeta?.alias || [],
    redirect: NotWorkingBattlePagewnDPQQsZ9tMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/NotWorkingBattlePage/NotWorkingBattlePage.vue").then(m => m.default || m)
  },
  {
    name: NotWorkingBattlePagewnDPQQsZ9tMeta?.name ?? "not-working-battle-page___uz",
    path: NotWorkingBattlePagewnDPQQsZ9tMeta?.path ?? "/uz/battles/temp",
    meta: { ...(NotWorkingBattlePagewnDPQQsZ9tMeta || {}), ...{"footer":false} },
    alias: NotWorkingBattlePagewnDPQQsZ9tMeta?.alias || [],
    redirect: NotWorkingBattlePagewnDPQQsZ9tMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/NotWorkingBattlePage/NotWorkingBattlePage.vue").then(m => m.default || m)
  },
  {
    name: NotWorkingBattlePagewnDPQQsZ9tMeta?.name ?? "not-working-battle-page___es",
    path: NotWorkingBattlePagewnDPQQsZ9tMeta?.path ?? "/es/battles/temp",
    meta: { ...(NotWorkingBattlePagewnDPQQsZ9tMeta || {}), ...{"footer":false} },
    alias: NotWorkingBattlePagewnDPQQsZ9tMeta?.alias || [],
    redirect: NotWorkingBattlePagewnDPQQsZ9tMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/NotWorkingBattlePage/NotWorkingBattlePage.vue").then(m => m.default || m)
  },
  {
    name: NotWorkingBattlePagewnDPQQsZ9tMeta?.name ?? "not-working-battle-page___hi",
    path: NotWorkingBattlePagewnDPQQsZ9tMeta?.path ?? "/hi/battles/temp",
    meta: { ...(NotWorkingBattlePagewnDPQQsZ9tMeta || {}), ...{"footer":false} },
    alias: NotWorkingBattlePagewnDPQQsZ9tMeta?.alias || [],
    redirect: NotWorkingBattlePagewnDPQQsZ9tMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/NotWorkingBattlePage/NotWorkingBattlePage.vue").then(m => m.default || m)
  },
  {
    name: NotWorkingBattlePagewnDPQQsZ9tMeta?.name ?? "not-working-battle-page___ph",
    path: NotWorkingBattlePagewnDPQQsZ9tMeta?.path ?? "/ph/battles/temp",
    meta: { ...(NotWorkingBattlePagewnDPQQsZ9tMeta || {}), ...{"footer":false} },
    alias: NotWorkingBattlePagewnDPQQsZ9tMeta?.alias || [],
    redirect: NotWorkingBattlePagewnDPQQsZ9tMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/NotWorkingBattlePage/NotWorkingBattlePage.vue").then(m => m.default || m)
  },
  {
    name: NotWorkingBattlePagewnDPQQsZ9tMeta?.name ?? "not-working-battle-page___id",
    path: NotWorkingBattlePagewnDPQQsZ9tMeta?.path ?? "/id/battles/temp",
    meta: { ...(NotWorkingBattlePagewnDPQQsZ9tMeta || {}), ...{"footer":false} },
    alias: NotWorkingBattlePagewnDPQQsZ9tMeta?.alias || [],
    redirect: NotWorkingBattlePagewnDPQQsZ9tMeta?.redirect,
    component: () => import("/app/layers/dota/routes/battles/NotWorkingBattlePage/NotWorkingBattlePage.vue").then(m => m.default || m)
  },
  {
    name: MainPageMejfE9SXNwMeta?.name ?? "main-page___en",
    path: MainPageMejfE9SXNwMeta?.path ?? "/en",
    meta: MainPageMejfE9SXNwMeta || {},
    alias: MainPageMejfE9SXNwMeta?.alias || [],
    redirect: MainPageMejfE9SXNwMeta?.redirect,
    component: () => import("/app/layers/dota/routes/mainPage/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageMejfE9SXNwMeta?.name ?? "main-page___ru",
    path: MainPageMejfE9SXNwMeta?.path ?? "/",
    meta: MainPageMejfE9SXNwMeta || {},
    alias: MainPageMejfE9SXNwMeta?.alias || [],
    redirect: MainPageMejfE9SXNwMeta?.redirect,
    component: () => import("/app/layers/dota/routes/mainPage/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageMejfE9SXNwMeta?.name ?? "main-page___kk",
    path: MainPageMejfE9SXNwMeta?.path ?? "/kk",
    meta: MainPageMejfE9SXNwMeta || {},
    alias: MainPageMejfE9SXNwMeta?.alias || [],
    redirect: MainPageMejfE9SXNwMeta?.redirect,
    component: () => import("/app/layers/dota/routes/mainPage/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageMejfE9SXNwMeta?.name ?? "main-page___az",
    path: MainPageMejfE9SXNwMeta?.path ?? "/az",
    meta: MainPageMejfE9SXNwMeta || {},
    alias: MainPageMejfE9SXNwMeta?.alias || [],
    redirect: MainPageMejfE9SXNwMeta?.redirect,
    component: () => import("/app/layers/dota/routes/mainPage/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageMejfE9SXNwMeta?.name ?? "main-page___uz",
    path: MainPageMejfE9SXNwMeta?.path ?? "/uz",
    meta: MainPageMejfE9SXNwMeta || {},
    alias: MainPageMejfE9SXNwMeta?.alias || [],
    redirect: MainPageMejfE9SXNwMeta?.redirect,
    component: () => import("/app/layers/dota/routes/mainPage/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageMejfE9SXNwMeta?.name ?? "main-page___es",
    path: MainPageMejfE9SXNwMeta?.path ?? "/es",
    meta: MainPageMejfE9SXNwMeta || {},
    alias: MainPageMejfE9SXNwMeta?.alias || [],
    redirect: MainPageMejfE9SXNwMeta?.redirect,
    component: () => import("/app/layers/dota/routes/mainPage/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageMejfE9SXNwMeta?.name ?? "main-page___hi",
    path: MainPageMejfE9SXNwMeta?.path ?? "/hi",
    meta: MainPageMejfE9SXNwMeta || {},
    alias: MainPageMejfE9SXNwMeta?.alias || [],
    redirect: MainPageMejfE9SXNwMeta?.redirect,
    component: () => import("/app/layers/dota/routes/mainPage/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageMejfE9SXNwMeta?.name ?? "main-page___ph",
    path: MainPageMejfE9SXNwMeta?.path ?? "/ph",
    meta: MainPageMejfE9SXNwMeta || {},
    alias: MainPageMejfE9SXNwMeta?.alias || [],
    redirect: MainPageMejfE9SXNwMeta?.redirect,
    component: () => import("/app/layers/dota/routes/mainPage/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageMejfE9SXNwMeta?.name ?? "main-page___id",
    path: MainPageMejfE9SXNwMeta?.path ?? "/id",
    meta: MainPageMejfE9SXNwMeta || {},
    alias: MainPageMejfE9SXNwMeta?.alias || [],
    redirect: MainPageMejfE9SXNwMeta?.redirect,
    component: () => import("/app/layers/dota/routes/mainPage/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: EventCasesPageBVQEDhJhIpMeta?.name ?? "new-cases___en",
    path: EventCasesPageBVQEDhJhIpMeta?.path ?? "/en/new-cases",
    meta: EventCasesPageBVQEDhJhIpMeta || {},
    alias: EventCasesPageBVQEDhJhIpMeta?.alias || [],
    redirect: EventCasesPageBVQEDhJhIpMeta?.redirect,
    component: () => import("/app/layers/dota/routes/mainPage/EventCasesPage/EventCasesPage.vue").then(m => m.default || m)
  },
  {
    name: EventCasesPageBVQEDhJhIpMeta?.name ?? "new-cases___ru",
    path: EventCasesPageBVQEDhJhIpMeta?.path ?? "/new-cases",
    meta: EventCasesPageBVQEDhJhIpMeta || {},
    alias: EventCasesPageBVQEDhJhIpMeta?.alias || [],
    redirect: EventCasesPageBVQEDhJhIpMeta?.redirect,
    component: () => import("/app/layers/dota/routes/mainPage/EventCasesPage/EventCasesPage.vue").then(m => m.default || m)
  },
  {
    name: EventCasesPageBVQEDhJhIpMeta?.name ?? "new-cases___kk",
    path: EventCasesPageBVQEDhJhIpMeta?.path ?? "/kk/new-cases",
    meta: EventCasesPageBVQEDhJhIpMeta || {},
    alias: EventCasesPageBVQEDhJhIpMeta?.alias || [],
    redirect: EventCasesPageBVQEDhJhIpMeta?.redirect,
    component: () => import("/app/layers/dota/routes/mainPage/EventCasesPage/EventCasesPage.vue").then(m => m.default || m)
  },
  {
    name: EventCasesPageBVQEDhJhIpMeta?.name ?? "new-cases___az",
    path: EventCasesPageBVQEDhJhIpMeta?.path ?? "/az/new-cases",
    meta: EventCasesPageBVQEDhJhIpMeta || {},
    alias: EventCasesPageBVQEDhJhIpMeta?.alias || [],
    redirect: EventCasesPageBVQEDhJhIpMeta?.redirect,
    component: () => import("/app/layers/dota/routes/mainPage/EventCasesPage/EventCasesPage.vue").then(m => m.default || m)
  },
  {
    name: EventCasesPageBVQEDhJhIpMeta?.name ?? "new-cases___uz",
    path: EventCasesPageBVQEDhJhIpMeta?.path ?? "/uz/new-cases",
    meta: EventCasesPageBVQEDhJhIpMeta || {},
    alias: EventCasesPageBVQEDhJhIpMeta?.alias || [],
    redirect: EventCasesPageBVQEDhJhIpMeta?.redirect,
    component: () => import("/app/layers/dota/routes/mainPage/EventCasesPage/EventCasesPage.vue").then(m => m.default || m)
  },
  {
    name: EventCasesPageBVQEDhJhIpMeta?.name ?? "new-cases___es",
    path: EventCasesPageBVQEDhJhIpMeta?.path ?? "/es/new-cases",
    meta: EventCasesPageBVQEDhJhIpMeta || {},
    alias: EventCasesPageBVQEDhJhIpMeta?.alias || [],
    redirect: EventCasesPageBVQEDhJhIpMeta?.redirect,
    component: () => import("/app/layers/dota/routes/mainPage/EventCasesPage/EventCasesPage.vue").then(m => m.default || m)
  },
  {
    name: EventCasesPageBVQEDhJhIpMeta?.name ?? "new-cases___hi",
    path: EventCasesPageBVQEDhJhIpMeta?.path ?? "/hi/new-cases",
    meta: EventCasesPageBVQEDhJhIpMeta || {},
    alias: EventCasesPageBVQEDhJhIpMeta?.alias || [],
    redirect: EventCasesPageBVQEDhJhIpMeta?.redirect,
    component: () => import("/app/layers/dota/routes/mainPage/EventCasesPage/EventCasesPage.vue").then(m => m.default || m)
  },
  {
    name: EventCasesPageBVQEDhJhIpMeta?.name ?? "new-cases___ph",
    path: EventCasesPageBVQEDhJhIpMeta?.path ?? "/ph/new-cases",
    meta: EventCasesPageBVQEDhJhIpMeta || {},
    alias: EventCasesPageBVQEDhJhIpMeta?.alias || [],
    redirect: EventCasesPageBVQEDhJhIpMeta?.redirect,
    component: () => import("/app/layers/dota/routes/mainPage/EventCasesPage/EventCasesPage.vue").then(m => m.default || m)
  },
  {
    name: EventCasesPageBVQEDhJhIpMeta?.name ?? "new-cases___id",
    path: EventCasesPageBVQEDhJhIpMeta?.path ?? "/id/new-cases",
    meta: EventCasesPageBVQEDhJhIpMeta || {},
    alias: EventCasesPageBVQEDhJhIpMeta?.alias || [],
    redirect: EventCasesPageBVQEDhJhIpMeta?.redirect,
    component: () => import("/app/layers/dota/routes/mainPage/EventCasesPage/EventCasesPage.vue").then(m => m.default || m)
  },
  {
    name: TopLaddersPagecARmRO4To7Meta?.name ?? "top___en",
    path: TopLaddersPagecARmRO4To7Meta?.path ?? "/en/top",
    meta: TopLaddersPagecARmRO4To7Meta || {},
    alias: TopLaddersPagecARmRO4To7Meta?.alias || [],
    redirect: TopLaddersPagecARmRO4To7Meta?.redirect,
    component: () => import("/app/layers/dota/routes/mainPage/TopLaddersPage/TopLaddersPage.vue").then(m => m.default || m)
  },
  {
    name: TopLaddersPagecARmRO4To7Meta?.name ?? "top___ru",
    path: TopLaddersPagecARmRO4To7Meta?.path ?? "/top",
    meta: TopLaddersPagecARmRO4To7Meta || {},
    alias: TopLaddersPagecARmRO4To7Meta?.alias || [],
    redirect: TopLaddersPagecARmRO4To7Meta?.redirect,
    component: () => import("/app/layers/dota/routes/mainPage/TopLaddersPage/TopLaddersPage.vue").then(m => m.default || m)
  },
  {
    name: TopLaddersPagecARmRO4To7Meta?.name ?? "top___kk",
    path: TopLaddersPagecARmRO4To7Meta?.path ?? "/kk/top",
    meta: TopLaddersPagecARmRO4To7Meta || {},
    alias: TopLaddersPagecARmRO4To7Meta?.alias || [],
    redirect: TopLaddersPagecARmRO4To7Meta?.redirect,
    component: () => import("/app/layers/dota/routes/mainPage/TopLaddersPage/TopLaddersPage.vue").then(m => m.default || m)
  },
  {
    name: TopLaddersPagecARmRO4To7Meta?.name ?? "top___az",
    path: TopLaddersPagecARmRO4To7Meta?.path ?? "/az/top",
    meta: TopLaddersPagecARmRO4To7Meta || {},
    alias: TopLaddersPagecARmRO4To7Meta?.alias || [],
    redirect: TopLaddersPagecARmRO4To7Meta?.redirect,
    component: () => import("/app/layers/dota/routes/mainPage/TopLaddersPage/TopLaddersPage.vue").then(m => m.default || m)
  },
  {
    name: TopLaddersPagecARmRO4To7Meta?.name ?? "top___uz",
    path: TopLaddersPagecARmRO4To7Meta?.path ?? "/uz/top",
    meta: TopLaddersPagecARmRO4To7Meta || {},
    alias: TopLaddersPagecARmRO4To7Meta?.alias || [],
    redirect: TopLaddersPagecARmRO4To7Meta?.redirect,
    component: () => import("/app/layers/dota/routes/mainPage/TopLaddersPage/TopLaddersPage.vue").then(m => m.default || m)
  },
  {
    name: TopLaddersPagecARmRO4To7Meta?.name ?? "top___es",
    path: TopLaddersPagecARmRO4To7Meta?.path ?? "/es/top",
    meta: TopLaddersPagecARmRO4To7Meta || {},
    alias: TopLaddersPagecARmRO4To7Meta?.alias || [],
    redirect: TopLaddersPagecARmRO4To7Meta?.redirect,
    component: () => import("/app/layers/dota/routes/mainPage/TopLaddersPage/TopLaddersPage.vue").then(m => m.default || m)
  },
  {
    name: TopLaddersPagecARmRO4To7Meta?.name ?? "top___hi",
    path: TopLaddersPagecARmRO4To7Meta?.path ?? "/hi/top",
    meta: TopLaddersPagecARmRO4To7Meta || {},
    alias: TopLaddersPagecARmRO4To7Meta?.alias || [],
    redirect: TopLaddersPagecARmRO4To7Meta?.redirect,
    component: () => import("/app/layers/dota/routes/mainPage/TopLaddersPage/TopLaddersPage.vue").then(m => m.default || m)
  },
  {
    name: TopLaddersPagecARmRO4To7Meta?.name ?? "top___ph",
    path: TopLaddersPagecARmRO4To7Meta?.path ?? "/ph/top",
    meta: TopLaddersPagecARmRO4To7Meta || {},
    alias: TopLaddersPagecARmRO4To7Meta?.alias || [],
    redirect: TopLaddersPagecARmRO4To7Meta?.redirect,
    component: () => import("/app/layers/dota/routes/mainPage/TopLaddersPage/TopLaddersPage.vue").then(m => m.default || m)
  },
  {
    name: TopLaddersPagecARmRO4To7Meta?.name ?? "top___id",
    path: TopLaddersPagecARmRO4To7Meta?.path ?? "/id/top",
    meta: TopLaddersPagecARmRO4To7Meta || {},
    alias: TopLaddersPagecARmRO4To7Meta?.alias || [],
    redirect: TopLaddersPagecARmRO4To7Meta?.redirect,
    component: () => import("/app/layers/dota/routes/mainPage/TopLaddersPage/TopLaddersPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageilrUVbfL3gMeta?.name ?? "techies___en",
    path: MainPageilrUVbfL3gMeta?.path ?? "/en/techies",
    meta: MainPageilrUVbfL3gMeta || {},
    alias: MainPageilrUVbfL3gMeta?.alias || [],
    redirect: MainPageilrUVbfL3gMeta?.redirect,
    component: () => import("/app/layers/dota/routes/techies/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageilrUVbfL3gMeta?.name ?? "techies___ru",
    path: MainPageilrUVbfL3gMeta?.path ?? "/techies",
    meta: MainPageilrUVbfL3gMeta || {},
    alias: MainPageilrUVbfL3gMeta?.alias || [],
    redirect: MainPageilrUVbfL3gMeta?.redirect,
    component: () => import("/app/layers/dota/routes/techies/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageilrUVbfL3gMeta?.name ?? "techies___kk",
    path: MainPageilrUVbfL3gMeta?.path ?? "/kk/techies",
    meta: MainPageilrUVbfL3gMeta || {},
    alias: MainPageilrUVbfL3gMeta?.alias || [],
    redirect: MainPageilrUVbfL3gMeta?.redirect,
    component: () => import("/app/layers/dota/routes/techies/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageilrUVbfL3gMeta?.name ?? "techies___az",
    path: MainPageilrUVbfL3gMeta?.path ?? "/az/techies",
    meta: MainPageilrUVbfL3gMeta || {},
    alias: MainPageilrUVbfL3gMeta?.alias || [],
    redirect: MainPageilrUVbfL3gMeta?.redirect,
    component: () => import("/app/layers/dota/routes/techies/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageilrUVbfL3gMeta?.name ?? "techies___uz",
    path: MainPageilrUVbfL3gMeta?.path ?? "/uz/techies",
    meta: MainPageilrUVbfL3gMeta || {},
    alias: MainPageilrUVbfL3gMeta?.alias || [],
    redirect: MainPageilrUVbfL3gMeta?.redirect,
    component: () => import("/app/layers/dota/routes/techies/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageilrUVbfL3gMeta?.name ?? "techies___es",
    path: MainPageilrUVbfL3gMeta?.path ?? "/es/techies",
    meta: MainPageilrUVbfL3gMeta || {},
    alias: MainPageilrUVbfL3gMeta?.alias || [],
    redirect: MainPageilrUVbfL3gMeta?.redirect,
    component: () => import("/app/layers/dota/routes/techies/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageilrUVbfL3gMeta?.name ?? "techies___hi",
    path: MainPageilrUVbfL3gMeta?.path ?? "/hi/techies",
    meta: MainPageilrUVbfL3gMeta || {},
    alias: MainPageilrUVbfL3gMeta?.alias || [],
    redirect: MainPageilrUVbfL3gMeta?.redirect,
    component: () => import("/app/layers/dota/routes/techies/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageilrUVbfL3gMeta?.name ?? "techies___ph",
    path: MainPageilrUVbfL3gMeta?.path ?? "/ph/techies",
    meta: MainPageilrUVbfL3gMeta || {},
    alias: MainPageilrUVbfL3gMeta?.alias || [],
    redirect: MainPageilrUVbfL3gMeta?.redirect,
    component: () => import("/app/layers/dota/routes/techies/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageilrUVbfL3gMeta?.name ?? "techies___id",
    path: MainPageilrUVbfL3gMeta?.path ?? "/id/techies",
    meta: MainPageilrUVbfL3gMeta || {},
    alias: MainPageilrUVbfL3gMeta?.alias || [],
    redirect: MainPageilrUVbfL3gMeta?.redirect,
    component: () => import("/app/layers/dota/routes/techies/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: UserPageSecretNpbLlJzwMNMeta?.name ?? "SuperSecretProfilePage2___en",
    path: UserPageSecretNpbLlJzwMNMeta?.path ?? "/en/SuperSecretProfilePage2/:userId",
    meta: UserPageSecretNpbLlJzwMNMeta || {},
    alias: UserPageSecretNpbLlJzwMNMeta?.alias || [],
    redirect: UserPageSecretNpbLlJzwMNMeta?.redirect,
    component: () => import("~/layers/dota/routes/profile/UserPageSecret/UserPageSecret.vue").then(m => m.default || m)
  },
  {
    name: UserPageSecretNpbLlJzwMNMeta?.name ?? "SuperSecretProfilePage2___ru",
    path: UserPageSecretNpbLlJzwMNMeta?.path ?? "/SuperSecretProfilePage2/:userId",
    meta: UserPageSecretNpbLlJzwMNMeta || {},
    alias: UserPageSecretNpbLlJzwMNMeta?.alias || [],
    redirect: UserPageSecretNpbLlJzwMNMeta?.redirect,
    component: () => import("~/layers/dota/routes/profile/UserPageSecret/UserPageSecret.vue").then(m => m.default || m)
  },
  {
    name: UserPageSecretNpbLlJzwMNMeta?.name ?? "SuperSecretProfilePage2___kk",
    path: UserPageSecretNpbLlJzwMNMeta?.path ?? "/kk/SuperSecretProfilePage2/:userId",
    meta: UserPageSecretNpbLlJzwMNMeta || {},
    alias: UserPageSecretNpbLlJzwMNMeta?.alias || [],
    redirect: UserPageSecretNpbLlJzwMNMeta?.redirect,
    component: () => import("~/layers/dota/routes/profile/UserPageSecret/UserPageSecret.vue").then(m => m.default || m)
  },
  {
    name: UserPageSecretNpbLlJzwMNMeta?.name ?? "SuperSecretProfilePage2___az",
    path: UserPageSecretNpbLlJzwMNMeta?.path ?? "/az/SuperSecretProfilePage2/:userId",
    meta: UserPageSecretNpbLlJzwMNMeta || {},
    alias: UserPageSecretNpbLlJzwMNMeta?.alias || [],
    redirect: UserPageSecretNpbLlJzwMNMeta?.redirect,
    component: () => import("~/layers/dota/routes/profile/UserPageSecret/UserPageSecret.vue").then(m => m.default || m)
  },
  {
    name: UserPageSecretNpbLlJzwMNMeta?.name ?? "SuperSecretProfilePage2___uz",
    path: UserPageSecretNpbLlJzwMNMeta?.path ?? "/uz/SuperSecretProfilePage2/:userId",
    meta: UserPageSecretNpbLlJzwMNMeta || {},
    alias: UserPageSecretNpbLlJzwMNMeta?.alias || [],
    redirect: UserPageSecretNpbLlJzwMNMeta?.redirect,
    component: () => import("~/layers/dota/routes/profile/UserPageSecret/UserPageSecret.vue").then(m => m.default || m)
  },
  {
    name: UserPageSecretNpbLlJzwMNMeta?.name ?? "SuperSecretProfilePage2___es",
    path: UserPageSecretNpbLlJzwMNMeta?.path ?? "/es/SuperSecretProfilePage2/:userId",
    meta: UserPageSecretNpbLlJzwMNMeta || {},
    alias: UserPageSecretNpbLlJzwMNMeta?.alias || [],
    redirect: UserPageSecretNpbLlJzwMNMeta?.redirect,
    component: () => import("~/layers/dota/routes/profile/UserPageSecret/UserPageSecret.vue").then(m => m.default || m)
  },
  {
    name: UserPageSecretNpbLlJzwMNMeta?.name ?? "SuperSecretProfilePage2___hi",
    path: UserPageSecretNpbLlJzwMNMeta?.path ?? "/hi/SuperSecretProfilePage2/:userId",
    meta: UserPageSecretNpbLlJzwMNMeta || {},
    alias: UserPageSecretNpbLlJzwMNMeta?.alias || [],
    redirect: UserPageSecretNpbLlJzwMNMeta?.redirect,
    component: () => import("~/layers/dota/routes/profile/UserPageSecret/UserPageSecret.vue").then(m => m.default || m)
  },
  {
    name: UserPageSecretNpbLlJzwMNMeta?.name ?? "SuperSecretProfilePage2___ph",
    path: UserPageSecretNpbLlJzwMNMeta?.path ?? "/ph/SuperSecretProfilePage2/:userId",
    meta: UserPageSecretNpbLlJzwMNMeta || {},
    alias: UserPageSecretNpbLlJzwMNMeta?.alias || [],
    redirect: UserPageSecretNpbLlJzwMNMeta?.redirect,
    component: () => import("~/layers/dota/routes/profile/UserPageSecret/UserPageSecret.vue").then(m => m.default || m)
  },
  {
    name: UserPageSecretNpbLlJzwMNMeta?.name ?? "SuperSecretProfilePage2___id",
    path: UserPageSecretNpbLlJzwMNMeta?.path ?? "/id/SuperSecretProfilePage2/:userId",
    meta: UserPageSecretNpbLlJzwMNMeta || {},
    alias: UserPageSecretNpbLlJzwMNMeta?.alias || [],
    redirect: UserPageSecretNpbLlJzwMNMeta?.redirect,
    component: () => import("~/layers/dota/routes/profile/UserPageSecret/UserPageSecret.vue").then(m => m.default || m)
  },
  {
    name: MainPagefFC20c5EcCMeta?.name ?? "quiz___en",
    path: MainPagefFC20c5EcCMeta?.path ?? "/en/quiz",
    meta: MainPagefFC20c5EcCMeta || {},
    alias: MainPagefFC20c5EcCMeta?.alias || [],
    redirect: MainPagefFC20c5EcCMeta?.redirect,
    component: () => import("/app/layers/dota/routes/quiz/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagefFC20c5EcCMeta?.name ?? "quiz___ru",
    path: MainPagefFC20c5EcCMeta?.path ?? "/quiz",
    meta: MainPagefFC20c5EcCMeta || {},
    alias: MainPagefFC20c5EcCMeta?.alias || [],
    redirect: MainPagefFC20c5EcCMeta?.redirect,
    component: () => import("/app/layers/dota/routes/quiz/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagefFC20c5EcCMeta?.name ?? "quiz___kk",
    path: MainPagefFC20c5EcCMeta?.path ?? "/kk/quiz",
    meta: MainPagefFC20c5EcCMeta || {},
    alias: MainPagefFC20c5EcCMeta?.alias || [],
    redirect: MainPagefFC20c5EcCMeta?.redirect,
    component: () => import("/app/layers/dota/routes/quiz/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagefFC20c5EcCMeta?.name ?? "quiz___az",
    path: MainPagefFC20c5EcCMeta?.path ?? "/az/quiz",
    meta: MainPagefFC20c5EcCMeta || {},
    alias: MainPagefFC20c5EcCMeta?.alias || [],
    redirect: MainPagefFC20c5EcCMeta?.redirect,
    component: () => import("/app/layers/dota/routes/quiz/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagefFC20c5EcCMeta?.name ?? "quiz___uz",
    path: MainPagefFC20c5EcCMeta?.path ?? "/uz/quiz",
    meta: MainPagefFC20c5EcCMeta || {},
    alias: MainPagefFC20c5EcCMeta?.alias || [],
    redirect: MainPagefFC20c5EcCMeta?.redirect,
    component: () => import("/app/layers/dota/routes/quiz/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagefFC20c5EcCMeta?.name ?? "quiz___es",
    path: MainPagefFC20c5EcCMeta?.path ?? "/es/quiz",
    meta: MainPagefFC20c5EcCMeta || {},
    alias: MainPagefFC20c5EcCMeta?.alias || [],
    redirect: MainPagefFC20c5EcCMeta?.redirect,
    component: () => import("/app/layers/dota/routes/quiz/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagefFC20c5EcCMeta?.name ?? "quiz___hi",
    path: MainPagefFC20c5EcCMeta?.path ?? "/hi/quiz",
    meta: MainPagefFC20c5EcCMeta || {},
    alias: MainPagefFC20c5EcCMeta?.alias || [],
    redirect: MainPagefFC20c5EcCMeta?.redirect,
    component: () => import("/app/layers/dota/routes/quiz/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagefFC20c5EcCMeta?.name ?? "quiz___ph",
    path: MainPagefFC20c5EcCMeta?.path ?? "/ph/quiz",
    meta: MainPagefFC20c5EcCMeta || {},
    alias: MainPagefFC20c5EcCMeta?.alias || [],
    redirect: MainPagefFC20c5EcCMeta?.redirect,
    component: () => import("/app/layers/dota/routes/quiz/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPagefFC20c5EcCMeta?.name ?? "quiz___id",
    path: MainPagefFC20c5EcCMeta?.path ?? "/id/quiz",
    meta: MainPagefFC20c5EcCMeta || {},
    alias: MainPagefFC20c5EcCMeta?.alias || [],
    redirect: MainPagefFC20c5EcCMeta?.redirect,
    component: () => import("/app/layers/dota/routes/quiz/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageuEGbVpMMPyMeta?.name ?? "faq___en",
    path: MainPageuEGbVpMMPyMeta?.path ?? "/en/faq",
    meta: MainPageuEGbVpMMPyMeta || {},
    alias: MainPageuEGbVpMMPyMeta?.alias || [],
    redirect: MainPageuEGbVpMMPyMeta?.redirect,
    component: () => import("/app/layers/dota/routes/faq/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageuEGbVpMMPyMeta?.name ?? "faq___ru",
    path: MainPageuEGbVpMMPyMeta?.path ?? "/faq",
    meta: MainPageuEGbVpMMPyMeta || {},
    alias: MainPageuEGbVpMMPyMeta?.alias || [],
    redirect: MainPageuEGbVpMMPyMeta?.redirect,
    component: () => import("/app/layers/dota/routes/faq/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageuEGbVpMMPyMeta?.name ?? "faq___kk",
    path: MainPageuEGbVpMMPyMeta?.path ?? "/kk/faq",
    meta: MainPageuEGbVpMMPyMeta || {},
    alias: MainPageuEGbVpMMPyMeta?.alias || [],
    redirect: MainPageuEGbVpMMPyMeta?.redirect,
    component: () => import("/app/layers/dota/routes/faq/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageuEGbVpMMPyMeta?.name ?? "faq___az",
    path: MainPageuEGbVpMMPyMeta?.path ?? "/az/faq",
    meta: MainPageuEGbVpMMPyMeta || {},
    alias: MainPageuEGbVpMMPyMeta?.alias || [],
    redirect: MainPageuEGbVpMMPyMeta?.redirect,
    component: () => import("/app/layers/dota/routes/faq/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageuEGbVpMMPyMeta?.name ?? "faq___uz",
    path: MainPageuEGbVpMMPyMeta?.path ?? "/uz/faq",
    meta: MainPageuEGbVpMMPyMeta || {},
    alias: MainPageuEGbVpMMPyMeta?.alias || [],
    redirect: MainPageuEGbVpMMPyMeta?.redirect,
    component: () => import("/app/layers/dota/routes/faq/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageuEGbVpMMPyMeta?.name ?? "faq___es",
    path: MainPageuEGbVpMMPyMeta?.path ?? "/es/faq",
    meta: MainPageuEGbVpMMPyMeta || {},
    alias: MainPageuEGbVpMMPyMeta?.alias || [],
    redirect: MainPageuEGbVpMMPyMeta?.redirect,
    component: () => import("/app/layers/dota/routes/faq/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageuEGbVpMMPyMeta?.name ?? "faq___hi",
    path: MainPageuEGbVpMMPyMeta?.path ?? "/hi/faq",
    meta: MainPageuEGbVpMMPyMeta || {},
    alias: MainPageuEGbVpMMPyMeta?.alias || [],
    redirect: MainPageuEGbVpMMPyMeta?.redirect,
    component: () => import("/app/layers/dota/routes/faq/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageuEGbVpMMPyMeta?.name ?? "faq___ph",
    path: MainPageuEGbVpMMPyMeta?.path ?? "/ph/faq",
    meta: MainPageuEGbVpMMPyMeta || {},
    alias: MainPageuEGbVpMMPyMeta?.alias || [],
    redirect: MainPageuEGbVpMMPyMeta?.redirect,
    component: () => import("/app/layers/dota/routes/faq/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: MainPageuEGbVpMMPyMeta?.name ?? "faq___id",
    path: MainPageuEGbVpMMPyMeta?.path ?? "/id/faq",
    meta: MainPageuEGbVpMMPyMeta || {},
    alias: MainPageuEGbVpMMPyMeta?.alias || [],
    redirect: MainPageuEGbVpMMPyMeta?.redirect,
    component: () => import("/app/layers/dota/routes/faq/MainPage/MainPage.vue").then(m => m.default || m)
  },
  {
    name: LandingKrCpxeqvBgMeta?.name ?? "hamster___en",
    path: LandingKrCpxeqvBgMeta?.path ?? "/en/hamster",
    meta: LandingKrCpxeqvBgMeta || {},
    alias: LandingKrCpxeqvBgMeta?.alias || [],
    redirect: LandingKrCpxeqvBgMeta?.redirect,
    component: () => import("/app/layers/dota/routes/hamster/Landing/Landing.vue").then(m => m.default || m)
  },
  {
    name: LandingKrCpxeqvBgMeta?.name ?? "hamster___ru",
    path: LandingKrCpxeqvBgMeta?.path ?? "/hamster",
    meta: LandingKrCpxeqvBgMeta || {},
    alias: LandingKrCpxeqvBgMeta?.alias || [],
    redirect: LandingKrCpxeqvBgMeta?.redirect,
    component: () => import("/app/layers/dota/routes/hamster/Landing/Landing.vue").then(m => m.default || m)
  },
  {
    name: LandingKrCpxeqvBgMeta?.name ?? "hamster___kk",
    path: LandingKrCpxeqvBgMeta?.path ?? "/kk/hamster",
    meta: LandingKrCpxeqvBgMeta || {},
    alias: LandingKrCpxeqvBgMeta?.alias || [],
    redirect: LandingKrCpxeqvBgMeta?.redirect,
    component: () => import("/app/layers/dota/routes/hamster/Landing/Landing.vue").then(m => m.default || m)
  },
  {
    name: LandingKrCpxeqvBgMeta?.name ?? "hamster___az",
    path: LandingKrCpxeqvBgMeta?.path ?? "/az/hamster",
    meta: LandingKrCpxeqvBgMeta || {},
    alias: LandingKrCpxeqvBgMeta?.alias || [],
    redirect: LandingKrCpxeqvBgMeta?.redirect,
    component: () => import("/app/layers/dota/routes/hamster/Landing/Landing.vue").then(m => m.default || m)
  },
  {
    name: LandingKrCpxeqvBgMeta?.name ?? "hamster___uz",
    path: LandingKrCpxeqvBgMeta?.path ?? "/uz/hamster",
    meta: LandingKrCpxeqvBgMeta || {},
    alias: LandingKrCpxeqvBgMeta?.alias || [],
    redirect: LandingKrCpxeqvBgMeta?.redirect,
    component: () => import("/app/layers/dota/routes/hamster/Landing/Landing.vue").then(m => m.default || m)
  },
  {
    name: LandingKrCpxeqvBgMeta?.name ?? "hamster___es",
    path: LandingKrCpxeqvBgMeta?.path ?? "/es/hamster",
    meta: LandingKrCpxeqvBgMeta || {},
    alias: LandingKrCpxeqvBgMeta?.alias || [],
    redirect: LandingKrCpxeqvBgMeta?.redirect,
    component: () => import("/app/layers/dota/routes/hamster/Landing/Landing.vue").then(m => m.default || m)
  },
  {
    name: LandingKrCpxeqvBgMeta?.name ?? "hamster___hi",
    path: LandingKrCpxeqvBgMeta?.path ?? "/hi/hamster",
    meta: LandingKrCpxeqvBgMeta || {},
    alias: LandingKrCpxeqvBgMeta?.alias || [],
    redirect: LandingKrCpxeqvBgMeta?.redirect,
    component: () => import("/app/layers/dota/routes/hamster/Landing/Landing.vue").then(m => m.default || m)
  },
  {
    name: LandingKrCpxeqvBgMeta?.name ?? "hamster___ph",
    path: LandingKrCpxeqvBgMeta?.path ?? "/ph/hamster",
    meta: LandingKrCpxeqvBgMeta || {},
    alias: LandingKrCpxeqvBgMeta?.alias || [],
    redirect: LandingKrCpxeqvBgMeta?.redirect,
    component: () => import("/app/layers/dota/routes/hamster/Landing/Landing.vue").then(m => m.default || m)
  },
  {
    name: LandingKrCpxeqvBgMeta?.name ?? "hamster___id",
    path: LandingKrCpxeqvBgMeta?.path ?? "/id/hamster",
    meta: LandingKrCpxeqvBgMeta || {},
    alias: LandingKrCpxeqvBgMeta?.alias || [],
    redirect: LandingKrCpxeqvBgMeta?.redirect,
    component: () => import("/app/layers/dota/routes/hamster/Landing/Landing.vue").then(m => m.default || m)
  }
]