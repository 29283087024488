import { EHeaderMenuItems, type IAppsConfig } from './index.types';

export default <IAppsConfig>{
  auth: {
    isCheckboxesSelectedByDefault: true,
    variants: ['steam', 'google', 'vk'],
  },
  cases: {
    showButtons: true,
  },
  contacts: {
    adMail: 'media_td@tastyteam.cc',
    supportMail: 'support@tastyteam.cc',
    partnerMail: 'partner@tastyteam.cc',
  },
  defaultCurrency: 'RUB',
  faq: {
    type3: {
      categoryId: 1,
      questionId: 1,
    },
  },
  footer: {
    logo: 'SvgoLogoFooterTastystrike',
    copyright: 'TastySTRIKE',
    paymentMethods: ['visa', 'mastercard', 'qiwi', 'yoomoney', 'webmoney', 'hotskins'],
    hideSocials: false,
  },
  headerLogo: 'SvgoLogoHeaderTastystrike',
  legalData: {
    beneficiary: 'OmegaWalls OÜ',
    registrationNumber: 16473723,
    address: 'Vesivärava tn 50-201 Tallinn Harjumaa 10152',
  },
  isTechiesDisabled: false,
  hidePaymentBackBlock: false,
  hideSecretShop: false,
  hideFreeCasesBanner: false,
  hideHeaderMenuItems: [EHeaderMenuItems.LEADERBOARD],
  hideProjectSwitcher: false,
  supportKey: 'sePmTTSmEQ',
  seo: {
    favicon: '/img/favicon/cs.ico',
    title: 'Tastystrike - Кейсы CS 2',
    description:
      'TastySTRIKE — это самый крупный сайт с кейсами по CS 2. Покупай кейсы и получай дорогие скины на любимое оружие! Вывод на Steam в течение 1 минуты.',
  },
  statistics: {
    hideElements: [],
  },
  techWork: {
    logo: 'SvgoTechWorkLogoDota',
    logoClass: 'logo--dota',
  },
  redirects: {
    newCases: ROUTING.MAINPAGE.NEW_CASES,
  },
  legalVariant: 'dota',
};
