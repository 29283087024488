<template>
  <NuxtLinkLocale :class="['button', classBinders]" :style="styleBinders" v-bind="linkProps">
    <Transition name="fade" mode="out-in">
      <div
        v-if="!isLoading"
        :class="['default', ($slots.append || $slots.prepend) && 'default__gapped']"
        :style="{ ...btnStyles }"
      >
        <slot name="prepend" />
        <div v-if="text" :class="['button__text', textBinders]">
          {{ text }}
        </div>
        <slot v-if="$slots.default" name="default" />
        <slot name="append" />
      </div>
      <div v-else class="loading"></div>
    </Transition>
  </NuxtLinkLocale>
</template>

<script setup lang="ts">
import type { ISharedKitButtonProps } from './SharedKitButton.types';

const { toPx } = GlobalUtils.Converting;

const props = withDefaults(defineProps<ISharedKitButtonProps>(), {
  isDisabled: false,
  isLoading: false,
});

const classBinders = computed(() => ({
  button__disabled: props.isDisabled,
  button__loading: props.isLoading,
}));

const textBinders = computed(() => ({
  text__gradient:
    // eslint-disable-next-line
    GlobalUtils.Objects.isObject(props.colorPreset.textColor as any) || Array.isArray(props.colorPreset.textColor),
}));

const styleBinders = computed(() => {
  const size = props.sizePreset;
  const type = props.typePreset;
  const color = props.colorPreset;

  const config = {
    '--button-height': toPx(size.height),
    '--button-radius': toPx(0),
    '--default-bg': GlobalUtils.CSS.getBackgroundColor(color.defaultBackground),
    '--hover-bg': GlobalUtils.CSS.getBackgroundColor(color.hoverBackground),
    '--hover-text-color': GlobalUtils.CSS.getBackgroundColor(color.hoverTextColor ?? color.textColor),
    '--text-color':
      GlobalUtils.CSS.parseColor(color.textColor).backgroundImage !== 'none'
        ? GlobalUtils.CSS.parseColor(color.textColor).backgroundImage
        : color.textColor,
    '--text-loading-color': GlobalUtils.CSS.getBackgroundColor(color.loadingColor),
    '--text-size': toPx(size.fontSize),
  };

  if (type && type[size.type]) {
    config['--button-radius'] = GlobalUtils.CSS.proceedCssValue(type[size.type]!, 'px');
  }

  return config;
});
</script>

<style scoped lang="scss" src="./SharedKitButton.scss" />
