import type { RouterConfig } from '@nuxt/schema';
import { GlobalUtils, ROUTING } from '~/utils';

async function tryScrollToAnchor(hash: string, timeout = 1000, delay = 100) {
  while (timeout > 0) {
    const el = document.querySelector(hash);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
      break;
    }

    await new Promise((resolve) => setTimeout(resolve, delay));
    timeout -= delay;
  }
}

export default <RouterConfig>{
  scrollBehavior: (to, savedPosition) => {
    // Выборка страниц главной
    const mainPageNames = GlobalUtils.Routing.createRoutingNamesArray(
      ROUTING.MAINPAGE.MAINPAGE,
      ROUTING.MAINPAGE.NEW_CASES,
      ROUTING.MAINPAGE.TOP,
    );

    const routeBaseName = useRouteBaseName();
    const baseName = routeBaseName(to);

    // Если это не главная страница, то применяется обычный scrollBehavior
    if (!mainPageNames.includes(baseName ?? '')) return { top: 0 };

    if (to.hash) {
      tryScrollToAnchor(to.hash);
      return;
    }

    // Если это главная страница, то применяется scrollBehavior для сохранения позиции
    return savedPosition ?? {};
  },
};
