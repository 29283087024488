import UserHttp from '~/repository/modules/user/UserHttp';
import type { ISetSteamDataRequestDTO } from '~/repository/modules/user/user.types';

export default class UserService {
  private readonly userHttp: UserHttp;

  constructor() {
    this.userHttp = new UserHttp();
  }

  public getUser(userId: number) {
    return this.userHttp.getUser(userId);
  }

  public getUserOffers(userId: number) {
    return this.userHttp.getUserOffers(userId);
  }

  public postUserSession(url: string) {
    return this.userHttp.postUserSession(url);
  }

  public getMe() {
    return this.userHttp.getMe();
  }

  public async setSteamData(params: ISetSteamDataRequestDTO) {
    return await this.userHttp.setSteamData(params);
  }
}
