import type { IAppRoute } from '../../utils/routing.utils';
import { FAQ_ROUTES } from './routes/faq.routes';
import { BATTLES_ROUTES } from './routes/battles.routes';
import { CARDFORM_ROUTES } from './routes/cardForm.routes';
import { HAMSTER_ROUTES } from './routes/hamster.routes';
import { MAINPAGE_ROUTES } from './routes/mainPage.routes';
import { PAYMENT_ROUTES } from './routes/payment.routes';
import { TECHIES_ROUTES } from './routes/techies.routes';
import { PROFILE_ROUTES, PROFILE_SECRET_ROUTES } from './routes/profile.routes';

import { QUIZ_ROUTES } from './routes/quiz.routes';
import { CONTACTS_ROUTES } from './routes/contacts.routes';
import { USERAGREEMENT_ROUTES } from './routes/userAgreement.routes';
import { COOKIES_ROUTES } from './routes/cookies.routes';
import { EVENTCONDITION_ROUTES } from './routes/eventCondition.routes';
import { PRIVACY_POLICY_ROUTES } from './routes/privacy-policy.routes';

const ROOT_ROUTES = {
  BATTLES: '/battles',
  CARDFORM: '/cardform',
  CONTACTS: '/contacts',
  COOKIES: '/cookies',
  EVENTCONDITION: '/eventCondition',
  PRIVACY_POLICY: '/privacy-policy',
  FAQ: '/faq',
  HAMSTER: '/hamster',
  MAINPAGE: '',
  PAYMENT: '/refill',
  PROFILE: '/profile',
  PROFILE_SECRET: '/SuperSecretProfilePage2',
  QUIZ: '/quiz',
  TECHIES: '/techies',
  USERAGREEMENT: '/userAgreement',
};

const ROUTING = {
  BATTLES: wrapByRootRoute(ROOT_ROUTES.BATTLES, BATTLES_ROUTES),
  CARDFORM: wrapByRootRoute(ROOT_ROUTES.CARDFORM, CARDFORM_ROUTES),
  CONTACTS: wrapByRootRoute(ROOT_ROUTES.CONTACTS, CONTACTS_ROUTES),
  COOKIES: wrapByRootRoute(ROOT_ROUTES.COOKIES, COOKIES_ROUTES),
  EVENTCONDITION: wrapByRootRoute(ROOT_ROUTES.EVENTCONDITION, EVENTCONDITION_ROUTES),
  PRIVACY_POLICY: wrapByRootRoute(ROOT_ROUTES.PRIVACY_POLICY, PRIVACY_POLICY_ROUTES),
  FAQ: wrapByRootRoute(ROOT_ROUTES.FAQ, FAQ_ROUTES),
  HAMSTER: wrapByRootRoute(ROOT_ROUTES.HAMSTER, HAMSTER_ROUTES),
  MAINPAGE: wrapByRootRoute(ROOT_ROUTES.MAINPAGE, MAINPAGE_ROUTES),
  PAYMENT: wrapByRootRoute(ROOT_ROUTES.PAYMENT, PAYMENT_ROUTES),
  PROFILE: wrapByRootRoute(ROOT_ROUTES.PROFILE, PROFILE_ROUTES),
  PROFILE_SECRET: wrapByRootRoute(ROOT_ROUTES.PROFILE_SECRET, PROFILE_SECRET_ROUTES),
  QUIZ: wrapByRootRoute(ROOT_ROUTES.QUIZ, QUIZ_ROUTES),
  TECHIES: wrapByRootRoute(ROOT_ROUTES.TECHIES, TECHIES_ROUTES),
  USERAGREEMENT: wrapByRootRoute(ROOT_ROUTES.USERAGREEMENT, USERAGREEMENT_ROUTES),
};

export default ROUTING;

export function wrapByRootRoute<T>(root: string, routes: T): T {
  const wrappedRoutes = {} as T;

  for (const routeKey in routes) {
    const routeConfig = routes[routeKey];
    const anyRouteKey = routeKey as string;

    /* @ts-ignore */
    wrappedRoutes[anyRouteKey as keyof T] = {
      notNative: true,
    } as IAppRoute;

    for (const key in routeConfig) {
      const fieldValue = routeConfig[key];

      if (key === 'getDynamicPath' && typeof fieldValue === 'function') {
        /* @ts-ignore */
        wrappedRoutes[routeKey][key] = (...args: Array<number | string>) => root + fieldValue(...args);
      } else if (key === 'path') {
        /* @ts-ignore */
        wrappedRoutes[routeKey][key] = root + fieldValue;
      } else {
        /* @ts-ignore */
        wrappedRoutes[routeKey][key] = fieldValue;
      }
    }
  }

  return wrappedRoutes;
}
