import { ErrorCodes } from '~/api/global/errors/codes/codes';
import type { IDefaultError } from '~/api/global/api.types';
import ModuleError from '~/repository/extensions/error/ModuleError';
import type { IError, IItemHandlerErrorWithModule } from '~/repository/extensions/error/error.types';

type TListHandlerErrorWithModule = Record<ErrorCodes, IItemHandlerErrorWithModule>;

// Класс нужен для интеграции другим модулей ошибок, например sentry
// Мы передаём в него инстанс модуля/объект в котором описаны ключи по которым мы отправляем ошибки и метод обработки
export default class ModuleErrorModified extends ModuleError {
  private readonly listHandlerErrorWithModule: TListHandlerErrorWithModule;

  constructor(listHandlerErrorWithModule: TListHandlerErrorWithModule) {
    super();
    this.listHandlerErrorWithModule = listHandlerErrorWithModule;
  }

  /** Метод получения ошибки по ключу с бэка, а так-же отправка событий об ошибки с использованием другог модуля
   * @param {IDefaultError} e - объект ошибки
   * @returns {string} - отправляем сообщение об ошибки из списка ключей фронта
   * **/
  override checkError(e: IDefaultError): IError | string {
    if (!e.data || !e.data.data) return ErrorCodes.UNPREDICTED_EXCEPTION;
    const item = this.listHandlerErrorWithModule[e.data.data.code as ErrorCodes];
    item && item.handler();
    return super.checkError(e);
  }
}
