export default defineNuxtRouteMiddleware((to) => {
  const {
    $projectConfig: { isTechiesDisabled },
  } = useNuxtApp();

  if (!isTechiesDisabled) return;

  const getRouteBaseName = useRouteBaseName();
  const baseRouteName = getRouteBaseName(to);

  if (ROUTING.TECHIES.MAINPAGE.name !== baseRouteName) return;

  const localeRoute = useLocaleRoute();
  return navigateTo(localeRoute(ROUTING.MAINPAGE.MAINPAGE));
});
