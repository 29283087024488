import BattlesHttp from './BattlesHttp';
import type {
  IAllBattlesGetResponseBody,
  IBattleCasesSectionsResponseBody,
  IBattleStatisticsResponseBody,
  IBattleUserStatusResponseBody,
  ICancelBattleStartRequestBody,
  ICloseBattleRequestBody,
  IOpenBattleRequestBody,
  ICreateBattleRequestBody,
  ICreateBattleResponseBody,
  IJoinBattleRequestBody,
  IJoinBattleResponseBody,
  ILeaveBattleRequestBody,
  ILeaveBattleResponseBody,
  ISellWinningsBattleRequestBody,
  ISingleBattleResponseBody,
  IStartBattleRequestBody,
  ISendEmodjiRequestBody,
  ISendEmodjiResponseBody,
  IOpenBattleResponseBody,
  ICloseBattleResponseBody,
} from './BattlesApi.types';
import type {
  IBattlesBattleFullInfoGetQuery,
  IBattlesBattlesListGetQuery,
  IBattlesStatisticsGetQuery,
} from './BattlesQueries.types';
import type {
  IBattlesBattleFullInfoEntity,
  IBattlesStatisticEntity,
} from '~/repository/modules/battles/BattlesEntities.types';
import type { TPossibleNull } from '~/types/Shared.types';

export default class BattlesService {
  private readonly battlesHttp: BattlesHttp;

  constructor() {
    this.battlesHttp = new BattlesHttp();
  }

  public async getFullBattleInfo(
    queries: IBattlesBattleFullInfoGetQuery,
  ): Promise<TPossibleNull<IBattlesBattleFullInfoEntity>> {
    let response;
    if (process.server) {
      response = await this.battlesHttp.getSSRData<ISingleBattleResponseBody>('ONE_BATTLE', queries);
    } else {
      response = await this.battlesHttp.getClientData<ISingleBattleResponseBody>('ONE_BATTLE', queries);
    }
    if (!response || !response.battle) return null;
    return response.battle;
  }

  public async getBattlesList(queries: Partial<IBattlesBattlesListGetQuery>): Promise<IAllBattlesGetResponseBody> {
    const response = await this.battlesHttp.getClientData<IAllBattlesGetResponseBody>('ALL_BATTLES', queries, false);
    if (!response || !response.battles) {
      return {
        battles: [],
        hasNext: false,
      };
    }
    return response;
  }

  public async getCaseSections(): Promise<IBattleCasesSectionsResponseBody> {
    let response;
    if (process.server) {
      response = await this.battlesHttp.getSSRData<IBattleCasesSectionsResponseBody>('ALL_CASES');
    } else {
      response = await this.battlesHttp.getClientData<IBattleCasesSectionsResponseBody>('ALL_CASES');
    }
    if (!response)
      return {
        lastCases: [],
        maxRounds: 50,
        sections: [],
      };
    return response;
  }

  public async getBattlesStatistics(queries: IBattlesStatisticsGetQuery): Promise<IBattlesStatisticEntity> {
    const response = await this.battlesHttp.getClientData<IBattleStatisticsResponseBody>('BATTLE_STATISTICS', queries);
    if (!response) {
      return {
        allCount: 0,
        maxWin: 0,
        winsCount: 0,
        winsPercent: 0,
      };
    }
    return response;
  }

  public async getUserStatus(): Promise<IBattleUserStatusResponseBody> {
    const response = await this.battlesHttp.getClientData<IBattleUserStatusResponseBody>('STATUS');
    if (!response) {
      return {
        activeBattleUlid: null,
        inBattle: false,
      };
    }
    return response;
  }

  public async createBattle(body: ICreateBattleRequestBody): Promise<ICreateBattleResponseBody> {
    const response = await this.battlesHttp.makePostRequest<ICreateBattleRequestBody, ICreateBattleResponseBody>(
      'CREATE_BATTLE',
      body,
    );
    if (!response || !response.battleUlid) throw new Error('Create battle error');
    return response;
  }

  public async startBattle(body: IStartBattleRequestBody): Promise<void> {
    const response = await this.battlesHttp.makePostRequest('OFFER_AND_START_BATTLE', body);
    if (!response) throw new Error('Battle Start error');
  }

  public async cancelBattleStart(body: ICancelBattleStartRequestBody): Promise<void> {
    const response = await this.battlesHttp.makePostRequest('CANCEL_START_OFFER', body);
    if (!response) throw new Error('Cancel Battle Start error');
  }

  public async joinBattle(body: IJoinBattleRequestBody): Promise<IJoinBattleResponseBody> {
    const response = await this.battlesHttp.makePostRequest<IJoinBattleRequestBody, IJoinBattleResponseBody>(
      'JOIN_BATTLE',
      body,
    );
    if (!response) {
      return {
        balance: 0,
      };
    }
    return response;
  }

  public async leaveBattle(body: ILeaveBattleRequestBody): Promise<ILeaveBattleResponseBody> {
    const response = await this.battlesHttp.makePostRequest<ILeaveBattleRequestBody, ILeaveBattleResponseBody>(
      'LEAVE_BATTLE',
      body,
    );
    if (!response) {
      return {
        balance: 0,
      };
    }
    return response;
  }

  public async sellBattleWinnings(body: ISellWinningsBattleRequestBody): Promise<void> {
    const response = await this.battlesHttp.makePostRequest('SELL_ALL_WINNINGS', body);
    if (!response) throw new Error('Cancel Battle Start error');
  }

  public async closeBattle(body: ICloseBattleRequestBody): Promise<ICloseBattleResponseBody> {
    const response = await this.battlesHttp.makePostRequest<ICloseBattleRequestBody, ICloseBattleResponseBody>(
      'CLOSE_NOTIFY',
      body,
    );
    if (!response) return { spectators: 0 };
    return response;
  }

  public async openBattle(body: IOpenBattleRequestBody): Promise<IOpenBattleResponseBody> {
    const response = await this.battlesHttp.makePostRequest<IOpenBattleRequestBody, IOpenBattleResponseBody>(
      'OPEN_NOTIFY',
      body,
    );
    if (!response) return { spectators: 0 };
    return response;
  }

  public async sendEmodji(body: ISendEmodjiRequestBody): Promise<ISendEmodjiResponseBody> {
    const response = await this.battlesHttp.makePostRequest<ISendEmodjiRequestBody, ISendEmodjiResponseBody>(
      'SEND_EMOJI',
      body,
    );
    if (!response) {
      return {
        battleUlid: '',
        content: '',
        memberId: 0,
      };
    }
    return response;
  }
}
