import type { LocationQuery } from '#vue-router';
import type { IUser } from '~/store/user/user.types';
import type { TPossibleNull } from '~/types/Shared.types';
import { useUserStore } from '~/store/user/user.store';
import { AuthEvents } from '~/repository/amplitude/events/auth';

interface ISignupAmplitudeData {
  createdAt: number;
  isLogged: boolean;
  query: TPossibleNull<LocationQuery>;
  url: string;
}

export function useSignupAmplitude() {
  const route = useRoute();
  const userStore = useUserStore();
  const { user } = storeToRefs(userStore);

  const type = usePersistedRef<string>('amplitude-sign-up-type', '');
  const creationData = usePersistedRef<TPossibleNull<ISignupAmplitudeData>>(
    'amplitude-sign-up-creation',
    null,
    EStorageTypes.SESSION,
  );

  const saveType = (newType: string) => {
    type.value = newType;
  };

  const saveCreationData = () => {
    creationData.value = {
      createdAt: Date.now(),
      isLogged: false,
      query: route.query,
      url: window ? location.href : '',
    };
  };

  const sendCreationEvent = ({ userId, createdAt }: IUser) => {
    const invalidData = !type.value || creationData.value?.isLogged || isNaN(Date.parse(createdAt));
    if (!creationData.value || invalidData) return;

    const route = useRoute();

    try {
      creationData.value.isLogged = true;
      const utmFull = creationData.value.query
        ? Object.entries(creationData.value.query)
            .map((param) => `${param[0]}=${param[1]}`)
            .join('&')
        : '';

      if (route.query.new_user === '1') {
        AuthEvents.accountCreated(
          {
            'Url': creationData.value.url,
            'Utm full': utmFull,
            'signupType': type.value,
            'utm_campaign': (creationData.value.query?.utm_campaign ?? '') as string,
            'utm_content': (creationData.value.query?.utm_content ?? '') as string,
            'utm_medium': (creationData.value.query?.utm_medium ?? '') as string,
            'utm_source': (creationData.value.query?.utm_source ?? '') as string,
            'utm_term': (creationData.value.query?.utm_term ?? '') as string,
          },
          {
            'User ID': userId,
            'signupDate': createdAt,
            'signupType': type.value,
            'signupUtm': utmFull,
          },
        );
        return;
      }

      AuthEvents.login(
        {
          Url: creationData.value.url,
          signupType: type.value,
        },
        {
          'User ID': userId,
        },
      );
    } catch {}
  };

  const watchToSendLoginAmplitude = () => {
    watch(
      user,
      (newUser, oldUser) => {
        if (!newUser || oldUser) return;
        sendCreationEvent(newUser);
      },
      { once: true },
    );
  };

  return {
    creationData,
    saveCreationData,
    saveType,
    sendCreationEvent,
    type,
    watchToSendLoginAmplitude,
  };
}
