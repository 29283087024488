<template>
  <component :is="logo" :class="['logo', logoClass]" />
</template>

<script lang="ts" setup>
const {
  $projectConfig: {
    techWork: { logoClass, logo },
  },
} = useNuxtApp();
</script>

<style lang="scss" scoped src="./Logo.scss" />
