import type { ErrorCodes } from '~/api/global/errors/codes/codes';
import type { IItemHandlerErrorWithModule } from '~/repository/extensions/error/error.types';

// Объект с ключами для sentry, внутрь handler необходимо поместить метод отправки запроса от sentry
export default <Record<ErrorCodes, IItemHandlerErrorWithModule>>{
  authentication: {
    handler() {},
    title: '',
  },
};
