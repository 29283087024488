import SeoRepository from '~/features/seo/repository/api';
import type { ISeoApiData } from '~/features/seo/types/api.types';

export default defineNuxtRouteMiddleware(async (to) => {
  const {
    isHydrating,
    payload: { serverRendered },
    $projectConfig: {
      seo: { title: defaultTitle, description: defaultDescription },
    },
  } = useNuxtApp();

  if (import.meta.client && isHydrating && serverRendered) return;

  let seoData: Partial<ISeoApiData> = {
    title: defaultTitle,
    description: defaultDescription,
  };

  try {
    const apiSeoData = await SeoRepository.getSeo(to.path);
    if (!apiSeoData) throw new Error('SEO data not found');

    seoData = apiSeoData;
  } catch {
  } finally {
    const {
      public: { baseUrl },
    } = useRuntimeConfig();
    const fullUrl = `${baseUrl}${to.fullPath}`;
    const imageUrl = `${baseUrl}/images/Logo/TD_dotamix2.jpg`;

    const { title, description } = seoData;

    useHead({
      link: [{ href: fullUrl, rel: 'canonical' }],
      meta: [
        {
          content: title,
          property: 'vk:title',
        },
        {
          content: description,
          name: 'description',
        },
        {
          content: description,
          property: 'vk:description',
        },
        {
          content: description,
          property: 'vk:text',
        },
        {
          content: fullUrl,
          property: 'vk:url',
        },
        {
          content: imageUrl,
          property: 'vk:image',
        },
        {
          content: 'website',
          property: 'og:type',
        },
        {
          content: title,
          property: 'og:title',
        },
        {
          content: description,
          property: 'og:description',
        },
        {
          content: title,
          property: 'og:site_name',
        },
        {
          content: fullUrl,
          property: 'og:url',
        },
        {
          content: imageUrl,
          property: 'og:image',
        },
      ],
      title,
    });
  }
});
