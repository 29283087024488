import { ETabsPresets } from '~/features/mainPage/components/HeaderTab/HeaderTab.types';
import type {
  ITopLadderResponseBody,
  ILadderHistoryDto,
  ILadderUser,
  ITopEventLadderHistoryResponseBody,
  ITopEventLadderResponseBody,
  ITopLadderHistoryResponseBody,
  ICurrentEventSettingsResponseBody,
  IUserInfoResponseBody,
  IAvailableCases,
  IRaffleResultInfoResponseBody,
  IRaffleResult,
  IHistoryEventData,
} from '~/repository/modules/mainPage/MainPageApi.types';
import type {
  ITopLadderEntity,
  IUserDataInLadder,
  IItemDataInLadder,
  ITopLadderHistoryEntity,
  ICurrentEventSettingsEntity,
  IUserInLadder,
  IEventLadderData,
  ITopEventLadderEntity,
  ITopEventLadderHistoryEntity,
  IUserInfoEntity,
  IRaffleResultInfoEntity,
} from '~/repository/modules/mainPage/MainPageEntities.types';
import type { TPossibleNull } from '~/types/Shared.types';
import { parseImageDomain } from '~/utils/image.utils';

const getFullIconUrl = (icon: TPossibleNull<string>) => {
  if (!icon) return '';
  if (icon.includes('https')) return icon;
  return `https://tastydrop.cc${icon}`;
};

export const TopLadderAdapter = (data: ITopLadderResponseBody): ITopLadderEntity => {
  const {
    $projectConfig: { defaultCurrency },
  } = useNuxtApp();

  let me: TPossibleNull<IUserInLadder> = null;
  if (data.topCurrentUser !== null) {
    const myInfo: IUserDataInLadder = {
      avatar: data.topCurrentUser.avatar,
      coins: data.topCurrentUser.tastyCoins,
      level: data.topCurrentUser.level ? data.topCurrentUser.level : 0,
      positionInLadder: data.topCurrentUser.position,
      userId: data.topCurrentUser.userId,
      username: data.topCurrentUser.name,
    };
    let myItem: TPossibleNull<IItemDataInLadder> = null;

    if (data.topCurrentUser.item) {
      myItem = {
        id: data.topCurrentUser.itemId ?? 0,
        imageData: { image: data.topCurrentUser.img ?? '' },
        link: data.topCurrentUser.link ?? '',
        name: data.topCurrentUser.item,
        priceData: {
          currency: defaultCurrency,
          price: String(data.topCurrentUser.price) ?? '0',
        },
        qualityEnum: { name: data.topCurrentUser.quality ?? 'Arcana' },
        tastyCoins: null,
      };
    }
    me = {
      item: myItem,
      user: myInfo,
    };
  }

  const users: IUserInLadder[] = data.topUsers.map((user: ILadderUser) => {
    return {
      item: {
        id: user.itemId,
        imageData: { image: user.img },
        isUltraRare: user.isUltraRare,
        link: user.link,
        name: user.item,
        priceData: {
          currency: user.price.currency,
          price: user.price.price,
        },
        qualityEnum: { name: user.quality },
        tastyCoins: null,
      },
      user: {
        avatar: user.avatar,
        coins: user.tastyCoins,
        level: user.userLevel,
        positionInLadder: user.position,
        userId: user.userId,
        username: user.name,
      },
    };
  });

  return {
    me,
    users,
  };
};

export const TopLadderHistoryAdapter = (data: ITopLadderHistoryResponseBody): ITopLadderHistoryEntity => {
  const history = data.topUsers.map((ladderDto: ILadderHistoryDto) => {
    const dateStartObject = new Date(ladderDto.dateStart);
    const dateStartUnix = dateStartObject.getTime();
    const dateEndObject = new Date(ladderDto.dateEnd);
    const dateEndUnix = dateEndObject.getTime();

    return {
      dateEnd: dateEndUnix,
      dateStart: dateStartUnix,
      id: ladderDto.id,
    };
  });

  return { history };
};

export const CurrentEventSettingsAdapter = (data: ICurrentEventSettingsResponseBody): ICurrentEventSettingsEntity => {
  // const prefix = data.currentEvent.slice(0, -2);
  // const yearSuffix = data.currentEvent.slice(-2);
  // const capitalizedPrefix = prefix.charAt(0).toUpperCase() + prefix.slice(1);
  // const fixedEventFullName = `${capitalizedPrefix} ${yearSuffix} Event`;

  const fixedEventTime = new Date(data.timeToEventOver).getTime();

  const getFilledPrecentGradient = (gradient: string[]) => {
    gradient[0] = `${gradient[0].toLowerCase()} 0%`;
    gradient[1] = `${gradient[1].toLowerCase()} 100%`;

    return gradient;
  };

  const challengeCardGradient = {
    colorStops: data.challengeCardGradient ?? [],
    toDirection: '180deg',
  };

  const tabValues = Object.values(ETabsPresets);
  const parsedActiveTabs = data.activeTabs?.filter((tab) => tabValues.includes(tab as ETabsPresets)) ?? [];

  return {
    activeTabs: parsedActiveTabs as ETabsPresets[],
    casesSectionTitles: data.cases,
    challengeCardGradient,
    coinColor: data.coinsColor,
    coinIcon: data.coinsIcon,
    eventButtonGradient: {
      color: getFilledPrecentGradient(data.gradient),
      hover: getFilledPrecentGradient(data.gradientHovered),
    },
    eventFullName: data.currentEvent ?? '',
    eventSubtitle: data.textTitle,
    eventTimerUnixTime: fixedEventTime,
    eventTitle: data.currentEvent,
    getCasesImages: data.getCases,
    id: data.id,
    imageBackground: data.imageBackground,
    imageLogo: data.imageLogo,
    popup: {
      image1: data.popup.image1,
      image2: data.popup.image2,
      image3: data.popup.image3,
      image4: data.popup.image4,
      image5: data.popup.image5,
      image6: data.popup.image6,
      popupGradient: {
        colorStops: getFilledPrecentGradient(data.popup.gradient),
        toDirection: '180deg',
      },
      shadow: data.popup.popupShadowGradient,
    },
  };
};

export const EventLadderAdapter = (data: ITopEventLadderResponseBody): ITopEventLadderEntity => {
  const timeToEndEvent = data.event.dateEnd;
  const dateObject = new Date(timeToEndEvent);
  const unixTimestamp = dateObject.getTime();

  const event: IEventLadderData = {
    coinsColor: data.event.coinsColor || '#FF7D05',
    coinsIcon: parseImageDomain(data.event.coinsIcon),
    id: data.event.id,
    logo: parseImageDomain(data.event.logo),
    name: data.event.name,
    timeToEnd: unixTimestamp,
  };

  const { me, users } = TopLadderAdapter(data);

  return { event, me, users };
};

export const EventLadderHistoryAdapter = (data: ITopEventLadderHistoryResponseBody): ITopEventLadderHistoryEntity => {
  const uniqueDatesSet = new Set<string>();
  data.events = data.events.filter((event) => {
    const isUnique = !uniqueDatesSet.has(event.dateEnd.date);
    uniqueDatesSet.add(event.dateEnd.date);
    return isUnique;
  });

  const history = data.events.map((eventDto: IHistoryEventData) => {
    const dateObject = new Date(eventDto.dateEnd.date);
    const unixTimestamp = dateObject.getTime();

    return {
      coinsColor: eventDto.coinsColor || '#FF7D05',
      coinsIcon: parseImageDomain(eventDto.coinsIcon),
      id: eventDto.id,
      logo: parseImageDomain(eventDto.logo),
      name: eventDto.name,
      timeToEnd: unixTimestamp,
    };
  });

  return { history };
};

export const UserInfoAdapter = (data: IUserInfoResponseBody): IUserInfoEntity => {
  const fixedAvilableCases = data.availableCases.map((availableCase: IAvailableCases) => {
    return {
      caseName: availableCase.caseName,
      id: availableCase.caseName.replace('LEVEL ', ''),
    };
  });

  return {
    availableCases: fixedAvilableCases,
    avatar: data.avatar,
    currentXp: data.currentExp,
    nearestCaseTime: data.nearestCaseTime * 1000,
    nextLvlCase: {
      link: data.nextLevelCaseData?.link ? data.nextLevelCaseData.link : '',
      name: data.nextLevelCaseData?.caseName ? data.nextLevelCaseData.caseName.replace('LEVEL ', '') : '',
    },
    nextLvlXp: data.expToNextLevel,
    nickname: data.nickname,
    ticketsCount: data.ticketsSumData?.ticketsSum ?? 0,
    userId: data.userId,
    userLevel: data.userLevel,
  };
};

export const UserInRaffleInfoAdapter = (data: IUserInfoResponseBody): IUserDataInLadder => {
  return {
    avatar: data.avatar,
    coins: data.ticketsSumData.ticketsSum,
    level: +data.userLevel,
    positionInLadder: 0,
    userId: data.userId,
    username: data.nickname,
  };
};

export const RaffleResultAdapter = (data: IRaffleResultInfoResponseBody): IRaffleResultInfoEntity => {
  const mapCallback = (user: IRaffleResult) => {
    return {
      item: {
        id: user.itemId,
        image: getFullIconUrl(user.img),
        name: user.item,
        priceData: {
          currency: user.price.currency,
          price: user.price.price,
        },
        qualityEnum: {
          name: 'Arcana',
        },
      },
      user: {
        avatar: user.avatar,
        items: [],
        level: +user.level,
        positionInLadder: user.position,
        tickets: user.tickets,
        userId: user.userId,
        username: user.name,
      },
    };
  };

  const fixedRaffleResult = data.RaffleResult.map(mapCallback);
  const fixedMeInRaffle = data.meInRaffle?.map(mapCallback);

  return {
    arcanaCount: data.prizeCount,
    meInRaffle: fixedMeInRaffle || null,
    otherUsersInRaffle: fixedRaffleResult,
    paginator: data.paginator,
  };
};
