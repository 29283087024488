import ConsoleLogger from './ConsoleLogger';

/* Класс, контролирующий логеры и их включение/выключение
 * Весь функционал данного контроллер будет доступен также и в консоли браузера
 */
export default class LoggerController {
  public static readonly selfLogger: ConsoleLogger = new ConsoleLogger('LoggerController');
  private static loggers: Map<
    unknown,
    {
      implicitly: boolean;
      instance: ConsoleLogger;
      key: string;
      prefix: string;
    }
  > = new Map();

  public static turnOnLogger(key: string) {
    const logger = LoggerController.findLogger(key);

    if (!logger)
      return LoggerController.selfLogger.warn(
        `Не удается найти ${key} logger. Создайте его, прежде чем обращаться к нему`,
      );

    logger.instance.isEnabled = true;

    logger.instance.loggerPrefix = '';
    logger.instance.warn(
      `%c${LoggerController.selfLogger.loggerPrefix}: %cLogger "${logger.key}" ${
        logger.implicitly ? '(Created Implicitly)' : ''
      } – ENABLED`,
      'color: white;',
      'color: #00cc66;',
    );
    logger.instance.loggerPrefix = logger.prefix;
  }

  public static turnOffLogger(key: string) {
    const logger = LoggerController.findLogger(key);

    if (!logger)
      return LoggerController.selfLogger.warn(
        `Не удается найти ${key} logger. Создайте его, прежде чем обращаться к нему`,
      );

    logger.instance.loggerPrefix = '';
    logger.instance.warn(
      `%c${LoggerController.selfLogger.loggerPrefix}: %cLogger "${logger.key}" - DISABLED`,
      'color: white;',
      'color: #f44336;',
    );
    logger.instance.loggerPrefix = logger.prefix;

    logger.instance.isEnabled = false;
  }

  public static turnOnAll() {
    LoggerController.loggers.forEach((logger) => {
      this.turnOnLogger(logger.key);
    });
  }

  public static turnOffAll() {
    LoggerController.loggers.forEach((logger) => {
      this.turnOffLogger(logger.key);
    });
    LoggerController.selfLogger.warn('Все логеры отключены');
    LoggerController.selfLogger.isEnabled = false;
  }

  public static createLogger(key: unknown, prefix = ''): void {
    if (LoggerController.findLogger(key))
      return LoggerController.selfLogger.warn(`Не удается создать logger. Logger ${key} уже существует`);

    const logger = new ConsoleLogger(prefix);
    LoggerController.loggers.set(key, {
      implicitly: false,
      instance: logger,
      key: key as string,
      prefix,
    });
  }

  public static getLogger(key: unknown): ConsoleLogger {
    const logger = LoggerController.findLogger(key);
    if (!logger) {
      LoggerController.selfLogger.warn(`Не удается найти ${key} Logger. Создаю его неявно...`);
      LoggerController.createLogger(key, key as string);
      LoggerController.selfLogger.warn(`Logger ${key} создан`);
      const newLogger = LoggerController.loggers.get(key);
      newLogger!.implicitly = true;
      return newLogger!.instance;
    }
    return logger.instance;
  }

  public static showLoggers() {
    LoggerController.selfLogger.log('Предоставляю существующие логгеры:');
    LoggerController.selfLogger.loggerPrefix = '';
    LoggerController.loggers.forEach((logger) => {
      LoggerController.selfLogger.log(logger.key);
    });
    LoggerController.selfLogger.loggerPrefix = 'LoggerController';
  }

  private static findLogger(key: unknown) {
    return LoggerController.loggers.get(key) || null;
  }
}
