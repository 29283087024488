import ApiHttp from '~/api/global/http/ApiHttp';
import type { CaseResponseDto } from '~/repository/modules/cases/cases.dto';
import type {
  ICaseData,
  IDropsWinnersItems,
  IOpenItems,
  ISection,
  TChallangeData,
} from '~/repository/modules/cases/cases.types';

export default class CasesHttp extends ApiHttp {
  private readonly caseApi: string;
  private readonly allCases: string;
  private readonly eventCases: string;
  private readonly challengeProgress: string;

  constructor() {
    super();
    this.caseApi = '/v1/cases/';
    this.allCases = '/v1/sections/';
    this.eventCases = '/v1/event/cases';
    this.challengeProgress = '/v1/event/personal-progress';
  }

  public async getSections() {
    const { data } = await useLazyAsyncData<{ data: ISection[] }>(() => this.$api.get(this.allCases));
    return data?.value?.data;
  }

  public async getCase(caseName: string): Promise<CaseResponseDto | undefined> {
    const { data } = await useAsyncData<{ data: CaseResponseDto }>(() => this.$api.get(this.caseApi + caseName));
    return data.value?.data;
  }

  public async getEventCases(): Promise<ICaseData[] | undefined> {
    const { data }: { data: ICaseData[] } = await this.$api.get(this.eventCases);
    return data;
  }

  public async openCase(caseName: string): Promise<IOpenItems | undefined> {
    const { data }: { data: IOpenItems } = await this.$authApi.post(this.caseApi + caseName + '/open');
    return data;
  }

  public getDrops(caseName: string, gameId: string) {
    return useAsyncData<{ data: IDropsWinnersItems }>(() => this.$api.get(this.caseApi + caseName + '/' + gameId));
  }

  public async getMaxCasePriceForUser(): Promise<ICaseData | undefined> {
    const { data } = await useAsyncData<{ data: ICaseData }>(() => this.$authApi.get(this.eventCases + '/max-price'));
    return data.value?.data;
  }

  public async getChallangeProgress(): Promise<TChallangeData | undefined> {
    const { data } = await useAsyncData<{ data: TChallangeData }>(() => this.$authApi.get(this.challengeProgress));
    return data.value?.data;
  }
}
