import { GlobalUtils } from '~/utils';
import type { IBkGetUserData } from '~/repository/modules/bk/bk.types';
import { useIntegratorApi } from '~/features/bk/composables/useIntegratorApi';
import { useBkIntegratorStore } from '~/features/bk/store/bk.integrator';
import { languageIsoData } from '~/repository/extensions/i18n/data';

export const useBkUserStore = defineStore('bk/user', () => {
  const bkIntegratorStore = useBkIntegratorStore();
  const nuxtApp = useNuxtApp();
  const { urlOptions } = storeToRefs(bkIntegratorStore);
  const { defaultUrl, computedLanguage } = useIntegratorApi(urlOptions);

  const user = ref<IBkGetUserData>();

  const clientId = computed<string>(() => user.value?.clientId || '');

  const setValidLanguage = (data: IBkGetUserData) => {
    /** Подставляем дефолтное языка с бэка
     * при отсутсвии корректно указанного в query параметрах на клиенте
     */
    const { languages } = data;
    const currentLanguage = languages.current || languages.default;
    urlOptions.value.languageIso = languageIsoData[currentLanguage];
  };

  const handleUserData = (usersData: IBkGetUserData[]) => {
    const data = usersData.find(Boolean);
    if (!data) return;
    if (urlOptions.value.isDemo) {
      return setValidLanguage(data);
    }
    user.value = data;
    setValidLanguage(data);
  };

  const getClientId = async () => {
    await GlobalUtils.Api.handleRequest(
      async () => {
        const getUserArgs = {
          authToken: urlOptions.value['auth-token'],
          bkPrefix: defaultUrl.value,
          currency: urlOptions.value.currencyIso,
          /** Необходимо для бэка
           * undefined вызывает ошибку
           */
          language: computedLanguage.value,
        };

        // --------------------------------------------------------------------- //

        // Обязательно отправляем запросы на получение юзера на оба БК (ДОТА, КС), чтобы юзер создавался и там и там, если его нет

        await Promise.all([
          nuxtApp.$api.bk.getUser<IBkGetUserData>(getUserArgs),
          nuxtApp.$api.bkSecond.getUser<IBkGetUserData>(getUserArgs),
        ]).then(handleUserData);

        // --------------------------------------------------------------------- //
      },
      (e) => {
        // eslint-disable-next-line no-console
        console.log(e);
      },
    );
  };

  return {
    clientId,
    getClientId,
    user,
  };
});
