import type {
  ISharedButtonNewColorPreset,
  ISharedButtonNewFormPreset,
  ISharedButtonNewStatePreset,
  ISharedButtonTextPreset,
} from '~/types/SharedButtonNew.types';
import { Colors } from '~/constants/colors.constants';

export const defaultButtonColorPreset: ISharedButtonNewColorPreset = {
  color: 'var(--dark-blue-4)',
  colorBorder: 'var(--dark-blue-4)',
  colorHovered: 'var(--dark-blue-5)',
};

export const defaultSmallButtonFormPreset: ISharedButtonNewFormPreset = {
  border: 0,
  flexDir: 'row',
  height: '32px',
  justifyContent: 'center',
  margin: 0,
  minWidth: '200px',
  padding: 'var(--gap-4xs) var(--gap-4xs)',
  radius: 'var(--border-radius-3xs)',
  width: '200px',
};
export const defaultMiddleButtonFormPreset: ISharedButtonNewFormPreset = {
  border: 0,
  flexDir: 'row',
  height: '40px',
  justifyContent: 'center',
  margin: 0,
  minWidth: '200px',
  padding: 'var(--gap-3xs) var(--gap-3xs)',
  radius: 'var(--border-radius-4xl)',
  width: '200px',
};
export const defaultLargeButtonFormPreset: ISharedButtonNewFormPreset = {
  border: 0,
  flexDir: 'row',
  height: '50px',
  justifyContent: 'center',
  margin: 0,
  minWidth: '211px',
  padding: 'var(--gap-2xs) var(--gap-2xs)',
  radius: 'var(--border-radius-4xl)',
  width: '211px',
};

export const defaultButtonStatePreset: ISharedButtonNewStatePreset = {
  active: false,
  disabled: false,
};

export const defaultSmallButtonTextPreset: ISharedButtonTextPreset = {
  color: Colors.NEUTRAL.WHITE,
  size: 'var(--font-size-m)',
};
export const defaultMediumButtonTextPreset: ISharedButtonTextPreset = {
  color: Colors.NEUTRAL.WHITE,
  size: 'var(--font-size-m)',
};
export const defaultLargeButtonTextPreset: ISharedButtonTextPreset = {
  color: Colors.NEUTRAL.WHITE,
  size: 'var(--font-size-l)',
};
