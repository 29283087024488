export const PROFILE_ROUTES = {
  USER_PAGE: {
    getDynamicPath: (userId: string) => `/${userId}`,
    name: 'profile',
    path: '/:userId',
  },
};

export const PROFILE_SECRET_ROUTES = {
  USER_PAGE: {
    getDynamicPath: (userId: string) => `/${userId}`,
    name: 'SuperSecretProfilePage2',
    path: '/:userId',
  },
};
