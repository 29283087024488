import type { TPossibleNull } from '~/types/Shared.types';
import ApiQueryHttp from '~/api/global/http/ApiQueryHttp';

export default class InnerOfferHttp extends ApiQueryHttp {
  protected readonly offerApi: string;

  constructor() {
    super();
    this.offerApi = '/v1/triggers';
  }

  /* GET */

  public async getOffer<IResponseOfferDTO>(): Promise<TPossibleNull<IResponseOfferDTO>> {
    const { data } = await this.$authApi.get<{ data: IResponseOfferDTO }>(this.offerApi);
    return (data as IResponseOfferDTO) || null;
  }
}
