const getValueByKey = <T, K>(obj: Record<string, T>, keys: string): K => {
  let value: T | null = null;
  const split: string[] = keys.split('/');
  split.forEach((key) => {
    if (value) {
      value = (value as Record<string, T>)[key] as T;
    } else {
      value = obj[key];
    }
  });
  return value as K;
};

/**
 * Возвращает объект с заданными наименованиями ключей
 * @param {Object} obj - Целевой объект
 * @param {Array} keys - Массив ключей, которые будут присутсвовать в возвращаемом объекте
 * @returns {Object} - Объект с ключами указанными в масиве
 */
const getObjectWithOnlySelectedKeys = <T, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> => {
  return keys.reduce(
    (acc, key) => {
      if (obj[key]) {
        return {
          ...acc,
          [key]: obj[key],
        };
      }
      return acc;
    },
    {} as Pick<T, K>,
  );
};

/**
 * Сортирует массив объектов по свойству key в указанном порядке
 * @param {Array} arr - Массив объектов для сортировки
 * @param {Array} order - Порядок сортировки, массив строк впорядке чего нужно сортировать
 * @param {string} key - Ключ св-ва объекта, по значению которого будет определяться сортировка
 * @returns {Array} - Отсортированный массив объектов
 */

type TString = Record<string, string>;
const sortArrayWithObjectByKey = <T>(arr: T, order: string[], key: string): T => {
  const orderMapping: { [x: string]: number } = {};
  order.forEach((orderKey, index) => {
    orderMapping[orderKey.toUpperCase()] = index;
  });

  if (!Array.isArray(arr)) return arr;
  return arr.sort((a: TString, b: TString) => {
    const keyA = orderMapping[GlobalUtils.Objects.getValueByKey<string, string>(a, key)?.toUpperCase()];
    const keyB = orderMapping[GlobalUtils.Objects.getValueByKey<string, string>(b, key)?.toUpperCase()];

    return +keyA - +keyB;
  });
};

/**
 * Перемешивает массив объектов
 * @param {Array} arr - Массив объектов для перемешивания
 * @returns {Array} - Отсортированный массив объектов
 */
const shuffleArray = <T extends Array<object>>(arr: T): T => {
  return [...arr].sort(() => Math.random() - 0.5) as T;
};

export interface HashedIdFunction {
  (id: number): string;

  hash?: Partial<{ [key: number]: number }>;
}
const hashedId: HashedIdFunction = function (id: number) {
  if (!hashedId.hash) {
    hashedId.hash = {};
  }

  if (hashedId.hash && id in hashedId.hash) {
    hashedId.hash[+id]!++;
  } else {
    hashedId.hash[id] = 1;
  }

  return `${id}-#${hashedId.hash[id]}`;
};

/**
 * Добавить элемент в массив по индексу
 * @param {Array} array - массив
 * @param {object} element - элемент
 * @param {number} index - индекс в который вставить элемент
 * @returns {Array} - Массив с добавленным элементом
 * */
function addElementAtIndex<T, K extends Array<T> = T[]>(array: K, element: T, index: number): K {
  array.splice(index, 0, element);
  return array;
}

type TUnpredictedObject = Record<string, unknown>;

/* Проверка на то, является ли переданное значения объектом */
function isObject(value?: TUnpredictedObject): boolean {
  return value !== null && typeof value === 'object' && !Array.isArray(value);
}

/* Проверка объектов на идентичность (рекурсивно)
 * Если объекты отличаются, то в выходном массиве будут находиться
 * Все ключи, которые отличаются между объектами.
 *
 * Если массив на выходе пуст, то объекты являются идентичными!
 * */
function checkObjectDifference<T = TUnpredictedObject>(obj1: T, obj2: T): (keyof T)[] {
  /* Если нет одного или другого объекта, то просто возвращаем пустой массив */
  if (!obj1 || !obj2) return [];
  /* Ключи объекта на "первом уровне" (корневом) */
  const props1 = Object.getOwnPropertyNames(obj1);
  /* Массив отличающихся ключей */
  let differentKeys: (keyof T)[] = [];

  for (let i = 0; i < props1.length; i++) {
    const key = props1[i];

    const valueObject1 = obj1[key as keyof T];
    const valueObject2 = obj2[key as keyof T];

    const areObjects = isObject(valueObject1 as TUnpredictedObject) && isObject(valueObject2 as TUnpredictedObject);

    /* Если оба массивы и есть индексы, которые отличаются по значениям, то пушим ключ */
    if (areObjects) {
      /* Если оба объекты, заходим в рекурсию */
      const diffKeys = checkObjectDifference(valueObject1, valueObject2);
      if (diffKeys.length) {
        differentKeys = [...differentKeys, key, ...diffKeys] as (keyof T)[];
      }
    } else if (valueObject1 !== valueObject2) {
      /* Если же значения просто не равны друг другу, то также просто пушим
       * ключ, который отличается, в итоговый массив
       */
      differentKeys.push(key as keyof T);
    }
  }

  return differentKeys;
}

/* Merge двух и более конфигураций в одну итоговую */
// @ts-ignore
function deepObjectsMerge<T = TUnpredictedObject>(target: T, source: T | Partial<T>): T {
  // Make sure to make a shallow copy first, otherwise
  // the original objects are mutated.
  const newTarget = { ...target };
  const newSource = { ...source } as T;

  for (const key in newSource as TUnpredictedObject) {
    const typedKey = key as keyof typeof newSource;
    if (typeof newTarget[typedKey] !== 'object') {
      newTarget[typedKey] = newSource[typedKey];
    } else if (typeof newSource[key as keyof typeof newSource] === 'object') {
      newTarget[typedKey] = deepObjectsMerge(newTarget[typedKey], newSource[typedKey]);
    }
  }
  return newTarget;
}

function deepObjectCopy(object: TUnpredictedObject) {
  const newObject = { ...object };

  if (isObject(object)) {
    for (const key in object) {
      if (isObject(object[key] as TUnpredictedObject)) {
        newObject[key] = deepObjectCopy(object[key] as TUnpredictedObject);
      } else if (Array.isArray(object[key])) {
        newObject[key] = [...(object[key] as Array<unknown>)];
      } else {
        newObject[key] = object[key];
      }
    }
  }

  return newObject;
}

export default {
  Objects: {
    addElementAtIndex,
    checkObjectDifference,
    deepObjectCopy,
    deepObjectsMerge,
    getObjectWithOnlySelectedKeys,
    getValueByKey,
    hashedId,
    isObject,
    shuffleArray,
    sortArrayWithObjectByKey,
  },
};
