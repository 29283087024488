import InventoryHttp from './inventoryHttp';
import ApiHttp from '~/api/global/http/ApiHttp';
import type {
  IReplaceInventoryItemRequestDTO,
  TGetInventoryRequestDTO,
} from '~/repository/modules/inventory/inventory.types';

export default class InventoryService extends ApiHttp {
  private readonly inventoryHttp: InventoryHttp;
  constructor() {
    super();
    this.inventoryHttp = new InventoryHttp();
  }

  public async getInventory(params: TGetInventoryRequestDTO) {
    const response = await this.inventoryHttp.getInventory(params);

    if (!response) throw new Error('Error');

    return response;
  }

  public sellItem = () => {
    return this.inventoryHttp.sellItem();
  };

  public sellAllItems = () => {
    return this.inventoryHttp.sellAllItems();
  };

  public tradeItem = async (id: number) => {
    await this.inventoryHttp.tradeItem(id);
  };

  public sendItem = async (ids: number[], method?: string) => {
    return await this.inventoryHttp.sendItem(ids, method);
  };

  public async sellDrop(id: string) {
    await this.inventoryHttp.sellDrop(id);
  }

  public async sellManyInventory(ids: number[]): Promise<void> {
    await this.inventoryHttp.sellManyInventory(ids);
  }

  public async replaceItem(params: IReplaceInventoryItemRequestDTO) {
    return await this.inventoryHttp.replaceItem(params);
  }
}
