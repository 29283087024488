import QueryService from '~/api/global/query/QueryService';
import ApiHttp from '~/api/global/http/ApiHttp';

/*
  Апи-провайдер класс, для манипуляции различными сущностями Апи модулей со своими хедерами,
  поставляемый с управлением query-параметрами
*/
export default class ApiQueryHttp extends ApiHttp {
  protected override query: QueryService;

  constructor() {
    super();
    this.query = new QueryService();
  }
}
