import QueryWorker from '~/api/global/query/QueryWorker';
import DataMapper from '~/api/global/DataMapper';

export default class QueryService {
  private readonly dataMapper: DataMapper;

  constructor() {
    this.dataMapper = new DataMapper();
  }

  public serialise = <T>(params: T, encode = true) => {
    if (!params || typeof params !== 'object') throw new Error('Invalid query params passed for encoding');

    return QueryWorker.encodeParamsIntoString(
      // @ts-ignore
      this.dataMapper.reverseMapDataKeys((params as Record<string, unknown>) || {}),
      encode,
    );
  };

  public unserialise = <T>(stringWithQuery = ''): Partial<T> => {
    if (stringWithQuery === undefined) throw new Error('Invalid string passed for decoding');

    return this.dataMapper.mapDataKeys(QueryWorker.decodeStringIntoParams(stringWithQuery)) as Partial<T>;
  };
}
