import type { TApiResult } from './api.types';
import { EResultSuccessTypes, EResultTypes, type TResult } from './client.types';

export const transform = (apiData: TApiResult): TResult => {
  if (apiData.status === 'fail') {
    return {
      status: EResultTypes.FAIL,
    };
  }

  if (apiData.status === 'pending') {
    return {
      status: EResultTypes.WAIT,
    };
  }

  if (!apiData.bonus) {
    return {
      status: EResultTypes.SUCCESS,
      sum: apiData.value,
      type: EResultSuccessTypes.COMMON,
    };
  }

  const { coins: coinsFactor, prize } = apiData.bonus;

  const caseData = prize
    ? {
        image: prize.icon,
        name: prize.title,
        price: prize.price,
      }
    : undefined;

  const coins = coinsFactor ? Math.floor(apiData.value * (coinsFactor / 100)) : undefined;

  return {
    bonuses: {
      caseData,
      coins,
    },
    status: EResultTypes.SUCCESS,
    sum: apiData.value,
    type: EResultSuccessTypes.BLOGGER,
  };
};
