import ApiHttp from '~/api/global/http/ApiHttp';
import type { ITopLiveDropApiData, ITopLiveDropApiResponse } from '~/layers/dota/store/topLiveDrop/topLiveDrop.types';

export default class DropFeedHttp extends ApiHttp {
  private readonly dropApi: string;

  constructor() {
    super();
    this.dropApi = '/v1/live_drop ';
  }

  async getTopDrop(isTop: boolean): Promise<ITopLiveDropApiData[]> {
    // @ts-ignore
    const { data } = await this.$api.post<ITopLiveDropApiResponse>(this.dropApi, {
      body: {
        is_top: isTop,
      },
    });
    return data;
  }
}
