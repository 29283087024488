import { defineStore } from 'pinia';
import type { IUrlOptions } from '~/features/bk/types/integrator.types';
import { useQuery } from '~/composables/useQuery';
import BkGuardUrlService from '~/repository/modules/bk/BkGuardUrlService';
import {
  requiredUrlPropertiesKey,
  requiredUrlPropertiesPathKey,
  validatorsQuery,
} from '~/features/bk/store/bk.integrator.helper';

export const useBkIntegratorStore = defineStore('bk/integrator', () => {
  const DEFAULT_URL_OPTIONS: IUrlOptions = {
    'auth-token': '',
    'caseName': '',
    'currencyIso': 'RUB',
    'integratorUuid': '',
    'isDemo': false,
  };
  const urlOptions = ref<IUrlOptions>(DEFAULT_URL_OPTIONS);
  const bkGuardUrlService = new BkGuardUrlService();

  function handlerUrl() {
    try {
      const { query, params } = useQuery();
      if (!query || !params) {
        // TODO обработка ошибки
        return;
      }

      const handlerUrl = bkGuardUrlService.checkUrl(urlOptions);
      handlerUrl(requiredUrlPropertiesKey, query, validatorsQuery);
      handlerUrl(requiredUrlPropertiesPathKey, params);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  return {
    handlerUrl,
    urlOptions,
  };
});
