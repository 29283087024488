/* eslint no-console: 0 */

/* Класс логгера консоли с типичными для нее методами */
export default class ConsoleLogger {
  private prefix: string = '';
  private enabled: boolean;

  constructor(prefix = '') {
    this.prefix = prefix;
    this.enabled = false;
  }

  public get loggerPrefix() {
    return this.prefix;
  }

  public get isEnabled() {
    return this.enabled;
  }

  public set loggerPrefix(value: string) {
    this.prefix = value;
  }

  public set isEnabled(value: boolean) {
    this.enabled = value;
  }

  public log(...args: unknown[]) {
    if (!this.isEnabled) return;
    if (this.prefix) {
      console.log(`%c${this.prefix}:`, 'color: #80ff00;');
    }
    console.log(...args);
  }

  public warn(...args: unknown[]) {
    if (!this.isEnabled) return;
    if (this.prefix) {
      console.warn(`%c${this.prefix}:`, 'color: #80ff00;');
    }
    console.warn(...args);
  }

  public error(...args: unknown[]) {
    if (!this.isEnabled) return;
    if (this.prefix) {
      console.error(`%c${this.prefix}:`, 'color: #80ff00;');
    }
    console.error(...args);
  }
}
