import dotaConfig from '~/config/apps/dota.config';
import csConfig from '~/config/apps/cs.config';
import dotaGlobalConfig from '~/config/apps/dota-global.config';

export default defineNuxtPlugin(() => {
  const { tastyProject, defaultLocale } = useRuntimeConfig().public;

  if (defaultLocale === 'en') {
    return {
      provide: {
        projectConfig: dotaGlobalConfig,
      },
    };
  }

  if (tastyProject === 'tastygo') {
    return {
      provide: {
        projectConfig: csConfig,
      },
    };
  }

  return {
    provide: {
      projectConfig: dotaConfig,
    },
  };
});
