import { ProfileHttp } from './profile.http';
import type {
  IGetSatDashboardParams,
  IGetStatBattlesParams,
  IBaseStatRequestParams,
} from '~/repository/modules/profile/types/statistics';
import type { IGetProfileBattlesParams, IGetProfileBaseParams } from '~/repository/modules/profile/types/battles';
import type { TGetProfileUpgradesRequestDto } from '~/repository/modules/profile/types/upgrades';

export class ProfileService {
  private readonly profileHttp = new ProfileHttp();

  public generateLevel() {
    return this.profileHttp.generateLevel();
  }

  public getStatBattles(params: IGetStatBattlesParams) {
    return this.profileHttp.getStatBattles(params);
  }

  public getStatDashboard(params: IGetSatDashboardParams) {
    return this.profileHttp.getStatDashboard(params);
  }

  public getStatUpgrades(params: IBaseStatRequestParams) {
    return this.profileHttp.getStatUpgrades(params);
  }

  public getBattles(params: IGetProfileBattlesParams) {
    return this.profileHttp.getBattles(params);
  }

  public getBestBattle(params: IGetProfileBaseParams) {
    return this.profileHttp.getBestBattle(params);
  }

  public getUpgrades(params: TGetProfileUpgradesRequestDto) {
    return this.profileHttp.getUpgrades(params);
  }
}
