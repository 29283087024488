import type { Directive, DirectiveBinding } from 'vue';
import { SentryEvents } from '~/repository/extensions/sentry/sentry.events';
import type { ISentryDirective, ISentryService } from '~/repository/extensions/sentry/sentry.types';

const DIRECTIVE_NAME = 'sentry';

const directive: Directive<HTMLElement, DirectiveBinding> = {
  beforeUpdate(el, binding) {
    if (el && binding) {
      const { $sentryService } = useNuxtApp() as Record<string, ISentryService>;
      const { value } = binding;
      const oldValue = {};

      const handleUpdate = () => {
        const { action, message, category, level, eventId, options } = value as ISentryDirective;
        if (action) {
          switch (action) {
            case SentryEvents.BREADCRUMB:
              // Добавляем breadcrumb в Sentry
              // eslint-disable-next-line no-case-declarations
              const breadcrumb = {
                category: category || 'custom',
                message,
                ...options, // Дополнительные свойства breadcrumb из модификаторов
              };
              $sentryService.addBreadcrumb(breadcrumb);
              break;
            case SentryEvents.CAPTURE_EXCEPTION:
              // Ловим и отправляем исключение в Sentry
              // eslint-disable-next-line no-case-declarations
              const exceptionOptions = {
                ...options, // Дополнительные опции для captureException из модификаторов
              };
              try {
                $sentryService.captureException(message, exceptionOptions);
              } catch (error) {
                $sentryService.captureException('error', error);
              }
              break;
            case SentryEvents.CAPTURE_MESSAGE:
              // Отправляем пользовательское сообщение в Sentry
              // eslint-disable-next-line no-case-declarations
              const messageOptions = {
                level: level || 'info',
                ...options, // Дополнительные опции для captureMessage из модификаторов
              };
              $sentryService.captureMessage(value, messageOptions);
              break;
            case SentryEvents.USER_FEEDBACK:
              // Запрашиваем обратную связь у пользователя
              // eslint-disable-next-line no-case-declarations
              const feedbackOptions = {
                eventId,
                ...options, // Дополнительные опции для запроса обратной связи из модификаторов
              };
              $sentryService.showReportDialog(feedbackOptions);
              break;
            default:
              return;
              break;
          }
        } else {
          return;
        }

        Object.assign(oldValue, value); // Сохраняем текущее значение как предыдущее
      };

      // Следим за изменением значения
      const valueChanged = () => {
        // @ts-ignore
        const isValueChanged = Object.keys(value).some((key) => value[key] !== oldValue[key]);
        if (isValueChanged) {
          handleUpdate(); // Выполняем действия только если значение изменилось
        }
      };
      valueChanged();
    }
  },
};

export default {
  directive,
  name: DIRECTIVE_NAME,
};
