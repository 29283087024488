import NumbersUtils from './number.utils';

/** Метод сокращает дробное значение до общепринятых на проекте
 * @param {Number} value - число
 * @return {String} - обрезанная строка
 * **/
const toFixedDefault = (value: number | undefined): string => NumbersUtils.Numbers.toFixed(value, 2);

/** Метод rounded
 * @param {Number} value - число, округленное по условию (0.5 и больше - в большую сторону, меньше - в меньшую)
 * @return {String} - обрезанная строка без десятичных значений
 * **/
const toRoundedDefault = (value: number | undefined): string => NumbersUtils.Numbers.toRounded(value);

export default {
  Prices: {
    toFixedDefault,
    toRoundedDefault,
  },
};
