import { Currency } from '~/constants/currency.constants';
import type { TCurrencyType } from '~/types/Shared.types';

export function useDefaultCurrency(isFake: boolean): (typeof Currency)[TCurrencyType] {
  const {
    $projectConfig: { defaultCurrency },
  } = useNuxtApp();

  if (isFake) return Currency.DIAMOND;
  return Currency[defaultCurrency as TCurrencyType] ?? Currency.RUB;
}
