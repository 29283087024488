import LoggerController from '~/repository/extensions/logger/LoggerController';

/* Создание функции активатора для активации работы LoggerController
 * Без ключа активации логер никак не включить
 */
export function createLoggersActivator(activationKey: string, doInScope: () => void = () => {}) {
  doInScope();
  return function (ourKey: string) {
    if (ourKey !== activationKey) return;
    LoggerController.selfLogger.isEnabled = true;
    LoggerController.selfLogger.warn('Активирован');
    return LoggerController;
  };
}

export const enum ELoggerKeys {
  BATTLES = 'Battles Logger',
  USER = 'User Logger',
}

/* todo: не вынесены в глобал утилиты */

export { LoggerController };
