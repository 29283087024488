<template>
  <div
    :class="[
      props.class,
      'img-wrapper',
      props.autoSize && 'img-wrapper--auto',
      props.limitByParent && 'img-wrapper--limit',
    ]"
    :style="styles"
  >
    <picture>
      <source v-if="(img as IPropsImgJPEG)?.webP1" type="image/webp" :srcset="webPSrcset" />
      <source v-if="(img as IPropsImgJPEG)?.jpeg1" type="image/jpeg" :srcset="jpegSrcset" />
      <source v-if="(img as IPropsImgJPEG)?.jpeg1" type="image/jpg" :srcset="jpegSrcset" />
      <source v-if="(img as IPropsImgPNG)?.png1" type="image/png" :srcset="pngSrcset" />
      <img
        v-if="(img as IPropsImgJPEG)?.jpeg1"
        :src="`${(img as IPropsImgJPEG)?.jpeg1}`"
        :alt="alt"
        :style="imgStyles"
      />
      <img v-if="(img as IPropsImgPNG)?.png1" :src="`${(img as IPropsImgPNG)?.png1}`" :alt="alt" :style="imgStyles" />
    </picture>
  </div>
</template>

<script setup lang="ts">
import type { CSSProperties } from 'vue';
import type { IPropsImgJPEG, IPropsImgPNG, IPropsSharedImage } from './SharedImage.types';

const { toPx } = GlobalUtils.Converting;

const props = withDefaults(defineProps<IPropsSharedImage>(), {
  alt: 'image',
  autoSize: false,
  fit: 'cover',
  limitByParent: false,
  type: 'jpeg',
});

const styles = computed(() => {
  const styles: CSSProperties = {};
  if (props.border) styles.borderRadius = GlobalUtils.CSS.proceedCssValue(props.border);
  if (props.height && props.width) {
    styles.height = toPx(props.height as string);
    styles.width = toPx(props.width as string);
  }
  return styles;
});

const webPSrcset = computed(() => {
  return `${props.img.webP3 || props.img.webP2 || props.img.webP1} 3x, ${props.img.webP2 || props.img.webP1} 2x, ${
    props.img.webP1
  } 1x`;
});
const jpegSrcset = computed(() => {
  return `${
    (props.img as IPropsImgJPEG).jpeg3 || (props.img as IPropsImgJPEG).jpeg2 || (props.img as IPropsImgJPEG).jpeg1
  } 3x,${(props.img as IPropsImgJPEG).jpeg2 || (props.img as IPropsImgJPEG).jpeg1} 2x, ${
    (props.img as IPropsImgJPEG).jpeg1
  } 1x`;
});
const pngSrcset = computed(() => {
  return `${
    (props.img as IPropsImgPNG).png3 || (props.img as IPropsImgPNG).png2 || (props.img as IPropsImgPNG).png1
  } 3x, ${(props.img as IPropsImgPNG).png2 || (props.img as IPropsImgPNG).png1} 2x, ${
    (props.img as IPropsImgPNG).png1
  } 1x`;
});
</script>

<style scoped lang="scss">
.img-wrapper {
  --img-fit: v-bind(fit);
}
</style>
<style src="./SharedImage.scss" lang="scss" scoped></style>
