import { useUserStore } from '~/store/user/user.store';

export const useZendesk = () => {
  const userStore = useUserStore();
  const { user } = storeToRefs(userStore);

  const {
    $projectConfig: { supportKey },
  } = useNuxtApp();

  if (!user.value) return;

  useHead({
    script: [
      {
        key: 'zendesk',
        src: `//code.jivosite.com/widget/${supportKey}`,
      },
      {
        innerHTML: `
            function jivo_onLoadCallback() {
              const userId = ${user.value.userId};
              jivo_api.setContactInfo({
                name: '${user.value.nickname} (${user.value.userId})'
              });
            }
          `,
        key: 'zendesk-callback',
      },
    ],
  });
};
