import InnerOfferHttp from './InnerOfferHttp';
import type { IResponseOfferDTO } from './InnerOfferService.types';

export default class InnerOfferService {
  private readonly innerOfferHTTP: InnerOfferHttp;

  constructor() {
    this.innerOfferHTTP = new InnerOfferHttp();
  }

  public async getOffer(): Promise<IResponseOfferDTO | []> {
    const response = await this.innerOfferHTTP.getOffer<IResponseOfferDTO | []>();

    if (!response) throw new Error('Error');

    return response;
  }
}
