import { useBattlesDataStateStore } from '~/features/battles/store/dataState.store';
import { WEBSOCKET_FIRST_CHANNEL } from '~/constants/app.constants';

export default defineNuxtPlugin(() => {
  addRouteMiddleware((to) => {
    const {
      $api: { websocket: WebsocketService },
    } = useNuxtApp();

    /* Идем не на батлы, следовательно прекращаем выполнение этого middleware */
    if (!to.path.toLowerCase().includes(ROUTING.BATTLES.MAIN.path)) {
      return;
    }

    const battlesDataStateStore = useBattlesDataStateStore();
    const { dataState } = storeToRefs(battlesDataStateStore);

    const isSubscribed = computed(() => dataState.value.areSocketsSubscribed);

    if (!isSubscribed.value && process.client) {
      const isConnected = WebsocketService.checkConnection(WEBSOCKET_FIRST_CHANNEL);
      battlesDataStateStore.changeDataState('areSocketsSubscribed', isConnected);
    }
  });
});
