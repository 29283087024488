import type {
  ISharedKitButtonColorPreset,
  ISharedKitButtonSizePreset,
  ISharedKitButtonTypePreset,
} from './SharedKitButton.types';
import { Colors } from '~/constants/colors.constants';

type TPresetProjectsKeys = 'DOTA' | 'CS';
type TColorPresetsKeys = 'ACCENT' | 'PRIMARY' | 'SECONDARY' | 'BURGER' | 'QUICKSALE';
type TSizePresetsKeys = 'L' | 'M' | 'S' | 'XS';
type TTypePresetsKeys = 'RECTANGLE' | 'ROUND' | 'NONE';

type TColorPresets = {
  [key in TColorPresetsKeys]: ISharedKitButtonColorPreset;
};

export const ColorPresets: Record<TPresetProjectsKeys, TColorPresets> = {
  /* Цвета для TastyGO */
  CS: {
    // Желтая
    ACCENT: {
      defaultBackground: Colors.MAIN.GOLD,
      hoverBackground: Colors.NEUTRAL.WHITE,
      loadingColor: Colors.NEUTRAL.BLACK_30,
      textColor: Colors.NEUTRAL.BLACK,
    },
    // Цвет кнопок в бургер-меню (прозрачно-белый)
    BURGER: {
      defaultBackground: Colors.NEUTRAL.WHITE_15,
      hoverBackground: Colors.NEUTRAL.WHITE_15,
      loadingColor: Colors.NEUTRAL.WHITE_30,
      textColor: Colors.NEUTRAL.WHITE,
    },
    // Классическая фиолетовая
    PRIMARY: {
      defaultBackground: Colors.MAIN.BLUE_200,
      hoverBackground: Colors.MAIN.BLUE_100,
      loadingColor: Colors.NEUTRAL.WHITE_30,
      textColor: Colors.NEUTRAL.WHITE,
    },

    // В попапе быстрой продажи темно-фиолетовая
    QUICKSALE: {
      defaultBackground: Colors.MAIN.BLUE_600,
      hoverBackground: Colors.MAIN.BLUE_300,
      loadingColor: Colors.NEUTRAL.WHITE_30,
      textColor: Colors.NEUTRAL.WHITE,
    },

    // Дополнительная темно-фиолетовая
    SECONDARY: {
      defaultBackground: Colors.MAIN.BLUE_400,
      hoverBackground: Colors.MAIN.BLUE_300,
      loadingColor: Colors.NEUTRAL.WHITE_30,
      textColor: Colors.NEUTRAL.WHITE,
    },
  },
  /* Цвета для TastyDROP */
  DOTA: {
    // Желтая
    ACCENT: {
      defaultBackground: Colors.MAIN.GOLD,
      hoverBackground: Colors.NEUTRAL.WHITE,
      loadingColor: Colors.NEUTRAL.BLACK_30,
      textColor: Colors.NEUTRAL.BLACK,
    },
    // Цвет кнопок в бургер-меню (прозрачно-белый)
    BURGER: {
      defaultBackground: Colors.NEUTRAL.WHITE_15,
      hoverBackground: Colors.NEUTRAL.WHITE_15,
      loadingColor: Colors.NEUTRAL.WHITE_30,
      textColor: Colors.NEUTRAL.WHITE,
    },
    // Классическая фиолетовая
    PRIMARY: {
      defaultBackground: Colors.MAIN.PURPLE_200,
      hoverBackground: Colors.MAIN.PURPLE_100,
      loadingColor: Colors.NEUTRAL.WHITE_30,
      textColor: Colors.NEUTRAL.WHITE,
    },

    // В попапе быстрой продажи темно-фиолетовая
    QUICKSALE: {
      defaultBackground: Colors.MAIN.PURPLE_600,
      hoverBackground: Colors.MAIN.PURPLE_300,
      loadingColor: Colors.NEUTRAL.WHITE_30,
      textColor: Colors.NEUTRAL.WHITE,
    },

    // Дополнительная темно-фиолетовая
    SECONDARY: {
      defaultBackground: Colors.MAIN.PURPLE_400,
      hoverBackground: Colors.MAIN.PURPLE_300,
      loadingColor: Colors.NEUTRAL.WHITE_30,
      textColor: Colors.NEUTRAL.WHITE,
    },
  },
};

export const SizePresets: Record<TSizePresetsKeys, ISharedKitButtonSizePreset> = {
  L: {
    fontSize: 16,
    height: 50,
    type: 'large',
  },
  M: {
    fontSize: 14,
    height: 40,
    type: 'medium',
  },
  S: {
    fontSize: 12,
    height: 32,
    type: 'default',
  },
  XS: {
    fontSize: 10,
    height: 28,
    type: 'default',
  },
};

export const TypePresets: Record<TTypePresetsKeys, ISharedKitButtonTypePreset> = {
  NONE: {
    default: 0,
    large: 0,
    medium: 0,
  },
  RECTANGLE: {
    default: 4,
    large: 12,
    medium: 8,
  },
  ROUND: {
    default: 80,
    large: 80,
    medium: 80,
  },
};
