import type { IApiBonusItem } from './api.types';
import type { IBonusItem } from './client.types';

export const transform = (apiData: IApiBonusItem[]): IBonusItem[] => {
  return apiData
    .map((item) => ({
      from: item.limit,
      percent: item.bonusMultiplier * 100,
    }))
    .sort((a, b) => a.from - b.from);
};
