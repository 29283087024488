import type { FetchOptions } from 'ofetch';
import type {
  IDefaultError,
  ITransformResponse,
  ICreateOptions,
  IOptionsCreateUrl,
  THeadersApi,
  THeadersType,
} from '~/api/global/api.types';

import DataMapper from '~/api/global/DataMapper';
import { ApiStorage } from '~/api/global/ApiStorage';
import ModuleError from '~/repository/extensions/error/ModuleError';
import ModuleErrorModified from '~/repository/extensions/error/ModuleErrorModified';
import sentryCodes from '~/api/global/errors/sentryCodes/sentryCodes';
import { isTastyDrop } from '~/api/global/samples/BkApiSample';

export default class FetchRequest {
  private readonly mapper: DataMapper;
  private readonly apiStorage: ApiStorage;

  constructor() {
    this.mapper = new DataMapper();
    this.apiStorage = new ApiStorage();
  }

  /** Вернуть корректный url для request
   * @param {IOptionsCreateUrl} properties - включает в себя три элемента
   * 1. Уникальный url для запроса. 2. Ключ дефолтного урла для запроса 3. Объект конфигурации
   * @return {string} - корректный url
   * **/
  public createUrl<T>({ url, keyApiUrlProject, opts = {} }: IOptionsCreateUrl<T>): string {
    const config = useRuntimeConfig();
    // TODO HOT FIX, в поисках адекватного решения для БК

    if (process.server && url.includes('integrator')) {
      /*
       * config.public.bkDotaApiUrl - https://dota2.tasty-provider.com/api
       * config.public.bkCsApiUrl - https://cs.tasty-provider.com/api
       * Пример возвращаемого значения - https://dota2.tasty-provider.com/api/integrator/f29ff8a3-bd55-4842-bde7-fd38b103fa54/user?language_iso=ru&currency_iso=AZN
       * */
      const computedUrl = isTastyDrop() ? config.public.bkDotaApiUrl : config.public.bkCsApiUrl;
      return computedUrl + url;
    }
    if (process.server && config.public.ssrApiUrl) return config.public.ssrApiUrl + url;
    if (opts?.isTargetUrl) return url;
    return config.public[keyApiUrlProject] + url;
  }

  /** Метод создаёт опции для запроса
   * Вешает заголовки
   * Преобразовывает data в body и из body
   * Доп настройки для библиотеки ofetch
   * **/
  public createOptions<T>({ currentRequestMethod, opts, type }: ICreateOptions<T>): FetchOptions {
    const method = currentRequestMethod;
    // Получаем необходимые флаги для конфигурации
    // Преобразовывать отправляемые данные?
    const mapReqBody = opts?.mapBeforeSend !== false;

    // Прикрепить заголовки для запроса
    const headersRequest = this.createHeaderOptions(opts.headers || {}, type);
    const requestBody = mapReqBody ? this.mapper.reverseMapDataKeys(opts?.body as Record<string, unknown>) : opts?.body;

    const body = method === 'get' ? null : JSON.stringify(requestBody);
    return {
      ...headersRequest,
      body: opts?.body instanceof FormData ? opts.body : body,
      method,
      ...this.defaultOFetchConfiguration(),
    } as FetchOptions;
  }

  /** Преобразовать данные в ответе по необходимости **/
  public transformResponse<T>({ opts }: ITransformResponse<T>) {
    // Преобразовывать полученные данные?
    const mapped = opts?.isMapped !== false;

    return (data: T) => {
      if (mapped) return this.mapper.mapDataKeys(data);
      return data;
    };
  }

  /** Метод обработки ошибок
   * Определяет модуль обработки ошибок
   * По необходиомсти отправляем ошибки в sentry
   * **/
  public catchError<T>({ opts }: ITransformResponse<T>) {
    // Использовать кастомный метод обработки ошибок?
    const manualErrorHandler = opts?.manualErrorHandler;
    const errorHandler = this.errorHandler();
    return (e: IDefaultError) => {
      const errorData = this.mapper.mapDataKeys(e.data || {});
      const newError = e.data ? { ...e, data: errorData } : e;
      if (manualErrorHandler) throw manualErrorHandler(newError);
      else throw errorHandler.checkError(newError as IDefaultError);
    };
  }

  private defaultOFetchConfiguration(): FetchOptions {
    return {
      retryStatusCodes: [401, 500],
    };
  }

  // Opts - является набором заголовков
  private createHeaderOptions<T>(opts: T, type: THeadersType) {
    const headersMap = new Map<THeadersType, THeadersApi>([
      ['default', this.apiStorage.defaultHeaders],
      ['auth', this.apiStorage.authHeaders],
      ['old', this.apiStorage.defaultOldHeaders],
      ['dev', this.apiStorage.devHeaders],
      ['bkDota', this.apiStorage.bkHeaders],
      ['bkCs', this.apiStorage.bkHeaders],
    ]);

    if (!headersMap.has(type)) {
      throw new Error('Invalid type');
    }
    const headers = headersMap.get(type);
    if (opts && headers) Object.assign(headers, opts);
    return { headers };
  }

  private errorHandler(): ModuleError | ModuleErrorModified {
    const config = useRuntimeConfig();
    if ((config.public.isLogSentry || '').toString().toLowerCase() === 'true') {
      return new ModuleErrorModified(sentryCodes);
    } else {
      return new ModuleError();
    }
  }
}
